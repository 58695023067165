import React from 'react';
import {
  CircularProgress,
  Box,
  Typography,
  LinearProgress
} from '@mui/material';
import Folder from '../../assets/images/Folder.png';
import styled from '@emotion/styled';

interface FolderUnzippingProps {
  filename: string;
  unzippingProgress: number;
}

const StyledDiv = styled('div')({
  width: '130px',
  padding: '10px',
  position: 'relative',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  float: 'left'
});

const FolderUnzipping: React.FC<FolderUnzippingProps> = ({
  filename,
  unzippingProgress
}) => {
  return (
    <StyledDiv>
      {/* Wrapper for positioning */}

      <img
        loading="lazy"
        src={Folder}
        alt=""
        style={{
          width: '100%',
          opacity: 0.5
        }}
      />
      <LinearProgress variant="determinate" value={unzippingProgress} />

      <Box textAlign={'center'}>
        <Typography variant="caption">
          {filename.length > 15 ? filename.substring(0, 15) + '...' : filename}
        </Typography>
      </Box>
    </StyledDiv>
  );
};

export default FolderUnzipping;
