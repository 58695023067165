/* eslint-disable */

import { Stack } from '@mui/material';
import { useCreateObjects, useGetObjectsByDataId } from 'api/sdk';
import { Button, Modal, Typography } from 'components';
import { useConfirm } from 'plugins/confirm';
import React, { useEffect, useState } from 'react';
import { CreateObjectSchema, ObjSchema } from 'schemas';

interface DropClassProps {
  open: boolean;
  onClose: () => void;
  onStartTraining: () => void;
  onViewData: () => void;
}

const ViewDataModal: React.FC<DropClassProps> = ({
  open,
  onClose,
  onStartTraining,
  onViewData
}) => {
  const confirm = useConfirm();

  return (
    <Modal open={open} onClose={onClose}>
      <Stack direction={'row'} justifyContent={'center'} spacing={2} mb={2}>
        <Typography
          style={{ alignItems: 'center', display: 'flex', fontWeight: 'bold' }}
        >
          {' '}
          Do you want to see the Training data ?{' '}
        </Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'center'} spacing={2}>
        <Button onClick={() => onStartTraining()}> Start Training</Button>
        <Button onClick={() => onViewData()}>View Data</Button>
      </Stack>
    </Modal>
  );
};

export default ViewDataModal;
