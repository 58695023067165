import { Typography } from '@mui/material';
import { Modal } from 'components';
import React, { useEffect, useState } from 'react';

interface videoProps {
  img: any;
  open: boolean;
  onClose: () => void;
  loading: boolean;
  streamError: string | null;
  stateValue: any;
  endStream: any;
}
export const VideoModal: React.FC<videoProps> = ({
  img,
  onClose,
  open,
  loading,
  stateValue,
  streamError,
  endStream
}) => {
  const handleClose = () => {
    endStream();
    // if (stateValue?.type === "success")
  };
  useEffect(() => {
    if (stateValue?.type === 'success') {
      onClose();
    }
  }, [stateValue]);
  return (
    <Modal open={open} onClose={handleClose} size="lg">
      {streamError && <Typography>{streamError}</Typography>}
      {loading ? (
        <>
          <div className="loader"> loading PLease wait...</div>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src={img} alt="" />
        </div>
      )}
    </Modal>
  );
};
