import React, { useState, useEffect, useRef } from 'react';
import Modal from 'components/Modal';
import { Stack } from '@mui/material';
import { Button, Typography } from 'components';
import { useSaveLabaelAssist } from 'api/sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { ObjSchema } from 'schemas';
import { useSnack } from 'plugins/snack';

interface DropClassModalProps {
  DropClassModal: boolean;
  onClose: (data: boolean) => void;
  selectedConfig: string;
  handleSync: (
    data: ObjSchema[],
    configuration_id: string,
    inference_id: string
  ) => Promise<any>;
  data: ObjSchema[];

  currentInferenceID: string;
}

const DropClass: React.FC<DropClassModalProps> = ({
  DropClassModal,
  onClose,
  handleSync,
  data,
  currentInferenceID,
  selectedConfig
}) => {
  const navigate = useNavigate();
  const { mutateAsync: saveData, isLoading: saveLoading } =
    useSaveLabaelAssist();
  const snack = useSnack();

  return (
    <Modal open={DropClassModal} onClose={() => onClose(false)}>
      <Typography>
        one or more classes has 0 images, do you want to drop them to save this
        dataset?
      </Typography>

      <Stack direction={'row'} spacing={1} justifyContent={'center'}>
        <Button version="light" onClick={() => onClose(false)}>
          cancel
        </Button>
        <Button
          //   loading={isLoading}
          //   disabled={isLoading}
          onClick={() => {
            const updatedObjects = data.filter(
              i => i.images && i.images.length > 0
            );
            const removedClasses = data.filter(
              i => !i.images || i.images.length === 0
            );
            const names = removedClasses.map(i => i.classname);
            handleSync(
              updatedObjects,
              data[0].configuration_id,
              currentInferenceID
            ).then(res => {
              saveData({
                dataId: currentInferenceID,
                configId: selectedConfig || ''
              }).then(res => {
                navigate('/Media/Media');
              });
            });
          }}
        >
          Proceed
        </Button>
      </Stack>
    </Modal>
  );
};

export default DropClass;
