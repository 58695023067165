import {
  Box,
  Checkbox,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Contents } from '../../schemasMedia';
import Folder from '../../assets/images/Folder.png';
import DraggableItem from './Draggable';
import ImageModal from './imageViewer';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import ListUnzipping from './ListUnzipping';
import { useRenameDirectoryFileManagerRenameDirectoryPatch } from 'api/media';
import { useSnack } from 'plugins/snack';
import ListViewRow from './ListViewRow';
interface ListViewProps {
  files: Contents[];
  setSearchMode: (data: boolean) => void;
  selectCurrentDirectory: (path: string) => void;

  setSearchQueryText: (data: string) => void;
  setSearchQuery: (data: string) => void;
  // setCurrentDirectory: (data: string) => void;
  currentDirectory: string;
  searchMode: boolean;
  setSelectedFiles: (data: Contents[]) => void;
  selectedFiles: Contents[];
  handleSelectFile: (file: Contents) => void;
  handleRefetchDirectory: () => void;
  editName: boolean;
  setEditName: (data: boolean) => void;
  setFiles: (data: Contents[]) => void;
  handleContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  copyFiles: Contents[];

  type: string;
}

const ListView: React.FC<ListViewProps> = ({
  files,
  setSearchMode,
  setSearchQueryText,
  setSearchQuery,
  selectCurrentDirectory,
  currentDirectory,
  setSelectedFiles,
  searchMode,
  selectedFiles,
  handleSelectFile,
  handleRefetchDirectory,
  editName,
  setEditName,
  setFiles,
  handleContextMenu,
  copyFiles,
  type
}) => {
  const [newName, setNewName] = useState<string>('');
  const snack = useSnack();
  const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    border: `1px solid #ededed`,
    padding: '2px',
    backgroundColor: '#ededed',
    position: 'sticky',
    top: 0,
    zIndex: 1
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '10px'
  }));
  const getBG = (selected: boolean, hovered: boolean, disabled: boolean) => {
    if (disabled) return '#ededed';
    if (selected) return '#EBF3F8';
    if (hovered) return '#CFE2ED';
    return 'fff';
  };

  const StyledTableRow = styled(TableRow)<{
    hovered: boolean;
    selected: boolean;
    disabled: boolean;
  }>(({ hovered, selected, disabled }) => ({
    padding: '10px',
    backgroundColor: getBG(selected, hovered, disabled)
  }));

  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [imageModal, setImageModal] = useState<boolean>(false);
  const [imgSrc, setImgsrc] = useState<string>('');
  const isAdmin = useSelector((state: RootState) =>
    state.auth.profile?.role == 'organization_admin' ? true : false
  );

  const { mutateAsync: rename } =
    useRenameDirectoryFileManagerRenameDirectoryPatch();

  const handleRename = (file: Contents) => {
    const len = file?.path?.split('/').length;
    const oldName = len && file?.path?.[len - 1];

    if (!oldName || oldName == newName) {
      setEditName(false);
      return;
    }

    rename({
      params: {
        directory_path: file.path || '',
        new_name: newName,
        current_directory: currentDirectory
      }
    })
      .then(() => {
        handleRefetchDirectory();
        setEditName(false);
        setNewName('');
      })
      .catch(error => {
        snack({
          message: error.response.data.detail,
          severity: 'error'
        });
      });
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        overflowY: 'auto',
        borderRadius: '8px',
        height: '100%'
      }}
    >
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableHeader align="left">
              <Typography
                variant="caption"
                style={{ fontWeight: 'bold', width: '100px' }}
              ></Typography>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              <Typography
                variant="caption"
                style={{ fontWeight: 'bold', width: '100px' }}
              >
                Name
              </Typography>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                Path
              </Typography>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                Created Time
              </Typography>
            </StyledTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.length > 0 &&
            files
              ?.filter(file => file.type !== 'application/zip')
              .map((item, index) => (
                <>
                  <ListViewRow
                    key={index}
                    selected={selectedFiles
                      .map(i => i.name)
                      .includes(item.name)}
                    fileType={item.type || ''}
                    name={item.name || ''}
                    handleRefetchDirectory={handleRefetchDirectory}
                    selectCurrentDirectory={selectCurrentDirectory}
                    currentDirectory={currentDirectory}
                    handleSelectFile={handleSelectFile}
                    setSelectedFiles={setSelectedFiles}
                    selectedFiles={selectedFiles}
                    file={item}
                    setFiles={setFiles}
                    editName={editName}
                    setEditName={setEditName}
                    handleContextMenu={handleContextMenu}
                    setSearchMode={setSearchMode}
                    setSearchQueryText={setSearchQueryText}
                    setSearchQuery={setSearchQuery}
                    imageModal={imageModal}
                    imageSrc={imgSrc}
                    setImageModal={setImageModal}
                    setImgsrc={setImgsrc}
                    disabled={
                      copyFiles.map(i => i.path).includes(item.path) &&
                      type === 'move'
                    }
                    index={index}
                    searchMode={searchMode}
                  />
                </>
              ))}
          <ListUnzipping
            handleRefetchDirectory={handleRefetchDirectory}
            currentDirectory={currentDirectory}
          />
        </TableBody>
      </Table>
      <ImageModal
        imageModal={imageModal}
        imageSrc={imgSrc}
        setimgSrc={setImgsrc}
        setImageModal={setImageModal}
      />
    </TableContainer>
  );
};

export default ListView;
