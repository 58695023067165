import React from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Typography,
  Stack
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Modal, Button } from 'components';
import { SelectInput } from 'components/Form';
import { useUpdateCamera } from 'api/sdk'; // Change to useUpdateCamera
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CameraSchema } from 'schemas';

const validationSchema = Yup.object({
  cameraName: Yup.string().required('Camera Name is required'),
  cameraURL: Yup.string().required('Camera URL is required')
});

interface EditCameraModalProps {
  open: boolean;
  onClose: (data: boolean) => void;

  selectedCamera: CameraSchema;
}

const EditCamera: React.FC<EditCameraModalProps> = ({
  open,
  onClose,
  selectedCamera
}) => {
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );

  // Initialize values based on selected camera details
  const initialValues = {
    cameraName: selectedCamera?.name || '',
    cameraURL: selectedCamera?.url || ''
  };

  const { mutateAsync: updateCamera, isLoading: updateCameraLoading } =
    useUpdateCamera();

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box p={2}>
        <Typography variant="h6" mb={2}>
          Edit Camera
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              await updateCamera({
                id: selectedConfig || '',
                cameraId: selectedCamera?.id || '',
                params: { url: values.cameraURL }
              });
              onClose(true);
            } catch (error) {
              // Handle any errors that occur during the async operation
              console.error('An error occurred:', error);
            }
          }}
        >
          {({ errors, touched, handleChange, handleBlur, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    fullWidth
                    size="small"
                    label="Enter Camera Name"
                    name="cameraName"
                    value={values.cameraName} // Bind value
                    onChange={handleChange} // Bind change handler
                    onBlur={handleBlur} // Bind blur handler
                    error={touched.cameraName && Boolean(errors.cameraName)}
                    disabled={true}
                    // helperText={touched.cameraName && errors.cameraName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    size="small"
                    label="Camera URL"
                    name="cameraURL"
                    value={values.cameraURL} // Bind value
                    onChange={handleChange} // Bind change handler
                    onBlur={handleBlur} // Bind blur handler
                    error={touched.cameraURL && Boolean(errors.cameraURL)}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    fullWidth
                    size="small"
                    label="Enter Sampling Rate"
                    type="number"
                    name="samplingRate"
                    value={values.samplingRate} // Bind value
                    onChange={handleChange} // Bind change handler
                    onBlur={handleBlur} // Bind blur handler
                    error={touched.samplingRate && Boolean(errors.samplingRate)}
                    helperText={touched.samplingRate && errors.samplingRate}
                    InputProps={{ inputProps: { min: 1, max: 60 } }}
                  />
                </Grid> */}
              </Grid>
              <Stack
                mt={2}
                direction={'row'}
                justifyContent={'center'}
                spacing={2}
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  version="light"
                  onClick={() => onClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  loading={updateCameraLoading} // Change to updateCameraLoading
                >
                  Update
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditCamera;
