import React from 'react';
import { Grid, Card, Stack, Skeleton, Typography, Box } from '@mui/material';
import DoughnutChartSkeleton from './DoughnutChartSkeleton';

const SkeletonLoader = () => {
  return (
    <Box sx={{ padding: 2 }}>
      {/* Group Statistics Loader */}
      <Typography
        variant="h6"
        sx={{ color: '#1E4E6A', fontWeight: 'bold', mb: 1 }}
      >
        Group Statistics
      </Typography>
      <Card sx={{ backgroundColor: '#1E4E6A', borderRadius: 2, p: 2 }}>
        <Skeleton
          variant="rectangular"
          height={100}
          width="100%"
          sx={{ borderRadius: 2 }}
        />
      </Card>

      {/* Storage Details Loader */}
      <Typography
        variant="h6"
        sx={{ color: '#1E4E6A', fontWeight: 'bold', mt: 3, mb: 1 }}
      >
        Storage & Configuration Details
      </Typography>

      <Grid container spacing={2}>
        {/* Storage Details Skeleton */}
        <Grid item xs={12} md={4}>
          <Card sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, p: 2 }}>
            <DoughnutChartSkeleton />
          </Card>
        </Grid>

        {/* Configuration Details Skeleton */}
        <Grid item xs={12} md={8}>
          <Card sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, p: 2 }}>
            <Skeleton
              variant="rectangular"
              height={200}
              width="100%"
              sx={{ borderRadius: 2 }}
            />
            <Stack spacing={1} mt={2}>
              <Skeleton variant="text" width="40%" height={20} />
              <Skeleton variant="text" width="70%" height={20} />
              <Skeleton variant="text" width="90%" height={20} />
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SkeletonLoader;
