import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, TableContainer, Typography } from 'components';
import { Box, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SelectInput } from 'components/Form';
import AddCameraModal from './addCamerModal';
import {
  useDeleteCamera,
  useListCameras,
  useListConfigurations,
  useUpdateCamera
} from 'api/sdk';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAllConfigs, setSelectedConfiguration } from 'store/configSlice';
import { CameraSchema } from 'schemas';
import IconButton from '@mui/material/IconButton';
import { CircularProgress } from '@mui/material';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { useLiveInference } from 'hooks/useLiveInference';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { VideoModal } from './videModal';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { useConfirm } from 'plugins/confirm';
import EditCamera from './EditCamera';
export const LiveData: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [addCamera, setAddCamera] = useState<boolean>(false);
  const [listOfCameras, setListOfCameras] = useState<CameraSchema[]>([]);
  const [filteredlistOfCameras, setFilteredListOfCameras] =
    useState<CameraSchema[]>(listOfCameras);
  const confirm = useConfirm();

  const [selectedCamera, setSelectedCamera] = useState<string | null>(null);
  const [EditCameraModal, setCaEditCameraModal] = useState<boolean>(false);
  const [selecteCameraToEdit, setSelecteCameraToEdit] =
    useState<CameraSchema>();

  const [videoModalOPen, setVideoModalOpen] = useState<boolean>(false);

  const configs = useSelector((state: RootState) => state.config.allConfigs);
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const dispatch = useDispatch();
  const {
    data,
    refetch: refetchListOfCameras,
    isLoading: cameraLoading,
    isFetching: cameraFetching
  } = useListCameras(selectedConfig || '');

  const totalLoading = useMemo(
    () => cameraLoading || cameraFetching,
    [cameraLoading, cameraFetching]
  );

  useEffect(() => {
    setListOfCameras([]);
    setFilteredListOfCameras([]);
    refetchListOfCameras().then(res => {
      setListOfCameras(res.data?.data || []);
      setFilteredListOfCameras(res.data?.data || []);
    });
  }, [selectedConfig]);

  const {
    startLive,
    started,
    loading,
    setLoading,
    endInference,
    startStream,
    streamError,
    endStream,
    activityData,
    setStreamError,
    image,
    setImage,
    sendMessage,
    isReseting,
    stateValue,
    setResetting,
    socket
  } = useLiveInference();
  const profile = useSelector((state: RootState) => state.auth.profile);
  const { isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });

  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setImage('');
    }, 1);

    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 1000); // Stop after 10 ms

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (started) {
        endInference();
      }
      if (image) {
        endStream();
      }
    };
  }, [data]);

  useEffect(() => {
    const cam = listOfCameras.filter(i => {
      return i.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredListOfCameras(cam);
  }, [searchQuery]);
  // function formatDateTime(dateTimeString: string) {
  //   const dateObj = new Date(dateTimeString);

  //   // Extract components
  //   const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  //   const day = String(dateObj.getDate()).padStart(2, '0');
  //   const year = dateObj.getFullYear();
  //   const hours = String(dateObj.getHours()).padStart(2, '0');
  //   const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  //   // Format as mm-dd-yyyy hh:mm
  //   return `${month}-${day}-${year} ${hours}:${minutes}`;
  // }
  const { mutateAsync: deleteCamera } = useDeleteCamera();

  return (
    <Card>
      <Stack
        direction={'row'}
        sx={{ borderBottom: '1px solid #ededed' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <div style={{ fontFamily: 'Proxima-Nova-Bold', fontSize: '18px' }}>
          Live Camera
        </div>
        <SelectInput
          label="Select Configuration"
          name="configuration"
          value={selectedConfig}
          onChange={e => {
            dispatch(setSelectedConfiguration(e));
          }}
          options={
            configs?.map(i => ({
              value: i.id,
              label: i.name
            })) || []
          }
          width={'250px'}
        />
      </Stack>
      <Stack>
        <Stack
          mt={1}
          padding={1}
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ backgroundColor: '#ededed' }}
        >
          <Box></Box>
          <TextField
            sx={{ width: '300px' }}
            variant="outlined"
            placeholder="Search"
            fullWidth
            size="small"
            value={searchQuery}
            onChange={e => {
              setSearchQuery(e.target.value);
            }}
          />
          <Stack direction={'row'}>
            <Button
              disabled={!selectedConfig}
              onClick={() => setAddCamera(true)}
            >
              <AddIcon />
              Add camera
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> Camera name </TableCell>
              <TableCell align="right"> Created At</TableCell>
              <TableCell align="right"> URL</TableCell>
              <TableCell align="right"> Status</TableCell>

              {/* <TableCell align="right">Sampling Rate</TableCell> */}
              <TableCell align="right"> Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!totalLoading &&
              filteredlistOfCameras.map(row => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {moment
                      .utc(row.created_at)
                      .local()
                      .format('MM-DD-YYYY HH:mm:ss')}
                  </TableCell>

                  <TableCell align="right">{row.url}</TableCell>
                  <TableCell align="right">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}
                    >
                      {row.status === 'ACTIVE' && (
                        <div style={{ color: 'green', fontSize: '32px' }}>
                          •
                        </div>
                      )}
                      {row.status === 'INACTIVE' && (
                        <div style={{ color: 'red', fontSize: '32px' }}>•</div>
                      )}

                      {row.status}
                    </div>
                  </TableCell>

                  <TableCell align="right">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                      }}
                    >
                      <Button
                        onClick={() => {
                          setVideoModalOpen(true);
                          setImage('');
                          startStream(row.url, row.id);
                        }}
                      >
                        play
                      </Button>
                      <IconButton
                        onClick={() => {
                          confirm({
                            title: 'Delete',
                            content:
                              'Are you sure you want to delete this camera?'
                          }).then(() => {
                            deleteCamera({
                              id: selectedConfig || '',
                              cameraId: row.id
                            }).then(res => {
                              refetchListOfCameras().then(res => {
                                setListOfCameras(res.data?.data || []);
                                setFilteredListOfCameras(res.data?.data || []);
                              });
                            });
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setCaEditCameraModal(true);
                          setSelecteCameraToEdit(row);
                        }}
                      >
                        <ModeEditIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {selecteCameraToEdit && (
            <EditCamera
              open={EditCameraModal}
              onClose={() => {
                setCaEditCameraModal(false);
              }}
              selectedCamera={selecteCameraToEdit}
            />
          )}
        </Table>
      </TableContainer>
      {totalLoading && (
        <>
          {' '}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              mt: 10
            }}
          >
            <CircularProgress />
          </Box>
        </>
      )}
      <AddCameraModal
        open={addCamera}
        onClose={() => setAddCamera(false)}
        onSubmit={() => {
          setAddCamera(false);
          refetchListOfCameras().then(res => {
            setListOfCameras(res.data?.data || []);
            setFilteredListOfCameras(res.data?.data || []);
          });
        }}
      />
      <VideoModal
        onClose={() => {
          setVideoModalOpen(false);
          setStreamError(null);
          setImage('');
        }}
        open={videoModalOPen}
        img={image}
        loading={loading}
        streamError={streamError}
        stateValue={stateValue}
        endStream={endStream}
      />
    </Card>
  );
};
