import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  TableContainer,
  Typography
} from 'components';
import React, { useRef, useState, useEffect } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  CardActionArea,
  IconButton,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Divider,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useListConfigurations } from 'api/sdk';
import { useSelector } from 'react-redux';
import { ExpandableListReport } from 'pages/Reports/expandableListReport';
import moment from 'moment';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import { FORMAT_DATE } from 'utils';
import { Barchart } from 'components/charts/barchart';
import BarChartComponent from 'components/charts/barChartReport';
import axios from 'axios';
import Inference from '../../assets/images/inference.svg';
import BarChartComponentError from 'components/charts/barChartReport/indexError';

import IncrementalBarChartComponentError from 'components/charts/barChartReport/indexBefore';
import BarChartComponentIncremental from 'components/charts/barChartReport/indexIncremental';
import BarChartComponentAfterIncremental from 'components/charts/barChartReport/indexAfterIncremental';
import { RootState } from 'store';
import Reports from '../../assets/images/reportsQuality.svg';
import { Graph } from 'pages/InferenceReports/graph';
import ConfusionMatrix from './confusionMatrix';
import reportsNotFound from '../../assets/images/reportsNotFound.svg';
import forwardArrow from '../../assets/images/forwardArrow.svg';
import SkeletonLoader from 'components/SkeletonLoader';
import { ListTable } from 'components/ListTable';
import ChartComponentReportPage from 'pages/InferenceReports/graphScrollReport';
import ChartComponentReportPageUnlabel from 'pages/InferenceReports/graphScrollReportUnlabel';

interface MainProps {
  onChange: (value: number) => void;
  currentInferenceID: string | undefined;
  reportsLoaded: boolean;
  inferenceReport: any;
}

interface ConfusionMatrixProps {
  data: {
    [actualClass: string]: {
      [predictedClass: string]: number;
    };
  };
}

interface TrainingReport {
  train_accuracy: number;
  train_error: number;
  test_accuracy: number;
  test_error: number;
  validation_accuracy: number;
  validation_loss: number;
}
interface InferenceValue {
  mode: string;
}
enum DataType {
  Label = 'Label',
  UnLabel = 'UnLabel'
}
interface IncrementalTrainingReport {
  before_incremental: {
    train_accuracy: number;
    [key: string]: any; // Add other properties if necessary
  };
  after_incremental: {
    train_accuracy: number;
    [key: string]: any; // Add other properties if necessary
  };
}

interface ModelReport {
  model_name: string; // "test56"
  model_architecture: string; // "resnet18"
  train_split: number; // 80 (changed key to match common spelling: train_split)
  batch_size: number; // 8
  learning_rate: number; // 0.001
  total_epochs: number; // 10
  current_epoch: number; // 10
  test_accuracy: number; // 1 (0 to 1, where 1 represents 100%)
  status: string; // "COMPLETED"
  total_image_count: number; // 1344
  class_count: number; // 2
  model_id: string; // "050f6d95-ac45-485e-80a0-5509a8637319"
  mode: string; // "Training" (or other modes as applicable)
  training_started_at: string; // Date in ISO format (e.g., "2024-11-13T09:05:26.671860")
  inference_id: string; // "aa49b774-aaa3-46d4-a34f-441a406bd417"
}

interface InferenceReport {
  training_report: TrainingReport[];
  model_details: ModelReport[];
  incremental_training_report?: IncrementalTrainingReport[];
  inference_report_label?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
    true_positive: number;
    true_negative: number;
    false_positive: number;
    false_nagative: number;
    accuracy: number;
  }>;
  inference_report_unlabel?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
  }>;
}

interface InferenceData {
  mode: string;
}
export const ViewReports: React.FC<MainProps> = ({
  onChange,
  currentInferenceID,
  reportsLoaded,
  inferenceReport
}) => {
  const profile = useSelector((state: RootState) => state.auth.profile);
  const { data, isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });

  const [distributionModal, setDistributionModal] = useState(false);
  const [distributionValue, setDistributionValue] = useState<any>('');
  const [actualModal, setActualModal] = useState(false);
  const [selectedMatrixData, setSelectedMatrixData] = useState<object | null>(
    null
  );
  const [selectedDataType, setSelectedDataType] = useState<string>('labelled');
  const [advancedDetails, setAdvancedDetails] = useState(false);
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvancedDetails(event.target.checked);
    // Dispatch any action or handle toggle state change if necessary
  };
  const dataDistributionData = async (
    Inference_id: any,
    No_of_labelled_Images: any
  ) => {
    let dataValue =
      No_of_labelled_Images === 0 ? DataType.UnLabel : DataType.Label;
    No_of_labelled_Images === 0
      ? setSelectedDataType('unlabeled')
      : setSelectedDataType('labelled');
    // 'http://qcdev-api.ind.gvap.dimaagai.net/configurations/6cb95ff4-2e0b-43ed-b752-e32175238d8d/inference/6cb95ff4-2e0b-43ed-b752-e32175238d8d/data_distribution?type=Label'

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/configurations/${inferenceReport?.config_id}/inference/${Inference_id}/data_distribution?type=${dataValue}`;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data distribution');
      }

      const data = await response.json();
      setDistributionValue(data);
    } catch (error) {
      console.error('Error fetching data distribution:', error);
    }
  };
  const handleOpen = () => {
    setDistributionModal(true);
  };
  const handleMatrixOpen = () => {
    setActualModal(true);
  };
  const handleActualClose = () => setActualModal(false);

  const handleClose = () => setDistributionModal(false);

  const dataChart = {
    train: {
      accuracy: inferenceReport?.training_report[0]?.train_accuracy * 100 || 0,
      error: inferenceReport?.training_report[0]?.train_error || 0
    },
    test: {
      accuracy: inferenceReport?.training_report[0]?.test_accuracy * 100 || 0,
      error: inferenceReport?.training_report[0]?.test_error || 0
    },
    validation: {
      accuracy:
        inferenceReport?.training_report[0]?.validation_accuracy * 100 || 0,
      error: inferenceReport?.training_report[0]?.validation_loss || 0
    }
  };
  const dataChartBefore = {
    train: {
      accuracy:
        inferenceReport?.incremental_training_report?.[0]?.before_incremental
          .train_accuracy || 0,
      accuracy_old:
        inferenceReport?.incremental_training_report?.[0]?.after_incremental
          .train_accuracy || 0,
      error:
        inferenceReport?.incremental_training_report?.[0]?.before_incremental
          .train_error || 0,
      error_old:
        inferenceReport?.incremental_training_report?.[0]?.after_incremental
          .train_error || 0
    },
    test: {
      accuracy:
        inferenceReport?.incremental_training_report?.[0]?.before_incremental
          .test_accuracy || 0,
      accuracy_old:
        inferenceReport?.incremental_training_report?.[0]?.after_incremental
          .test_accuracy || 0,
      error:
        inferenceReport?.incremental_training_report?.[0]?.before_incremental
          .test_error || 0,
      error_old:
        inferenceReport?.incremental_training_report?.[0]?.after_incremental
          .test_error || 0
    },
    validation: {
      accuracy:
        inferenceReport?.incremental_training_report?.[0]?.before_incremental
          .validation_accuracy || 0,
      accuracy_old:
        inferenceReport?.incremental_training_report?.[0]?.after_incremental
          .validation_accuracy || 0,
      error:
        inferenceReport?.incremental_training_report?.[0]?.before_incremental
          .validation_loss || 0, // Assuming "validation_loss" represents the error
      error_old:
        inferenceReport?.incremental_training_report?.[0]?.after_incremental
          .validation_loss || 0 // Assuming "validation_loss_old" represents the old error
    }
  };

  const [getModel, setModel] = useState<string>('labelled');
  function formatDateTime(dateTimeString: string) {
    const dateObj = new Date(dateTimeString);

    // Extract components
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');

    // Format as mm-dd-yyyy hh:mm
    return `${month}-${day}-${year} ${hours}:${minutes}`;
  }

  // Assuming `trainingStatus` is an object that holds confusion matrix data
  const handleClick = (trainingStatus: any) => {
    if (trainingStatus) {
      setSelectedMatrixData(trainingStatus); // Set the confusion matrix data
      handleMatrixOpen(); // Open the modal
    }
  };
  const Labelcolumns: ITableHeadColumn[] = [
    {
      value: 'Inference Name',
      key: 'inferenceName'
    },
    {
      value: 'Dataset Name',
      key: 'groupname'
    },
    {
      value: 'Inference Started At',
      key: 'date'
    },
    {
      value: 'No. of Image Uploaded',
      key: 'No_of_Image_Uploaded'
    },
    {
      value: 'No. of Classes',
      key: 'No_of_Classes'
    },
    {
      value: 'No. of Unlabeled Images',
      key: 'No_of_Unlabelled_Images'
    },
    {
      value: 'No. of Labeled Images',
      key: 'No_of_labelled_Images'
    },
    {
      value: 'Accuracy (Labeled Images)',
      key: 'Accuracy'
    },
    {
      value: 'Data Distribution',
      key: 'data_distribution',
      render: ({ value, record }) => {
        return (
          <IconButton
            onClick={() => {
              dataDistributionData(
                record?.Inference_Id,
                record?.No_of_labelled_Images
              );

              handleOpen();
            }}
          >
            <img
              src={Reports}
              alt="Report"
              style={{ width: '30px', height: '30px' }}
            />
          </IconButton>
        );
      }
    },
    {
      value: 'Confusion Metrics',
      key: 'confusion_metric',
      render: ({ value, record }) => {
        const trainingStatus = record?.Confusion_Matrix;

        return (
          <IconButton
            onClick={() => {
              handleClick(trainingStatus);
            }}
            disabled={
              record?.No_of_labelled_Images === 0 ||
              trainingStatus == '' ||
              trainingStatus == undefined
            }
          >
            <img
              src={
                record?.No_of_labelled_Images === 0 ||
                trainingStatus == '' ||
                trainingStatus == undefined
                  ? reportsNotFound
                  : Reports
              }
              alt="Report"
              style={{ width: '30px', height: '30px' }}
            />
          </IconButton>
        );
      }
    }
    // {
    //   value: 'False Negative',
    //   key: 'False_Negative'
    // },
    // {
    //   value: 'Accuracy %',
    //   key: 'Accuracy'
    // }
  ];

  const labelRows =
    (inferenceReport.inference_report &&
      inferenceReport.inference_report?.map((item: any, index: any) => ({
        serialno: index + 1,
        No_of_Image_Uploaded: item.total_images + item.unlabeled_count,
        No_of_Classes: item.class_count,
        No_of_Unlabelled_Images: item.unlabeled_count
          ? item.unlabeled_count
          : 0,
        No_of_labelled_Images: item.total_images,
        Accuracy: `${item.accuracy.toFixed(2)}%`,
        Confusion_Matrix: item?.confusion_matrix,
        Inference_Id: item?.data_id,
        groupname: item?.datasetname,
        inferenceName: item?.inference_name,
        date: formatDateTime(item?.inference_started_at)
      }))) ||
    [];

  const unLabelcolumns: ITableHeadColumn[] = [
    {
      value: 'Inference Name',
      key: 'inferenceName'
    },
    {
      value: 'No. of Image Uploadedp',
      key: 'No_of_Image_Uploaded'
    },
    {
      value: 'No. of OK Images',
      key: 'No_of_OK_Images'
    },
    {
      value: 'No. of NG Images',
      key: 'No_of_NG_Images'
    }
  ];
  const unlabelRows =
    (inferenceReport.inference_report_unlabel &&
      inferenceReport.inference_report_unlabel?.map(
        (item: any, index: any) => ({
          serialno: index + 1,
          No_of_Image_Uploaded: item.total_images,
          No_of_OK_Images: item.ok_images,
          No_of_NG_Images: item.ng_images
        })
      )) ||
    [];
  console.log(inferenceReport);
  return reportsLoaded ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <SkeletonLoader />
    </div>
  ) : (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        {/* <Button
            version="light"
            onClick={() => {
              // setReport(true);
              onChange(1);
            }}
          >
            Back
          </Button> */}
        <div
          onClick={() => {
            onChange(1);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <img
              src={forwardArrow}
              alt="Forward Arrow"
              style={{
                marginRight: '8px',
                height: '12px'
              }}
            />
            <Typography
              variant="h6"
              fontSize="14px"
              style={{
                fontWeight: 'bold',
                textDecoration: 'underline',
                color: '#102633'
              }}
            >
              Back
            </Typography>
          </Box>
        </div>
      </div>
      {/* Model Details */}
      <Card
        style={{
          marginTop: '2vh',
          padding: '2vh',
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Stack spacing={1}>
          {/* Title */}
          <Typography variant="h6" fontWeight="bold">
            Reports
          </Typography>

          {/* Model Details */}
          <Stack spacing={1} display={'flex'} justifyContent={'space-between'}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  fontWeight="bold"
                >
                  Model Details:
                </Typography>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={advancedDetails}
                      onChange={handleToggleChange}
                      name="toggleButton"
                      color="primary"
                    />
                  }
                  label="Advanced Details"
                  style={{ marginLeft: '18px', marginRight: '30px' }}
                  labelPlacement="start" // Ensure label is on the left (before the switch)
                />
              </Grid>
            </Grid>
          </Stack>

          {/* Grid for Details */}
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="body2" color="textSecondary">
                Configuration Name
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                <Tooltip
                  title={
                    inferenceReport?.config_name?.length > 15
                      ? inferenceReport?.config_name
                      : ''
                  }
                >
                  {inferenceReport?.config_name &&
                  inferenceReport?.config_name?.length > 15
                    ? `${inferenceReport?.config_name.slice(0, 15)}...`
                    : inferenceReport?.config_name}
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="body2" color="textSecondary">
                DataSet Name
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {inferenceReport?.model_details?.datasetname}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="body2" color="textSecondary">
                Training started at
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {formatDateTime(
                  inferenceReport?.model_details?.training_started_at
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="body2" color="textSecondary">
                Model Name
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                <Tooltip
                  title={
                    inferenceReport &&
                    inferenceReport?.model_details?.model_name?.length > 15
                      ? inferenceReport?.model_details?.model_name
                      : ''
                  }
                >
                  {inferenceReport &&
                  inferenceReport?.model_details?.model_name?.length > 15
                    ? `${inferenceReport?.model_details?.model_name?.slice(
                        0,
                        15
                      )}...`
                    : inferenceReport?.model_details?.model_name || 'Model 1'}
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="body2" color="textSecondary">
                Total # Images
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {' '}
                {inferenceReport?.model_details?.total_image_count}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="body2" color="textSecondary">
                Total # Classes
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {inferenceReport?.model_details?.class_count}
              </Typography>
            </Grid>

            {advancedDetails && (
              <>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Typography variant="body2" color="textSecondary">
                    Model Architecture
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {' '}
                    {inferenceReport?.model_details?.model_architecture}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Typography variant="body2" color="textSecondary">
                    Train Test Split
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {inferenceReport?.model_details?.train_spilt}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Typography variant="body2" color="textSecondary">
                    Batch Size
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {' '}
                    {inferenceReport?.model_details?.batch_size}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Typography variant="body2" color="textSecondary">
                    Learning Rate
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {inferenceReport?.model_details?.learning_rate}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <Typography variant="body2" color="textSecondary">
                    Epochs
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {inferenceReport?.model_details?.total_epochs}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </Card>
      {/* Modal component For Data Distribution*/}
      <Modal
        open={distributionModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        size="lg"
      >
        {/* {distributionValue && (
                <Graph height={'100%'} width={'100%'} data={distributionValue} />
              )} */}
        {/* <Box
          sx={{
            width: '50vw', // Width set to 80% of the viewport width
            height: '50vh', // Height set to 80% of the viewport height
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: 24,
            overflow: 'auto', // Allow scrolling if content is too large
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        > */}
        {distributionValue &&
          (selectedDataType === 'labelled' ? (
            <ChartComponentReportPage
              height={'100%'}
              width={'100%'}
              data={distributionValue}
            />
          ) : (
            <ChartComponentReportPageUnlabel
              height={'100%'}
              width={'100%'}
              data={distributionValue}
            />
          ))}
        {/* </Box> */}
      </Modal>
      {/* Modal component For Advance Matrix*/}
      <Modal
        open={actualModal}
        onClose={handleActualClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {selectedMatrixData && <ConfusionMatrix data={selectedMatrixData} />}
      </Modal>

      {/* {inference.mode === 'TRAINING' && ( */}
      {inferenceReport?.incremental_training_report?.length === 0 && (
        <Card
          style={{
            // height: '30vh',
            marginTop: '2vh'
          }}
        >
          {' '}
          <Stack mt={1} direction={'row'} justifyContent={'space-between'}>
            <Typography variant="subtitle1" color="primary" fontWeight="bold">
              Training Report
            </Typography>
          </Stack>
          <Grid container xs={12} mt={1}>
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Training Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.training_report?.[0]?.train_accuracy !==
                    undefined
                      ? (
                          inferenceReport?.training_report[0]?.train_accuracy *
                          100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Test Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.training_report[0]?.test_accuracy !==
                    undefined
                      ? (
                          inferenceReport?.training_report[0]?.test_accuracy *
                          100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Validation Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.training_report[0]
                      ?.validation_accuracy !== undefined
                      ? (
                          inferenceReport?.training_report[0]
                            ?.validation_accuracy * 100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Centered Vertical Divider */}

            <Grid
              item
              xs={6}
              justifyContent="center"
              style={{ height: '137px' }}
            >
              <BarChartComponent
                data={dataChart}
                width="10px"
                height="172px !important"
              />
            </Grid>
            {/* <Grid item xs={3}>
              <BarChartComponentError
                data={dataChart}
                width="10px"
                height="172px !important"
              />
            </Grid> */}
            {/* <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <BarChartComponent
                        data={dataChart}
                        width="10px"
                        height="172px !important"
                      />
                    </Grid> */}
          </Grid>
        </Card>
      )}
      {/* )} */}

      {/* Incremental Training Card */}
      {/* {inference.mode === 'INCREMENTAL_TRAINING' && ( */}
      {inferenceReport?.incremental_training_report?.length > 0 && (
        <Card
          style={{
            // height: '30vh',
            marginTop: '1vh'
            // overflowY: 'auto', // This will allow vertical scrolling
            // overflowX: 'hidden' // This hides horizontal overflow, if needed
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="subtitle1" color="primary" fontWeight="bold">
              Incremental Training Report
            </Typography>
          </Stack>

          <Grid container xs={12}>
            <Grid item xs={6}>
              <Stack direction={'row'} justifyContent={'space-between'} mt={1}>
                <Typography variant="body1" fontWeight="bold">
                  Before
                </Typography>
              </Stack>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Train Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    {' '}
                    :{' '}
                    {inferenceReport?.incremental_training_report?.[0]
                      ?.before_incremental.train_accuracy !== undefined
                      ? (
                          inferenceReport?.incremental_training_report?.[0]
                            ?.before_incremental.train_accuracy * 100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Test Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.incremental_training_report?.[0]
                      ?.before_incremental.test_accuracy !== undefined
                      ? (
                          inferenceReport?.incremental_training_report?.[0]
                            ?.before_incremental.test_accuracy * 100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Validation Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.incremental_training_report?.[0]
                      ?.before_incremental.validation_accuracy !== undefined
                      ? (
                          inferenceReport?.incremental_training_report?.[0]
                            ?.before_incremental.validation_accuracy * 100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
              <Stack direction={'row'} justifyContent={'space-between'} mt={1}>
                <Typography variant="body1" fontWeight="bold">
                  After
                </Typography>
              </Stack>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Train Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.incremental_training_report?.[0]
                      ?.after_incremental.train_accuracy !== undefined
                      ? (
                          inferenceReport?.incremental_training_report?.[0]
                            ?.after_incremental.train_accuracy * 100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Test Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.incremental_training_report?.[0]
                      ?.after_incremental.test_accuracy !== undefined
                      ? (
                          inferenceReport?.incremental_training_report?.[0]
                            ?.after_incremental.test_accuracy * 100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={0.2}
                ml={0.1}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body2" color="textSecondary">
                    Validation Accuracy
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {' '}
                  <Typography variant="body1" fontWeight="bold">
                    :{' '}
                    {inferenceReport?.incremental_training_report?.[0]
                      ?.after_incremental.validation_accuracy !== undefined
                      ? (
                          inferenceReport?.incremental_training_report?.[0]
                            ?.after_incremental.validation_accuracy * 100
                        ).toFixed(2)
                      : 'No data available'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <BarChartComponentIncremental
                data={dataChartBefore}
                width="400px"
                height="172px !important"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {/* )} */}
      <Card
        style={{
          // height: '40vh',
          marginTop: '2vh'
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Grid container xs={12}>
            {' '}
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <Typography style={{ fontSize: '16px' }} fontWeight="bold">
                Inference Report
              </Typography>{' '}
            </Grid>
            {getModel == 'labelled' && (
              <>
                <Grid item xs={12}>
                  <Stack mt={2} style={{ height: '30vh' }}>
                    <ListTable
                      stickyHeader={true}
                      columns={Labelcolumns}
                      rows={labelRows}
                      striped={true}
                      tableContainerStyle={{ height: '230px' }}
                    />
                  </Stack>
                </Grid>
              </>
            )}
            {getModel == 'unlabeled' && (
              <>
                <Grid item xs={12}>
                  <Stack mt={2} style={{ height: '20vh' }}>
                    <ListTable
                      stickyHeader={true}
                      columns={unLabelcolumns}
                      rows={unlabelRows}
                      striped={true}
                      tableContainerStyle={{ height: '230px' }}
                    />
                  </Stack>
                </Grid>
              </>
            )}
            {getModel == '' && (
              <>
                <Grid
                  item
                  xs={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Stack
                    mt={2}
                    style={{
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '20vh'
                    }}
                  >
                    <img
                      src={Inference}
                      alt="Inference"
                      style={{ width: '150px', height: '200px' }}
                    />
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </Card>
    </>
  );
};
