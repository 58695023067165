import React from 'react';
import { Grid, Box, Stack, Skeleton, Typography } from '@mui/material';

const DoughnutChartSkeleton = () => {
  return (
    <Grid container spacing={4} alignItems="center">
      {/* Doughnut Chart Skeleton */}
      <Grid
        item
        xs={12}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          display="inline-flex"
          sx={{ position: 'relative', width: 250, height: 250 }}
        >
          <Skeleton
            variant="circular"
            width={250}
            height={250}
            sx={{ position: 'absolute', zIndex: 1 }}
          />
          <Box
            top={0}
            left={0}
            right={0}
            bottom={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ zIndex: 0 }}
          >
            <Stack direction="column" spacing={0} alignItems="center">
              <Skeleton variant="text" width={60} height={20} />
              <Skeleton variant="text" width={80} height={30} />
            </Stack>
          </Box>
        </Box>
      </Grid>

      {/* Legend Skeleton */}
      <Grid item xs={12}>
        <Stack spacing={3}>
          {[...Array(4)].map((_, index) => (
            <Stack key={index} direction="row" alignItems="center" spacing={2}>
              <Skeleton
                variant="rectangular"
                width={20}
                height={20}
                sx={{ borderRadius: '4px' }}
              />
              <Skeleton variant="text" width="60%" height={20} />
              <Skeleton variant="text" width="20%" height={20} />
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DoughnutChartSkeleton;
