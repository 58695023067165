/* eslint-disable */
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField
} from '@mui/material';
import {
  useCreateObjects,
  useGetObjectsByDataId,
  useStartInference,
  useStartLiveInference,
  useSyncObjectByDataid
} from 'api/sdk';
import { Modal, Typography } from 'components';
import { SelectInput } from 'components/Form';

import { Snack } from 'plugins/snack/Snack';
import { config } from 'process';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CameraSchema,
  CreateObjectSchema,
  InferenceSchema,
  ModelListSchema,
  ObjSchema,
  ObjSchemaInferenceId,
  SyncObjectsRequest
} from 'schemas';
import { RootState } from 'store';
import { v4 } from 'uuid';
import * as Yup from 'yup';

interface ICreateConfigModalProps {
  open: boolean;
  onClose: () => void;
  onSucess: () => void;
  ModelList: any;
  objects: ObjSchema[];

  InferenceId: string | undefined;

  count: number | undefined;
  setSelectedModel: (modalID: string) => void;
  selectedModel: string | undefined;
  InferenceListArray: () => Promise<void>;
  refetchInference: () => void;
  inferenceValue: any;
}

interface IFormValues {
  model_name: string;
  number_of_image: number | null;
  infer_all: boolean;
  specific_number: number | null;
  samplingRate: number | null;
}

export const CreateModalLive: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  onSucess,

  ModelList,
  InferenceId,
  count,
  selectedModel,
  InferenceListArray,
  setSelectedModel,
  objects,

  inferenceValue,
  refetchInference
}) => {
  const [values, setValues] = useState<IFormValues>({
    model_name: '',
    number_of_image: 50,
    infer_all: true,
    specific_number: 50,
    samplingRate: 1
  });
  const [cameraList, setCameraList] = useState<CameraSchema[]>([]);
  const [camera, setCamera] = useState('');

  const [newInferenceID, setNewInferenceID] = useState<string>('');
  const [newObjects, setNewObjects] = useState<ObjSchema[]>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [proceedDisable, setProceedDisable] = useState(true);
  const { mutateAsync, isLoading } = useStartInference();
  const { mutateAsync: startLiveInference } = useStartLiveInference();
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setCameraList([]);
  }, [open]);
  const resetValues = () => {
    setValues({
      model_name: '',
      number_of_image: 50,
      infer_all: true,
      specific_number: 50,
      samplingRate: 1
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Convert input to number or default to 1 if empty
    const numericValue = value === '' ? null : Number(value);

    if (numericValue === null || (numericValue >= 1 && numericValue <= 60)) {
      setValues(prevValues => ({
        ...prevValues,
        samplingRate: numericValue // Ensure this is always a number or null
      }));
    }
  };

  useEffect(() => {
    selectedConfig && handleCameraList();
  }, [open, selectedConfig]);

  useEffect(() => {
    if (camera) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
  }, [camera]);

  const handleTriggerLiveInference = () => {
    startLiveInference({
      data: {
        camera_id: camera?.toString() ?? '',
        // model_id: selectedModel?.toString() ?? '',
        sampling_rate: Number(values?.samplingRate)
      }
    })
      .then(() => {
        refetchInference();
        onSucess();

        onClose();
        Snack({
          message: 'PipeLine Triggered successfully',
          severity: 'success'
        });
      })
      .catch(error => {
        setError(error.response.data.detail);
        setProceedDisable(false);
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      });
  };

  const handleCameraList = async () => {
    const token = localStorage.getItem('token');
    setCameraList([]);
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/configuration/${selectedConfig}/cameras`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      setCameraList(result);
    } catch (error) {}
  };
  // useEffect(() => {
  //   if (open && ModelList.length === 1) {
  //     setSelectedModel(ModelList[0].model_id);
  //   }
  // }, [open, ModelList, setSelectedModel]);

  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  const { data: objectData, refetch: currentObjectsRefetch } =
    useGetObjectsByDataId(selectedConfig || '', newInferenceID || '');

  useEffect(() => {
    if (newInferenceID) {
      currentObjectsRefetch().then(res => {
        setNewObjects(res.data?.data.object_details || []);
      });
    }
  }, [newInferenceID]);
  const {
    data: objectListArr,
    mutateAsync: syncObject,
    isLoading: syncLoading
  } = useSyncObjectByDataid();
  const handleSync = async (
    data: ObjSchema[],
    configID: string,
    inferenceid: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      processed_id: null,
      configuration_id: configID || '',
      groupname: obj.groupname as string,
      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          nas_path: imagePath.nas_path,
          image_path: imagePath.thumb_url,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: 'TRAINING'
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid,
      configId: configID || ''
    });
  };
  useEffect(() => {
    if (newObjects?.length && newObjects.length > 0) {
      const transformedObjects: ObjSchema[] = [];

      newObjects?.forEach(i => {
        let temp2: ObjSchema;
        const currentObj = objects.find(j => j.classname === i.classname);

        temp2 = {
          ...i,
          images: currentObj?.images || []
        };

        transformedObjects.push(temp2);
      });

      handleSync(
        transformedObjects,
        selectedConfig || '',
        transformedObjects[0].data_id || ''
      ).then(res => {
        // handleTriggerLiveInference(newInferenceID);
      });
    }
  }, [newObjects, objects]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetValues();
      }}
      title="Select Camera and Model to Start Inference"
      size="md"
    >
      {cameraList && cameraList?.length === 0 ? (
        <>
          <div className="loader"> Loading Please Wait...</div>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectInput
                name="Camera"
                label="Select Camera"
                options={
                  (cameraList &&
                    cameraList
                      ?.filter(camera => camera?.status === 'ACTIVE')
                      ?.map((camera: any) => ({
                        label: camera?.name,
                        value: camera?.id
                      }))) ||
                  []
                }
                placeholder={'Select Camera'}
                value={camera}
                variant="outlined"
                onChange={value => {
                  setCamera(value);
                  setErrorMessage(null);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                InputLabelProps={{
                  style: {
                    fontSize: 14
                  }
                }}
                fullWidth
                size="small"
                label="Enter Sampling Rate"
                type="number"
                name="samplingRate"
                onChange={handleChange} // Bind change handler
                value={values.samplingRate} // Bind value
                InputProps={{ inputProps: { min: 1, max: 60 } }}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography variant="caption" sx={{ color: 'red' }}>
              {' '}
              {error}{' '}
            </Typography>
          )}
          <Grid item xs={12} mt={2} container justifyContent="center">
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                setError(undefined);
                resetValues();
                setCamera('');
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={proceedDisable}
              style={{ marginLeft: '8px' }}
              onClick={() => {
                setProceedDisable(true);
                handleTriggerLiveInference();
                setCamera('');
              }}
            >
              Proceed
            </Button>
          </Grid>
        </>
      )}
    </Modal>
  );
};
