import React, { useState } from 'react';
import RawData from './rawData';
import PreprocessedData from './preProcessedData';
import { Button } from 'components/Button/Button';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface mainProps {}

const Index: React.FC<mainProps> = ({}) => {
  const [rawDataSelected, setRawDataSelected] = useState<boolean>(true);

  const selectedConfigurationID = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );

  const [selectedConfig, setSelectedConfig] = useState<string | undefined>(
    selectedConfigurationID
  );

  const configs = useSelector((state: RootState) => state.config.allConfigs);
  return (
    <div style={{ height: '100%' }}>
      <Stack
        sx={{ height: '5%' }}
        direction={'row'}
        spacing={1}
        bgcolor={'#fff'}
        borderBottom={'1px solid #c2c2c2'}
      >
        <Button
          version="transparent"
          style={{ borderBottom: rawDataSelected ? '1px solid #000' : 'none' }}
          onClick={() => {
            setRawDataSelected(true);
          }}
        >
          Raw Data
        </Button>
        <Button
          version="transparent"
          style={{ borderBottom: rawDataSelected ? 'none' : '1px solid #000' }}
          onClick={() => {
            setRawDataSelected(false);
          }}
        >
          {' '}
          Preprocessed Data
        </Button>
      </Stack>
      <Box style={{ height: '95%' }}>
        {rawDataSelected && <RawData />}
        {!rawDataSelected && (
          <PreprocessedData
            selectedConfig={selectedConfig || 'ALL'}
            setSelectedConfig={setSelectedConfig}
            configs={configs || []}
            type="BOTH"
          />
        )}
      </Box>
    </div>
  );
};

export default Index;
