/* eslint-disable */

import { Stack } from '@mui/material';
import { useCreateObjects, useGetObjectsByDataId } from 'api/sdk';
import { Button, Modal, Typography } from 'components';
import React, { useEffect, useState } from 'react';
import { CreateObjectSchema, ObjSchema } from 'schemas';

interface DropClassProps {
  open: boolean;
  onClose: () => void;
  modelsClass: string[];
  selectedObjects: ObjSchema[];
  selectedConfig: string;
  handleSync: (
    data: ObjSchema[],
    configID: string,
    inferenceid: string
  ) => Promise<void>;
  setCurrentInferenceID: (data: string) => void;
  setTrainingModal: (data: boolean) => void;
}

const DropClassIncr: React.FC<DropClassProps> = ({
  open,
  onClose,
  modelsClass,
  selectedObjects,
  selectedConfig,
  handleSync,
  setTrainingModal,
  setCurrentInferenceID
}) => {
  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  const [newObjects, setNewObjects] = useState<ObjSchema[]>();

  const [newInferenceID, setNewInferenceID] = useState<string>('');

  const {
    data: objectData,
    refetch: currentObjectsRefetch,
    isLoading: currentObjectsLoading
  } = useGetObjectsByDataId(selectedConfig, newInferenceID || '');

  useEffect(() => {
    if (newInferenceID) {
      currentObjectsRefetch().then(res => {
        setNewObjects(res.data?.data.object_details || []);
      });
    }
  }, [newInferenceID]);

  useEffect(() => {
    if (newObjects?.length && newObjects.length > 0) {
      const transformedObjects: ObjSchema[] = [];

      newObjects?.forEach(i => {
        let temp2: ObjSchema;
        const currentObj = selectedObjects.find(
          j => j.classname === i.classname
        );

        temp2 = {
          ...i,
          images: currentObj?.images || [],
          groupname: currentObj?.groupname || ''
        };

        transformedObjects.push(temp2);
      });

      handleSync(
        transformedObjects,
        selectedConfig,
        transformedObjects[0].data_id || ''
      ).then(res => {
        setCurrentInferenceID(transformedObjects[0].data_id as string);
        setTrainingModal(true);
        onClose();
      });
    }
  }, [newObjects]);

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        {selectedObjects
          .filter(i => !modelsClass.includes(i.classname))
          .map(i => i.classname)
          .join(', ')}
      </div>

      <Typography>
        {' '}
        new Classes{' '}
        {selectedObjects
          .filter(i => !modelsClass.includes(i.classname))
          .map(i => i.classname)
          .join(', ')}{' '}
        are found, do you want to Drop them ?{' '}
      </Typography>
      <Stack direction={'row'} spacing={2} justifyContent={'center'}>
        <Button
          version="light"
          onClick={() => {
            setTrainingModal(true);
            onClose();
          }}
        >
          {' '}
          continue without Dropping
        </Button>
        <Button
          loading={createObjectLoading || currentObjectsLoading}
          onClick={() => {
            const classesArray = selectedObjects
              .filter(i => modelsClass.includes(i.classname))
              .map(i => i.classname);

            const data: CreateObjectSchema[] = [];
            classesArray.forEach(i => {
              data.push({
                classname: i,
                configuration_id: selectedConfig || '',
                mode: 'INFERENCE',
                groupname: null
              });
            });
            CreateObjects({
              configId: selectedConfig || '',
              data: data
            }).then(res => {
              setNewInferenceID(res.data[0].data_id as string);
            });
          }}
        >
          drop and proceed
        </Button>
      </Stack>
    </Modal>
  );
};

export default DropClassIncr;
