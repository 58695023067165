import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import CustomIconButton from 'components/Button/IconButton';
import React, {
  LegacyRef,
  RefObject,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdOutlineEdit
} from 'react-icons/md';
import Actions from './actionsPreProcessed';
import { IoArrowForward, IoArrowBack } from 'react-icons/io5';
import { useGetAllObjectsProcessedData, useGetObjectsByDataId } from 'api/sdk';

import { SelectInput } from 'components/Form';
import styled from 'styled-components';
import Folder from '../../assets/images/Folder.png';
import Thumbnail from 'components/Thumbnail';
import { Button } from 'components/Button/Button';
import { ConfigSchema, GroupnameSchema, ObjSchema } from 'schemas';
import Datalist from './Datalist';
import ImageModal from './imageViewer';

interface PreprocessedDataProps {
  onSelect?: (data: string, objects?: ObjSchema[]) => void;
  configs: ConfigSchema[];
  selectedConfig: string;
  setSelectedConfig?: (data: string) => void;
  onCancel?: () => void;
  type: string;
  isIncremental?: boolean;
}

const PreprocessedData: React.FC<PreprocessedDataProps> = ({
  onSelect,
  onCancel,
  selectedConfig,
  setSelectedConfig,
  configs,
  type
}) => {
  const [selectedDataset, setSelectedDataset] = useState<string>('');
  const [selectedInferenceID, setSelectedInferenceID] = useState<string>('');
  const [selectedClass, setSelectedClass] = useState<string>('');
  const [groups, setGroups] = useState<GroupnameSchema[]>([]);
  const [imageModal, setImageModal] = useState<boolean>(false);

  useEffect(() => {
    if (!onSelect) {
      setSelectedConfig && setSelectedConfig('ALL');
    }
    return () => {
      if (!onSelect) {
        setSelectedConfig && setSelectedConfig('');
      }
    };
  }, []);

  const [step, setStep] = useState<number>(1);

  const {
    data,
    refetch,
    isLoading: GroupsLoading,
    isFetching: GroupsFetching
  } = useGetAllObjectsProcessedData(
    selectedConfig !== 'ALL' ? { config_id: selectedConfig } : undefined
  );

  const isGrouploading = useMemo(() => {
    return GroupsLoading || GroupsFetching;
  }, [GroupsLoading, GroupsFetching]);

  const {
    data: objectData,
    refetch: currentObjectsRefetch,
    isLoading: objectsLoading,
    isFetching: objectsFetching
  } = useGetObjectsByDataId(selectedConfig, selectedDataset);

  const isObjectloading = useMemo(() => {
    return objectsLoading || objectsFetching;
  }, [objectsLoading, objectsFetching]);

  const StyledDiv = styled('div')<{ selected: boolean }>(({ selected }) => ({
    width: '130px',
    padding: '10px',
    position: 'relative',
    backgroundColor: selected ? '#ededed' : '#fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    float: 'left'
  }));

  const images = useMemo(() => {
    const image = objectData?.data.object_details?.find(
      i => i.classname === selectedClass
    )?.images;
    return image;
  }, [data, selectedClass, objectData]);

  useEffect(() => {
    if (type == 'BOTH' || type == 'INFERENCE') {
      setGroups(data?.data?.object_details || []);
    }

    if (type == 'TRAINING') {
      const filteredData = data?.data?.object_details?.filter(
        i => i.mode == 'TRAINING' || i.mode == 'BOTH'
      );
      setGroups(filteredData || []);
    }
  }, [data, selectedClass, objectData]);

  const toalImages = useMemo(() => {
    return objectData?.data.object_details?.reduce(
      (acc, curr) => acc + (curr.images?.length || 0),
      0
    );
  }, [objectData]);
  const [imgSrc, setImgsrc] = useState<string>('');

  const [nextDataset, setNextDataset] = useState<string | null>(null);
  const [nextClass, setNextClass] = useState<string | null>(null);

  const handleBack = () => {
    if (step === 1) return;

    setStep(prev => {
      if (prev === 1) return prev;
      if (prev === 2) {
        setNextDataset(selectedDataset);

        setSelectedDataset('');
        return prev - 1;
      }
      if (prev === 3) {
        setSelectedClass('');
        console.log(selectedDataset, 'classes');
        setNextClass(selectedClass);
        return prev - 1;
      }
      return prev;
    });
  };

  const handleForward = () => {
    if (step === 3) return;

    setStep(prev => {
      if (prev === 2 && nextClass != null) {
        setSelectedClass(nextClass);

        return prev + 1;
      }
      if (prev === 1 && nextDataset != null) {
        setSelectedDataset(nextDataset);

        return prev + 1;
      }
      return prev;
    });
  };

  return (
    <Box bgcolor={'#fff'} sx={{ height: '100%' }}>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <Tooltip title="Previous" arrow>
            <CustomIconButton
              icon={IoArrowBack}
              disabled={step == 1}
              onClick={handleBack}
            />
          </Tooltip>{' '}
          <Tooltip title="Forward" arrow>
            <CustomIconButton
              icon={IoArrowForward}
              disabled={step == 3}
              onClick={handleForward}
            />
          </Tooltip>
          <>
            <Breadcrumbs
              separator={<MdNavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                underline="none"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setStep(1);
                  setSelectedDataset('');
                  setSelectedClass('');
                  setNextClass(null);
                  setNextDataset(null);
                }}
              >
                {' '}
                Pre-processed Data
              </Link>

              {selectedDataset && (
                <Link
                  color="inherit"
                  underline="none"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setStep(2);
                    setSelectedDataset(selectedDataset);
                    setSelectedClass('');
                    setNextClass(null);
                    setNextDataset(null);
                  }}
                >
                  {groups.find(i => i.data_id == selectedDataset)?.groupname ||
                    ''}
                </Link>
              )}

              {selectedClass && (
                <Link
                  color="inherit"
                  sx={{ cursor: 'pointer' }}
                  underline="none"
                >
                  {selectedClass}
                </Link>
              )}
            </Breadcrumbs>
          </>
        </Stack>
        {!onSelect && (
          <SelectInput
            variant="outlined"
            width={'20vw'}
            disabled={step != 1}
            name="configuration"
            value={selectedConfig}
            options={[
              { value: 'ALL', label: 'ALL' },
              ...(configs?.map(i => {
                return { value: i.id, label: i.name };
              }) || [])
            ]}
            onChange={e => {
              setSelectedConfig?.(e);
            }}
          />
        )}
      </Stack>
      <Box
        onContextMenu={e => {
          e.preventDefault();
        }}
        style={{
          height: onSelect ? '86%' : step == 3 ? '70vh' : '82%',
          padding: '16px'
        }}
      >
        {step == 1 && (
          <Grid container sx={{ height: '100%' }}>
            <Grid
              item
              xs={onSelect ? 9 : 12}
              sx={{
                borderRight: onSelect ? '1px solid #ccc' : '',
                height: '100%',
                overflowY: 'scroll'
              }}
            >
              {!isGrouploading &&
                groups.map(i => {
                  return (
                    <StyledDiv
                      selected={i.data_id == selectedDataset}
                      onDoubleClick={
                        !onSelect
                          ? () => {
                              setSelectedInferenceID(i.data_id || '');
                              setStep(2);
                              setSelectedConfig &&
                                setSelectedConfig(i.config_id || '');
                            }
                          : undefined
                      }
                      onClick={() => {
                        setSelectedDataset(i.data_id || '');
                        if (onSelect) {
                          setSelectedInferenceID(i.data_id || '');
                        }
                      }}
                    >
                      <img
                        loading="lazy"
                        src={Folder}
                        alt=""
                        style={{
                          width: '120px'
                        }}
                      />
                      <Box textAlign={'center'}>
                        <Tooltip
                          title={
                            i.groupname && i.groupname.length > 15
                              ? i?.groupname
                              : ''
                          }
                        >
                          <Typography variant="caption">
                            {' '}
                            {i?.groupname && i.groupname.length > 15
                              ? i?.groupname.substring(0, 15) + '...'
                              : i.groupname}{' '}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </StyledDiv>
                  );
                })}
              {isGrouploading && (
                <Box
                  sx={{
                    display: 'flex',
                    height: '50vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <Typography>
                      please wait till the data is loading
                    </Typography>
                  </Box>
                </Box>
              )}
              {!isGrouploading && groups.length == 0 && (
                <Box sx={{ textAlign: 'center', paddingTop: '200px' }}>
                  there are no {type == 'TRAINING' ? 'training' : ''} Dataset
                  created for this configuration
                </Box>
              )}
            </Grid>
            {onSelect && (
              <Grid
                item
                xs={3}
                p={1}
                style={{ height: '100%', overflowY: 'scroll' }}
              >
                {isObjectloading && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      flexDirection: 'column'
                    }}
                  >
                    <CircularProgress />
                    loading...
                  </div>
                )}
                {!isObjectloading && objectData?.data.object_details && (
                  <>
                    <Typography
                      sx={{ fontWeight: 'bold', textAlign: 'center' }}
                    >
                      Selected Folders Details
                    </Typography>

                    <Typography> Total images : {toalImages} </Typography>
                    <Typography>
                      {' '}
                      Total classes :{' '}
                      {objectData?.data.object_details.length || 0}{' '}
                    </Typography>
                    <div
                      style={{
                        height: '62vh',
                        overflowY: 'scroll'
                      }}
                    >
                      {objectData?.data.object_details?.map((i, index) => {
                        return (
                          <Datalist
                            key={i.classname}
                            classname={i.classname}
                            noOfImages={i?.images?.length || 0}
                            index={index}
                            editable={false}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
                {!isObjectloading && !objectData?.data.object_details && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      flexDirection: 'column'
                    }}
                  >
                    please select a Dataset to view the details
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        )}

        {step == 2 && (
          <Box
            sx={{
              height: '100%'
            }}
          >
            {isObjectloading && (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
                <Typography>please wait till the data is loading</Typography>
              </Box>
            )}

            {!isObjectloading && (
              <Grid
                sx={{
                  borderRight: onSelect ? '1px solid #ccc' : '',
                  height: '100%',
                  overflowY: 'scroll'
                }}
              >
                {objectData?.data.object_details.map(i => {
                  return (
                    <StyledDiv
                      selected={false}
                      onDoubleClick={() => {
                        setSelectedClass(i.classname);
                        setStep(3);
                      }}
                    >
                      <img
                        loading="lazy"
                        src={Folder}
                        alt=""
                        style={{
                          width: '120px'
                        }}
                      />
                      <Box textAlign={'center'}>
                        <Tooltip
                          title={
                            i.classname && i.classname.length > 15
                              ? i?.classname
                              : ''
                          }
                        >
                          <Typography variant="caption">
                            {/* {i.classname}{' '} */}{' '}
                            {i?.classname && i.classname.length > 15
                              ? i?.classname.substring(0, 15) + '...'
                              : i.classname}{' '}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </StyledDiv>
                  );
                })}
              </Grid>
            )}
          </Box>
        )}

        {step == 3 && (
          <Box
            sx={{
              height: '100%',
              overflow: 'scroll'
            }}
          >
            {images?.map((item, index) => {
              return (
                <Thumbnail
                  // onClick={() => {
                  //   setImageModal(true);
                  //   setImgsrc(item?.thumb_url as string);
                  // }}
                  handelChangeImageIndex={e => {
                    setImageModal(true);
                    setImgsrc(item?.thumb_url as string);
                  }}
                  id={item.id}
                  title=""
                  showImageGallery={true}
                  key={index}
                  thumbnailUrl={item.thumb_url || ''}
                  index={index}
                  checkable={false}
                />
              );
            })}
          </Box>
        )}

        <ImageModal
          imageModal={imageModal}
          setImageModal={setImageModal}
          imageSrc={imgSrc}
          setimgSrc={setImgsrc}
        />
      </Box>

      {!onSelect && (
        <>
          {step == 3 && (
            <Stack
              direction={'row'}
              padding={2}
              sx={{ borderTop: '1px solid #ccc' }}
            >
              {images?.length} Items
            </Stack>
          )}
          {step == 2 && !isObjectloading && (
            <Stack
              direction={'row'}
              padding={2}
              sx={{ borderTop: '1px solid #ccc' }}
            >
              {objectData?.data.object_details.length || 0} Items
            </Stack>
          )}
          {step == 1 && !isGrouploading && (
            <Stack
              direction={'row'}
              padding={2}
              sx={{ borderTop: '1px solid #ccc' }}
            >
              {groups.length} Items
            </Stack>
          )}
        </>
      )}

      {onSelect && (
        <Stack
          direction={'row'}
          justifyContent={'center'}
          spacing={2}
          borderTop={'1px solid #EDEDED'}
          p={1.5}
          bgcolor={'#fff'}
        >
          {' '}
          {onCancel && (
            <Button
              version="light"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={selectedDataset == '' || isObjectloading}
            onClick={() => {
              onSelect(selectedDataset, objectData?.data.object_details || []);
            }}
          >
            Import
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default PreprocessedData;
