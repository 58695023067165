import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Box } from '@mui/material';
import { ObjSchema } from 'schemas';
import { Modal } from 'components';

// Register chart components and plugins
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

type Props = {
  data: ObjSchema[];
  open: boolean;
  onClose: () => void;
};

const DataDistribution: React.FC<Props> = ({ data, open, onClose }) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    if (data) {
      const classNameCounts: Record<string, number> = {};

      data.forEach(obj => {
        if (obj.images && Array.isArray(obj.images)) {
          obj.images.forEach(() => {
            classNameCounts[obj.classname] =
              (classNameCounts[obj.classname] || 0) + 1;
          });
        }
      });

      const trimLabel = (label: string) =>
        label.length > 12 ? `${label.slice(0, 12)}...` : label;

      const chartLabels = Object.keys(classNameCounts).map(trimLabel);
      const chartValues = Object.values(classNameCounts);

      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: 'No of Images',
            data: chartValues,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)'
          }
        ]
      });
    }
  }, [data]);

  return (
    <Modal open={open} onClose={onClose}>
      <h2>Data Distribution</h2>
      <Box sx={{ height: '50vh' }}>
        {chartData ? (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Class Types',
                    color: 'black'
                  },
                  stacked: false,
                  grid: {
                    display: false
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: 'Number of Images',
                    color: 'black'
                  },
                  beginAtZero: true,
                  grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                  }
                }
              },
              plugins: {
                legend: {
                  display: false,
                  position: 'right' as const,
                  labels: {
                    color: 'rgb(0, 0, 0)'
                  }
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'x',
                    threshold: 10
                  },
                  zoom: {
                    wheel: {
                      enabled: true
                    },
                    pinch: {
                      enabled: true
                    },
                    mode: 'x'
                  }
                }
              }
            }}
          />
        ) : (
          <p>Loading data...</p>
        )}
      </Box>
    </Modal>
  );
};

export default DataDistribution;
