import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '../Typography/Typography';
import Card from '../Card';
import Actions from './Actions';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Popover,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import FolderType from './FolderType';
import {
  useGetDirectoryContentsFileManagerDirectoryContentsGet,
  useCreateDirectoryFileManagerDirectoryPost,
  useSearchDirectoryFileManagerSearchDirectoryGet,
  useDeleteDirectoryFileManagerDirectoriesDelete,
  useProcessFileTransferFileManagerFilesTypePatch
} from '../../api/media';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { Contents, DirectoriesContents, FileType } from '../../schemasMedia';
import { MdOutlineEdit } from 'react-icons/md';

import { FiFolderPlus } from 'react-icons/fi';
import { FaRegCopy, FaRegPaste } from 'react-icons/fa6';
import { TbCut } from 'react-icons/tb';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface contextMenuProps {
  files: Contents[];
  currentDirectory: string;
  setFiles: (Data: Contents[]) => void;
  setSelectedFiles: (file: Contents[]) => void;
  setEditName: (data: boolean) => void;
  onClose: () => void;
  selectedFiles: Contents[];
  fromDirectory: string;
  setFromDirectory: (data: string) => void;
  copyFiles: Contents[];
  setCopyFiles: (data: Contents[]) => void;
  setType: (data: FileType) => void;
  type: FileType;
  inputRef: React.RefObject<HTMLInputElement>;
  setDeleteModalOpen: (data: boolean) => void;
}

const ContextMenu: React.FC<contextMenuProps> = ({
  files,
  currentDirectory,
  setFiles,
  setSelectedFiles,
  setEditName,
  onClose,
  selectedFiles,
  fromDirectory,
  setFromDirectory,
  copyFiles,
  setCopyFiles,
  setType,
  type,
  inputRef,
  setDeleteModalOpen
}) => {
  const { mutateAsync: createFolder } =
    useCreateDirectoryFileManagerDirectoryPost();
  const user_id = useSelector((state: RootState) => state.auth.profile?.id);
  const group_id = useSelector(
    (state: RootState) => state.auth.profile?.group_id || '/'
  );

  const getNewFolderName = (): string => {
    const folderPattern = /^New folder(\d+)?$/;
    const folderNumbers = files
      .filter(
        file =>
          file.type === 'directory' && folderPattern.test(file.name as string)
      )
      .map(file => {
        const match = file?.name?.match(folderPattern);
        return match && match[1] ? parseInt(match[1]) : 1;
      });

    const maxNumber = folderNumbers.length > 0 ? Math.max(...folderNumbers) : 0;
    const newFolderNumber = maxNumber + 1;

    return newFolderNumber === 1
      ? 'New folder'
      : `New folder${newFolderNumber}`;
  };

  const { mutateAsync: deleteFiles } =
    useDeleteDirectoryFileManagerDirectoriesDelete();

  const { mutateAsync: moveFiles } =
    useProcessFileTransferFileManagerFilesTypePatch();

  return (
    <List sx={{ padding: 1 }}>
      <ListItem disablePadding sx={{ marginBottom: '4px' }}>
        <ListItemButton
          sx={{ padding: '2px 4px' }}
          onClick={() =>
            createFolder({
              params: {
                directory_name: getNewFolderName(),
                directory_path: `${group_id}/${user_id}/${currentDirectory}`
              }
            }).then(res => {
              setFiles(res.data.contents);
              const selectedFile = res.data.contents.filter(
                file => file.name == getNewFolderName()
              );
              setSelectedFiles(selectedFile);
              setEditName(true);
              onClose();
            })
          }
        >
          <ListItemIcon>
            <FiFolderPlus />
          </ListItemIcon>
          <ListItemText primary="New Folder" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ marginBottom: '4px' }}>
        <ListItemButton
          disabled={selectedFiles.length == 0}
          sx={{ padding: '4px 8px' }}
          onClick={() => {
            const files = selectedFiles.filter(
              file => file.type != 'Directory'
            );
            setCopyFiles(files);
            setFromDirectory(currentDirectory);
            setType('copy');
            onClose();
          }}
        >
          <ListItemIcon>
            <FaRegCopy />
          </ListItemIcon>
          <ListItemText primary="Copy" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ marginBottom: '4px' }}>
        <ListItemButton
          disabled={selectedFiles.length == 0}
          sx={{ padding: '4px 8px' }}
          onClick={() => {
            const files = selectedFiles.filter(
              file => file.type != 'Directory'
            );
            setCopyFiles(files);
            setFromDirectory(currentDirectory);
            setType('move');
            onClose();
          }}
        >
          <ListItemIcon>
            <TbCut />
          </ListItemIcon>
          <ListItemText primary="Cut" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ marginBottom: '4px' }}>
        <ListItemButton
          disabled={copyFiles.length == 0}
          sx={{ padding: '4px 8px' }}
          onClick={() => {
            moveFiles({
              data: {
                filenames: copyFiles.map(file => file.name as string),
                from_directory: fromDirectory,
                to_directory: `${group_id}/${user_id}/${currentDirectory}`
              },
              type: type || 'copy'
            }).then(res => {
              res.data && setFiles(res.data.contents);
              setCopyFiles([]);
              onClose();
            });
          }}
        >
          <ListItemIcon>
            <FaRegPaste />
          </ListItemIcon>
          <ListItemText primary="Paste" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ marginBottom: '4px' }}>
        <ListItemButton
          disabled={selectedFiles.length != 1}
          sx={{ padding: '4px 8px' }}
          onClick={() => {
            onClose();
            setEditName(true);
            inputRef.current?.focus();
            inputRef.current?.select();
          }}
        >
          <ListItemIcon>
            <MdOutlineEdit />
          </ListItemIcon>
          <ListItemText primary="Rename" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ marginBottom: '4px' }}>
        <ListItemButton
          disabled={selectedFiles.length == 0}
          sx={{ padding: '4px 8px' }}
          onClick={e => {
            selectedFiles != undefined &&
              selectedFiles.length > 0 &&
              setDeleteModalOpen(true);
            onClose();
          }}
        >
          <ListItemIcon>
            <RiDeleteBin6Line />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default ContextMenu;
