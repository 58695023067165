import React, { useState, useEffect, useRef } from 'react';
import { Box, Checkbox, TableCell, TableRow, Tooltip } from '@mui/material';
import Folder from '../../assets/images/Folder.png';
import styled from '@emotion/styled';
import { Typography } from '../Typography/Typography';

import { Contents, DirectoriesContents } from '../../schemasMedia';
import { useRenameDirectoryFileManagerRenameDirectoryPatch } from '../../api/media';

import ImageModal from './imageViewer';
import LaodingIMage from '../../assets/images/loadingImage.jpg';
import { useSnack } from 'plugins/snack';
import { useSelector } from 'react-redux';
import { Root } from 'react-dom/client';
import { RootState } from 'store';
import DraggableItem from './Draggable';

interface ListViewRowProps {
  selected: boolean;
  fileType: string;
  name: string;
  selectCurrentDirectory: (path: string) => void;
  currentDirectory: string;
  handleSelectFile: (file: Contents) => void;
  file: Contents;
  setSelectedFiles: (file: Contents[]) => void;
  selectedFiles: Contents[];
  editName: boolean;
  handleRefetchDirectory: (select?: Contents[]) => void;
  setEditName: (data: boolean) => void;
  disabled: boolean;
  setSearchMode: (data: boolean) => void;
  handleContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  setFiles: (data: Contents[]) => void;
  setSearchQueryText: (data: string) => void;
  setSearchQuery: (data: string) => void;
  imageModal: boolean;
  setImageModal: (data: boolean) => void;
  setImgsrc: (data: string) => void;
  imageSrc: string;
  index: number;
  searchMode: boolean;
}

const getBG = (selected: boolean, hovered: boolean, disabled: boolean) => {
  if (disabled) return '#ededed';
  if (selected) return '#CFE2ED';
  if (hovered) return '#EBF3F8';
  return 'fff';
};

const ListViewRow: React.FC<ListViewRowProps> = ({
  selected,
  fileType,
  name,
  selectCurrentDirectory,
  currentDirectory,
  handleSelectFile,
  file,
  setSelectedFiles,
  editName,
  handleRefetchDirectory,
  setEditName,
  disabled,
  setSearchMode,
  handleContextMenu,
  selectedFiles,
  index,
  searchMode,
  setSearchQueryText,
  setSearchQuery,
  setImageModal,
  setImgsrc
}) => {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [newName, setNewName] = useState<string>(name);
  const [playModal, setPlayModal] = useState<boolean>(false);
  const isAdmin = useSelector((state: RootState) =>
    state.auth.profile?.role == 'organization_admin' ? true : false
  );
  const [videoName, setVideoName] = useState<string>('');
  const snack = useSnack();
  const user_id = useSelector((state: RootState) => state.auth.profile?.id);

  const { mutateAsync: rename } =
    useRenameDirectoryFileManagerRenameDirectoryPatch();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editName && selected && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editName, selected]);

  const getThumbnail = (type: string) => {
    if (type === 'directory') {
      return Folder;
    }
    if (
      type === 'image/png' ||
      type === 'image/jpeg' ||
      type === 'image/jpg' ||
      type === 'image/webp'
    ) {
      return `${process.env.REACT_APP_API_BASE_URL}${file.path}`;
    }

    return Folder;
  };

  const handleRename = () => {
    const len = file?.path?.split('/').length;
    const oldName = len && file?.path?.[len - 1];

    if (!oldName || oldName == newName) {
      setEditName(false);
      return;
    }

    rename({
      params: {
        directory_path: file.path || '',
        new_name: newName,
        current_directory: currentDirectory
      }
    })
      .then(() => {
        handleRefetchDirectory();
        setEditName(false);
        setNewName('');
      })
      .catch(error => {
        snack({
          message: error.response.data.detail,
          severity: 'error'
        });
      });
  };

  const handleRowClick = (item: Contents, event: React.MouseEvent) => {
    // Skip if the click is coming from the input or its container
    if (
      (event.target as HTMLElement).tagName === 'INPUT' ||
      (event.target as HTMLElement).closest('.edit-name-container')
    ) {
      return;
    }

    // Skip if the click is from checkbox container (already handled)
    if (!(event.target as HTMLElement).closest('.checkbox-container')) {
      if (item.type === 'directory') {
        setSearchMode(false);
        const newPath =
          currentDirectory === '/'
            ? item.name
            : `${currentDirectory}/${item.name}`;

        const path = isAdmin
          ? `${
              item.path && item.path.replace(/^\/rawdata\/[0-9a-fA-F-]+\//, '')
            }`
          : item?.path &&
            item?.path.replace(
              /^\/rawdata\/[0-9a-fA-F-]+\/[0-9a-fA-F-]+\/?/,
              ''
            );

        searchMode
          ? selectCurrentDirectory(path || '')
          : selectCurrentDirectory(newPath || '');
        setSearchQueryText('');
        setSearchQuery('');
      }
      if (
        item.type === 'image/png' ||
        item.type === 'image/jpeg' ||
        item.type === 'image/jpg' ||
        item.type === 'image/webp'
      ) {
        setImageModal(true);
        setImgsrc(`${process.env.REACT_APP_API_BASE_URL}${item.path}`);
      }
    }
  };

  const handleCheckboxClick = (event: React.MouseEvent, item: Contents) => {
    event.stopPropagation();
    handleSelectFile(item);
  };

  const handleInputClick = (event: React.MouseEvent) => {
    // Stop event propagation to prevent row click
    event.stopPropagation();
  };

  const StyledTableRow = styled(TableRow)<{
    hovered: boolean;
    selected: boolean;
    disabled: boolean;
  }>(({ hovered, selected, disabled }) => ({
    padding: '10px',
    backgroundColor: getBG(selected, hovered, disabled)
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '10px'
  }));

  return (
    <StyledTableRow
      key={index}
      disabled={false}
      hovered={hoveredRow === index}
      selected={selectedFiles.find(f => f.path === file.path) ? true : false}
      onMouseEnter={() => setHoveredRow(index)}
      onMouseLeave={() => setHoveredRow(null)}
      onClick={e => handleRowClick(file, e)}
    >
      <StyledTableCell align="left" className="checkbox-container">
        <Checkbox
          checked={selectedFiles.includes(file)}
          onClick={e => handleCheckboxClick(e, file)}
          onChange={() => {}}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        {file.type === 'directory' ? (
          <DraggableItem id={file.name || ''} path={file.path || ''}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <img
                loading="lazy"
                src={
                  file.type === 'directory'
                    ? Folder
                    : `${process.env.REACT_APP_API_BASE_URL}${file.path}`
                }
                alt=""
                style={{
                  width: '50px',
                  height: '40px',
                  transition: 'transform 0.3s ease',
                  marginRight: '10px'
                }}
                onMouseEnter={e => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              />

              <Box textAlign={'center'}>
                {editName &&
                selectedFiles.map(i => i.name).includes(file.name) ? (
                  <div
                    className="edit-name-container"
                    onClick={handleInputClick}
                  >
                    <input
                      draggable={false}
                      ref={inputRef}
                      style={{ width: '120px', fontSize: '12px' }}
                      value={newName}
                      onClick={handleInputClick}
                      onBlur={() => {
                        setEditName && setEditName(false);
                        setNewName('');
                        setSelectedFiles([]);
                      }}
                      onChange={e => {
                        setNewName(e.target.value);
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          handleRename();
                        }
                      }}
                    />
                  </div>
                ) : (
                  file?.name && (
                    <Tooltip title={file?.name.length > 15 ? file.name : ''}>
                      <Typography variant="caption">
                        {file?.name?.length > 15
                          ? file?.name.substring(0, 15) + '...'
                          : file.name}
                      </Typography>
                    </Tooltip>
                  )
                )}
              </Box>
            </div>
          </DraggableItem>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <img
              loading="lazy"
              src={
                file.type === 'directory'
                  ? Folder
                  : `${process.env.REACT_APP_API_BASE_URL}${file.path}`
              }
              alt=""
              style={{
                width: '50px',
                height: '40px',
                transition: 'transform 0.3s ease'
              }}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            />
            {file.name}
          </div>
        )}
      </StyledTableCell>
      <StyledTableCell align="left">
        {isAdmin
          ? `${
              file.path && file.path.replace(/^\/rawdata\/[0-9a-fA-F-]+\//, '')
            }`
          : file?.path &&
            file?.path.replace(
              /^\/rawdata\/[0-9a-fA-F-]+\/[0-9a-fA-F-]+\/?/,
              ''
            )}
      </StyledTableCell>
      <StyledTableCell align="left">
        {file.created_time &&
          new Date(file.created_time).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ListViewRow;
