import { Grid, Stack } from '@mui/material';
import { Card, Typography } from 'components';
import React from 'react';
import True from '../../assets/images/possitiveIcon.svg';
import False from '../../assets/images/negativeIcon.svg';
import { GroupAnalyticsResponseSchema } from 'schemas';

interface IAccessDetailsProps {
  groupLevelDetails?: GroupAnalyticsResponseSchema;
}

const AccessDetails: React.FC<IAccessDetailsProps> = ({
  groupLevelDetails
}) => {
  return (
    <>
      <Card sx={{ p: 3 }} style={{ height: '100%' }}>
        <Typography
          variant="subtitle1"
          color="primary"
          fontWeight="bold"
          gutterBottom
        >
          Group Level Access Details
        </Typography>
        <Grid container spacing={4} alignItems="center" mt={2}>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Group Share Feature
              </Typography>
              <Typography variant="body1" color="text.primary">
                <img
                  src={
                    groupLevelDetails &&
                    groupLevelDetails?.group_level_access_details
                      ?.group_share === true
                      ? True
                      : False
                  }
                  alt={'Positive'}
                  style={{ width: 30, height: 30, marginLeft: '25px' }}
                />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Users with Advanced Settings access
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {(groupLevelDetails &&
                  groupLevelDetails?.group_level_access_details
                    ?.advance_settings_access_count) ??
                  0}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Users with Data Manager access
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {(groupLevelDetails &&
                  groupLevelDetails?.group_level_access_details
                    ?.data_manager_access_count) ??
                  0}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Users with Recorded Inference access
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {(groupLevelDetails &&
                  groupLevelDetails?.group_level_access_details
                    ?.record_inference_access_count) ??
                  0}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Users with Training access
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {(groupLevelDetails &&
                  groupLevelDetails?.group_level_access_details
                    ?.training_access_count) ??
                  0}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Users with Live Inference access
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {(groupLevelDetails &&
                  groupLevelDetails?.group_level_access_details
                    ?.live_inference_access_count) ??
                  0}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Users with Incremental Training access
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {(groupLevelDetails &&
                  groupLevelDetails?.group_level_access_details
                    ?.inc_training_access_count) ??
                  0}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2
                }}
              >
                Users with Novelty Detection access
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {(groupLevelDetails &&
                  groupLevelDetails?.group_level_access_details
                    ?.novelty_detection_access_count) ??
                  0}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AccessDetails;
