import { Grid, Stack, Tooltip } from '@mui/material';
import { useGroupsAnalytics } from 'api/sdk';
import { Card, Typography } from 'components';
import React, { useEffect, useState } from 'react';

const GroupStatistics = () => {
  //get analytics data
  const { isLoading, isError, refetch } = useGroupsAnalytics();
  const [analytics, SetAnalytics] = useState<any>([]);
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && SetAnalytics(res.data?.data);
    });
  }, [analytics]);

  return (
    <>
      <Card style={{ backgroundColor: '#1E4E6A' }}>
        <Stack spacing={1}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600 }}
            color={'#FFFFFF'}
          >
            Statistics Across Global ( Group and Self )
          </Typography>
          <Grid container spacing={1} justifyContent={'space-between'}>
            {[
              {
                label: 'Total no. of Groups',
                value: (analytics && analytics?.number_of_groups) || 0
              },
              {
                label: 'Total no. of Users',
                value: (analytics && analytics?.number_of_viewers) || 0
              },
              {
                label: 'Total no. of Configurations',
                value: (analytics && analytics?.number_of_configurations) || 0
              },
              {
                label: 'Total no. of Models Trained',
                value: (analytics && analytics?.number_of_models) || 0
              },
              {
                label: 'Total no. of Models Trained ( Incrementally )',
                value: (analytics && analytics?.number_of_inc_models) || 0
              },
              {
                label: 'Total no. of Live Inference',
                value: (analytics && analytics?.number_of_live_inference) || 0
              },
              {
                label: 'Total no. of Recorded Inference',
                value: (analytics && analytics?.number_of_batch_inference) || 0
              }
            ].map(({ label, value }, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                lg={1.5}
                key={index}
                color={'#FFFFFF'}
                textAlign={'center'}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 'normal',
                    lineHeight: '1.5em',
                    minHeight: '4.5em',
                    display: '-webkit-box',
                    WebkitLineClamp: 2 // Limit to 2 lines
                  }}
                >
                  {label}
                </Typography>

                <Typography variant="h6" fontWeight="bold">
                  {value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Card>
    </>
  );
};

export default GroupStatistics;
