import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  Typography
} from 'components';
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  CardActionArea,
  IconButton,
  LinearProgress,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useListConfigurations } from 'api/sdk';
import { useWebSocket } from 'hooks';
import { Formik, Form } from 'formik';

import { ObjectCategoryCard } from 'components';
import Inference from '../../assets/images/trainingImage.svg';
import { CreateModal } from './createModal';
import { useNavigate } from 'react-router-dom';
import Reports from '../../assets/images/reportsQuality.svg';
import ViewNovelty from '../../assets/images/ViewNovelty.svg';
import ViewNoveltyDisabled from '../../assets/images/ViewNoveltyDisable.svg';

import reportsNotFound from '../../assets/images/reportsNotFound.svg';
import styled, { keyframes } from 'styled-components';

import ReportIcon from '../../assets/images/inferenceProgress.svg';
import {
  ConfigSchema,
  CreateObjectSchema,
  IchorInferenceSchema,
  InferenceSchema,
  ModelListSchema,
  ObjSchema,
  TrainingResultSchema
} from 'schemas';

import { Recommend } from '@mui/icons-material';
import Env from 'config/env';
import { setSelectedConfiguration } from 'store/configSlice';
import { useDispatch } from 'react-redux';

import { ListTable } from 'components/ListTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import NoDataComponent from 'components/NoDataComponent';
import { set } from 'date-fns';
interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  objects: ObjSchema[] | undefined;

  handelRefetchObjects: () => Promise<any>;
  setCurrentInferenceID: (inferenceID: string | undefined) => void;
  setSelectedModel: (data: string) => void;
  selectedModel: string | undefined;
  setStatusValue: (data: string) => void;
  InferenceListArray: () => Promise<any>;
  setInferenceList: (data: InferenceSchema[]) => void;
  handleModelList: () => void;
  modelList: TrainingResultSchema[];
  InferenceList: InferenceSchema[];
  currentInferenceID: string | undefined;
  setStep: (data: number) => void;
  startInference: boolean;
  setStartInference: Dispatch<SetStateAction<boolean>>;
  selectedObjects: ObjSchema[];
  setSelectedObjects: Dispatch<SetStateAction<ObjSchema[]>>;
  refetchInference: () => void;
  createModalOpen: boolean;
  setCreateModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const Main: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  objects,
  handelRefetchObjects,
  modelList,
  InferenceList,
  handleModelList,
  setCurrentInferenceID,
  currentInferenceID,
  selectedModel,
  setSelectedModel,
  InferenceListArray,
  setStatusValue,
  setInferenceList,
  setStep,
  startInference,
  setStartInference,
  selectedObjects,
  setSelectedObjects,
  createModalOpen,
  setCreateModalOpen,
  refetchInference
}) => {
  const profile = useSelector((state: RootState) => state.auth.profile);
  const userFeatures = profile?.features_list || [];

  const { data, isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });

  const [value, setValue] = useState<string | undefined>(undefined);

  const [fromWebsocket, setFromWebsocket] = useState<any>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [status, setStatus] = useState('QUEUED');
  const [noveltystatus, setNoveltyStatus] = useState('');

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [advancedDetails, setAdvancedDetails] = useState(false);

  function formatDateTime(dateTimeString: string) {
    const dateObj = new Date(dateTimeString);

    // Extract components
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');

    // Format as mm-dd-yyyy hh:mm
    return `${month}-${day}-${year} ${hours}:${minutes}`;
  }
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvancedDetails(event.target.checked);
    // Dispatch any action or handle toggle state change if necessary
  };

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);
  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    if (message?.pipeline_type === 'quality_control_inference') {
      setFromWebsocket(data);
      setStatus(type);
      if (type == 'COMPLETED' || type == 'FAILED') {
        // refetchInference();
        InferenceListArray().then(res => {
          setInferenceList(res.data.data);
        });
        handelRefetchObjects().then(res => {});
      }
    }

    if (message?.pipeline_type === 'quality_control_novelty') {
      setFromWebsocket(data);

      setNoveltyStatus(type);
      if (type == 'COMPLETED') {
        // refetchInference();
        InferenceListArray().then(res => {
          setInferenceList(res.data.data);
        });
        handelRefetchObjects().then(res => {});
      }
    }
  };

  const blink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

  const IconWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
  `;
  const BlinkingImage = styled.img`
    width: 30px;
    height: 30px;
  `;
  const BlinkingCircle = styled.div`
    position: absolute;
    top: -5px; /* Adjust position based on your requirement */
    right: -5px; /* Adjust position based on your requirement */
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    animation: ${blink} 1s infinite;
  `;
  const count = useMemo(() => {
    const o =
      objects?.reduce((acc, obj) => acc + (obj.images?.length || 0), 0) || 0;
    return o;
  }, [objects]);

  const navigate = useNavigate();
  const [rows, setRows] = useState<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = React.useState<any>([]);
  useEffect(() => {
    const Updatedrows =
      InferenceList?.filter((item: any) => {
        // Check if searchTerm is valid
        const trimmedSearchTerm = searchTerm?.trim().toLowerCase();
        if (!trimmedSearchTerm) return true; // If no valid search term, include all rows

        // Filter rows based on the search term
        if (typeof item.inference_name === 'string') {
          return item.inference_name.toLowerCase().includes(trimmedSearchTerm);
        }
        return false; // Exclude rows without a valid 'name' field
      })
        .map(item => {
          let inference_status;
          // console.log("scdcsdasdcds",fromWebsocket?.inference_id ,item.data_id)
          if (
            item.status === 'COMPLETED' // Prioritize DB status
          ) {
            inference_status = 'COMPLETED';
          } else if (
            fromWebsocket?.data &&
            fromWebsocket?.data_id === item.data_id
          ) {
            inference_status = 'COMPLETED'; // Show "Completed" when WebSocket data is received and matches
          } else if (
            fromWebsocket?.progress !== undefined &&
            fromWebsocket?.data_id === item.data_id
          ) {
            inference_status = fromWebsocket.progress; // Show progress if available and inference_id matches
          } else if (fromWebsocket?.data_id === item.data_id) {
            inference_status = fromWebsocket?.status || item.status; // Fallback to WebSocket status or item status
          } else {
            inference_status = item.status; // Default to DB status if no WebSocket match
          }

          // Map fields to rows
          return {
            inferencename: item.inference_name,
            configurationname: item.config_name,
            modelname: item.model_name,
            dataSetName: item?.datasetname,
            ModelArchitecture: item?.model_architecture,
            totalimages: item.total_image_count,
            class_count: item.class_count,
            inference_id: item.data_id,
            config_id: item.config_id,
            date: formatDateTime(item.created_at as string),
            inference_status,
            model_id: item.model_id || '',
            mode: item?.mode || '',
            novelty_status: item.novelty_status || false,
            nov_status:
              item.nov_status === 'COMPLETED' // Prioritize DB status for nov_status
                ? 'COMPLETED'
                : item.nov_status === '' || item?.nov_status === null // Check if nov_status is empty
                ? 'N/A'
                : fromWebsocket?.data_id === item.data_id
                ? noveltystatus // Use WebSocket nov_status or fallback to 'N/A'
                : item.nov_status || noveltystatus, // Default to item.nov_status or noveltystatus
            isDeleted: item?.is_deleted
          };
        })
        .filter(item => item.mode != 'live') || [];

    let DataCount = data && data?.data?.length;
    const updatedcolumns: ITableHeadColumn[] = [
      {
        value: 'Inference Name',
        key: 'inferencename',
        align: 'center'
      },
      {
        value: 'DataSet Name',
        key: 'dataSetName',
        align: 'center'
      },
      {
        value: 'Model Name',
        key: 'modelname',
        align: 'center'
      },
      {
        value: 'Model Architecture',
        key: 'ModelArchitecture',
        align: 'center'
      },
      {
        value: 'Created at',
        key: 'date',
        align: 'center'
      },
      {
        value: 'Total Images',
        key: 'totalimages',
        align: 'center'
      },
      {
        value: 'Total Classes',
        key: 'class_count',
        align: 'center'
      },

      {
        value: 'Inference Status',
        key: 'inference_status',
        align: 'center',
        render: ({ value, record }) => {
          const trainingStatus = record?.inference_status;
          console.log('trainingStatus', trainingStatus);
          if (trainingStatus === 'COMPLETED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Completed
              </Typography>
            );
          }
          if (trainingStatus === 'FAILED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Failed
              </Typography>
            );
          }
          if (trainingStatus === 'INPROGRESS') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                In Progress
              </Typography>
            );
          }

          if (trainingStatus === 'QUEUED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Queued
              </Typography>
            );
          }
          if (trainingStatus === 'STOPPED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Stopped
              </Typography>
            );
          }

          // If it's neither "COMPLETED" nor "QUEUED", show the progress bar
          return (
            <Box position="relative" display="inline-flex" ml={4}>
              <CircularProgress
                variant="determinate"
                // value={typeof trainingStatus === 'number' ? trainingStatus : 0} // Ensure it's a number
                value={
                  typeof trainingStatus === 'number'
                    ? Number(trainingStatus.toFixed(2))
                    : 0
                }
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                  style={{ fontSize: '10px' }}
                >
                  {`${Math.round(trainingStatus as number)}%`}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
      {
        value: 'Novelty Status',
        key: 'nov_status',
        align: 'center',
        render: ({ value, record }) => {
          const trainingStatus = record?.nov_status;

          if (trainingStatus === 'COMPLETED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Completed
              </Typography>
            );
          }
          if (trainingStatus === 'FAILED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Failed
              </Typography>
            );
          }
          if (
            trainingStatus === 'INPROGRESS' ||
            trainingStatus === 'PROGRESS'
          ) {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                InProgress
              </Typography>
            );
          }

          if (trainingStatus === 'QUEUED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Queued
              </Typography>
            );
          }
          if (trainingStatus === 'STOPPED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Stopped
              </Typography>
            );
          } else {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                N/A
              </Typography>
            );
          }
        }
      },
      {
        value: 'Action',
        key: 'Novelty',
        align: 'center',
        render: ({ value, record }) => {
          return (
            <Box
              display="flex"
              gap={1}
              justifyContent="center"
              alignItems="center"
              ml={4}
            >
              {/* IconButton for "View Training Data" */}

              <Tooltip title="View Inferred Result">
                <IconButton
                  disabled={record.inference_status !== 'COMPLETED'}
                  onClick={() => {
                    dispatch(
                      setSelectedConfiguration(record.config_id as string)
                    );
                    setCurrentInferenceID(record.inference_id as string);
                    setSelectedModel(record.model_id as string);
                    onChange(2);
                    setStatusValue('COMPLETED');
                  }}
                >
                  {/* <IconWrapper>
                  {record?.mode === 'live' &&
                    record?.inference_status === 'INPROGRESS' && (
                      <BlinkingCircle />
                    )}
                  <BlinkingImage
                    src={
                      record?.mode === 'live' &&
                      record?.inference_status === 'INPROGRESS'
                        ? ReportIcon
                        : record?.inference_status === 'COMPLETED' ||
                          record?.inference_status === 'STOPPED'
                        ? Reports
                        : reportsNotFound
                    }
                    alt="Report"
                  />
                </IconWrapper> */}
                  <img
                    src={
                      record.inference_status === 'COMPLETED'
                        ? Reports
                        : reportsNotFound
                    }
                    alt="Report"
                    style={{
                      width: '30px',
                      height: '30px',
                      filter: `grayscale(90%)`
                    }}
                  />
                </IconButton>
              </Tooltip>
              {(profile?.role === 'organization_admin' ||
                userFeatures?.includes('NOVELTY')) && (
                <Tooltip title="View Novelty Data">
                  <IconButton
                    disabled={!record.novelty_status}
                    onClick={() => {
                      if (record.novelty_status) {
                        navigate('/novelty', {
                          state: {
                            inference_id: record.inference_id,
                            configId: record.config_id,
                            selectedModal: record.model_id,
                            statusValue: 'COMPLETED',
                            type:
                              record.calss_name === 'OK' ||
                              record.calss_name === 'NG'
                                ? 'LABELLED'
                                : 'UNLABELLED'
                          }
                        });
                      }
                    }}
                  >
                    <img
                      src={
                        record.novelty_status
                          ? ViewNovelty
                          : ViewNoveltyDisabled
                      }
                      alt="Report"
                      style={{
                        width: '30px',
                        height: '30px',
                        filter: `grayscale(90%)`
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          );
        }
      }
    ];
    setRows(Updatedrows);
    const filteredColumns = advancedDetails
      ? updatedcolumns
      : updatedcolumns.filter(
          column => !['ModelArchitecture'].includes(column.key)
        );
    setColumns(filteredColumns);
  }, [data, searchTerm, advancedDetails, InferenceList, fromWebsocket]);

  const dispatch = useDispatch();

  return (
    <Card style={{ height: '100%' }}>
      {!startInference && (
        <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: 2.5
            }}
          >
            Recorded Inference Dashboard
          </Typography>{' '}
          {
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {configs &&
                configs.length > 0 &&
                selectedConfig != '' &&
                selectedConfig != undefined &&
                !startInference &&
                (profile?.role === 'organization_admin' ||
                  userFeatures?.includes('ADVANCEDSETTINGS')) && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={advancedDetails}
                        onChange={handleToggleChange}
                        name="toggleButton"
                        color="primary"
                      />
                    }
                    label="Advanced Details"
                    style={{ marginLeft: '18px', marginRight: '30px' }}
                    labelPlacement="start" // Ensure label is on the left (before the switch)
                  />
                )}

              {configs && !startInference && (
                <SelectInput
                  name="configurationType"
                  label="Select Configuration"
                  variant="outlined"
                  width={'250px'}
                  placeholder="Select or Search Configuration"
                  // disabled={value != undefined}
                  options={configs?.map(i => ({
                    label: i.name,
                    value: i.id
                  }))}
                  value={selectedConfig || ''}
                  onChange={value => {
                    dispatch(setSelectedConfiguration(value));

                    setStartInference(false);
                  }}
                />
              )}
            </div>
          }
        </Stack>
      )}
      {configs && configs.length > 0 && selectedConfig == '' && (
        <Stack
          style={{
            height: '41vh',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <NoDataComponent />
          <Typography style={{ color: '#707070', fontSize: '26px' }}>
            Select Configuration to proceed with the process{' '}
          </Typography>
        </Stack>
      )}
      {configs &&
        configs.length > 0 &&
        selectedConfig != '' &&
        selectedConfig != undefined &&
        !startInference && (
          <Box
            style={{
              height: 'calc(100vh - 220px)'
            }}
          >
            {rows && (
              <ListTable
                columns={columns}
                rows={rows}
                searchVal={searchTerm}
                setSearchVal={setSearchTerm}
                striped={true}
                loading={false}
                stickyHeader
                showSearch={true}
                tableContainerStyle={{ height: '82%' }}
                actionButtons={
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    mt={2}
                    width={'80vw'}
                  >
                    {' '}
                    <Button
                      onClick={() => {
                        onChange(5);

                        setStartInference(true);
                      }}
                      style={{
                        width: '310px',
                        height: '45px',
                        borderRadius: '6px'
                      }}
                    >
                      {' '}
                      Start Inference
                    </Button>
                  </Stack>
                }
              />
            )}
          </Box>
        )}

      <CreateModal
        open={createModalOpen}
        InferenceId={currentInferenceID}
        objects={objects || []}
        ModelList={modelList}
        setSelectedModel={setSelectedModel}
        count={count}
        onClose={() => {
          setCreateModalOpen(false);
          onChange(5);
        }}
        selectedModel={selectedModel}
        refetchInference={refetchInference}
        selectedObjects={selectedObjects}
        onSucess={() => {
          InferenceListArray().then(res => {
            setInferenceList(res.data.data);
          });
          setCreateModalOpen(false);
          setStartInference(false);
          onChange(1);
        }}
      />
    </Card>
  );
};
