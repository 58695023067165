import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  Typography
} from 'components';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  CardActionArea,
  IconButton,
  LinearProgress,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useListConfigurations } from 'api/sdk';
import { useWebSocket } from 'hooks';
import { Formik, Form } from 'formik';

import { ObjectCategoryCard } from 'components';
import Inference from '../../assets/images/trainingImage.svg';
import { CreateModal } from './createModal';
import { useNavigate } from 'react-router-dom';
import Reports from '../../assets/images/reportsQuality.svg';
import reportsNotFound from '../../assets/images/reportsNotFound.svg';
import styled, { keyframes } from 'styled-components';

import ReportIcon from '../../assets/images/inferenceProgress.svg';
import {
  ConfigSchema,
  CreateObjectSchema,
  IchorInferenceSchema,
  InferenceSchema,
  ModelListSchema,
  ObjSchema
} from 'schemas';
import ViewNovelty from '../../assets/images/ViewNovelty.svg';
import ViewNoveltyDisabled from '../../assets/images/ViewNoveltyDisable.svg';

import { Recommend } from '@mui/icons-material';
import Env from 'config/env';
import { setSelectedConfiguration } from 'store/configSlice';
import { useDispatch } from 'react-redux';
import FileManager from 'components/filemanager/rawData';

import { CreateModalLive } from './createModalLive';
import { ListTable } from 'components/ListTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import NoDataComponent from 'components/NoDataComponent';
interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  objects: ObjSchema[] | undefined;

  handelRefetchObjects: () => Promise<any>;
  setCurrentInferenceID: (inferenceID: string | undefined) => void;
  setSelectedModel: (data: string) => void;
  selectedModel: string | undefined;
  setStatusValue: any;
  InferenceListArray: () => Promise<any>;
  setInferenceList: (data: InferenceSchema[]) => void;

  handleModelList: () => void;
  modelList: any;
  InferenceList: InferenceSchema[];
  currentInferenceID: string | undefined;

  refetchInference: () => void;
}

export const MainLive: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  objects,
  handelRefetchObjects,
  modelList,
  InferenceList,
  handleModelList,
  setCurrentInferenceID,
  currentInferenceID,
  selectedModel,
  setSelectedModel,
  InferenceListArray,
  setStatusValue,
  setInferenceList,
  refetchInference
}) => {
  const profile = useSelector((state: RootState) => state.auth.profile);
  const { data, isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });
  const userFeatures = profile?.features_list || [];

  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [startInference, setStartInference] = React.useState(false);
  // const [openFileManager, setOpenFileManager] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined>(undefined);
  const [step, setStep] = useState<string | undefined>(undefined);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fromWebsocket, setFromWebsocket] = useState<any>();
  const [inferenceValue, setInferenceValue] = useState('');
  const [liveInferenceModal, setLiveInferenceModal] = useState(false);
  const [noveltystatus, setNoveltyStatus] = useState('');

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [status, setStatus] = useState('QUEUED');

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [advancedDetails, setAdvancedDetails] = useState(false);
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvancedDetails(event.target.checked);
    // Dispatch any action or handle toggle state change if necessary
  };
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);
  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    if (message?.pipeline_type === 'quality_control_live_inference') {
      setFromWebsocket(data);
      setStatus(type);
      if (type == 'COMPLETED' || type == 'FAILED') {
        // refetchInference();
        InferenceListArray().then(res => {
          setInferenceList(res.data.data);
        });
        handelRefetchObjects().then(res => {
          setProcessing(false);
        });
      }
    }

    if (message?.pipeline_type === 'quality_control_novelty') {
      setFromWebsocket(data);

      setNoveltyStatus(type);
      if (type == 'COMPLETED') {
        // refetchInference();
        InferenceListArray().then(res => {
          setInferenceList(res.data.data);
        });
        handelRefetchObjects().then(res => {
          setProcessing(false);
        });
      }
    }
  };

  const blink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

  const IconWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
  `;
  const BlinkingImage = styled.img`
    width: 30px;
    height: 30px;
  `;
  const BlinkingCircle = styled.div`
    position: absolute;
    top: -5px; /* Adjust position based on your requirement */
    right: -5px; /* Adjust position based on your requirement */
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    animation: ${blink} 1s infinite;
  `;
  const count = useMemo(() => {
    const o =
      objects?.reduce((acc, obj) => acc + (obj.images?.length || 0), 0) || 0;
    return o;
  }, [objects]);

  const navigate = useNavigate();

  const [rows, setRows] = useState<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = React.useState<any>([]);
  const [totalCounts, setTotalCounts] = useState<Record<string, number>>({});
  useEffect(() => {
    // First, update the totalCounts in a separate effect
    if (fromWebsocket?.data_id && fromWebsocket?.image_count !== undefined) {
      setTotalCounts(prev => ({
        ...prev,
        [fromWebsocket.data_id]: Math.max(
          prev[fromWebsocket.data_id] || 0,
          fromWebsocket.image_count
        )
      }));
    }
  }, [fromWebsocket]);
  useEffect(() => {
    const Updatedrows =
      InferenceList?.filter((item: any) => {
        // Check if searchTerm is valid
        const trimmedSearchTerm = searchTerm?.trim().toLowerCase();
        if (!trimmedSearchTerm) return true; // If no valid search term, include all rows

        // Filter rows based on the search term
        if (typeof item.inference_name === 'string') {
          return item.inference_name.toLowerCase().includes(trimmedSearchTerm);
        }
        return false; // Exclude rows without a valid 'name' field
      })
        ?.map(item => {
          let inference_status;
          let totalCount;

          if (
            item.status === 'COMPLETED' // Prioritize DB status
          ) {
            inference_status = 'COMPLETED';
          } else if (
            fromWebsocket?.data &&
            fromWebsocket?.data_id === item.data_id
          ) {
            inference_status = 'COMPLETED'; // Show "Completed" when WebSocket data is received and matches
          } else if (
            fromWebsocket?.progress !== undefined &&
            fromWebsocket?.data_id === item.data_id
          ) {
            inference_status = fromWebsocket.progress; // Show progress if available and inference_id matches
          } else if (fromWebsocket?.data_id === item.data_id) {
            inference_status = fromWebsocket?.status || item.status; // Fallback to WebSocket status or item status
          } else {
            inference_status = item.status; // Default to DB status if no WebSocket match
          }

          // Use the totalCounts state to determine the totalCount
          if (inference_status === 'COMPLETED') {
            totalCount = item.total_image_count;
          } else {
            // Use the stored totalCount from state if available, otherwise use the item's total_image_count
            totalCount = Math.max(
              item.total_image_count,
              totalCounts[item.data_id] || 0
            );
          }
          // Map fields to rows
          return {
            inferencename: item.inference_name,
            configurationname: item.config_name,
            modelname: item.model_name,
            cameraname: item?.camera_name,
            samplingRate: item?.sampling_rate,
            ModelArchitecture: item?.model_architecture,
            totalimages: totalCount,
            class_count: item.class_count,
            inference_id: item.data_id,
            config_id: item.config_id,
            inference_status,
            model_id: item.model_id || '',
            mode: item?.mode || '',
            novelty_status: item.novelty_status || false,
            nov_status:
              item.nov_status === 'COMPLETED' // Prioritize DB status for nov_status
                ? 'COMPLETED'
                : item.nov_status === '' || item?.nov_status === null // Check if nov_status is empty
                ? 'N/A'
                : fromWebsocket?.data_id === item.data_id
                ? noveltystatus // Use WebSocket nov_status or fallback to 'N/A'
                : item.nov_status || noveltystatus, // Default to item.nov_status or noveltystatus
            isDeleted: item?.is_deleted
          };
        })
        .filter(item => item.mode === 'live') || [];

    let DataCount = data && data?.data?.length;
    const updatedcolumns: ITableHeadColumn[] = [
      {
        value: 'Inference Name',
        key: 'inferencename',
        align: 'center'
      },
      {
        value: 'Camera Name',
        key: 'cameraname',
        align: 'center'
      },
      {
        value: 'Sampling Rate(s)',
        key: 'samplingRate',
        align: 'center'
      },

      {
        value: 'Model Name',
        key: 'modelname',
        align: 'center'
      },
      {
        value: 'Model Architecture',
        key: 'ModelArchitecture',
        align: 'center'
      },
      {
        value: 'Total Images',
        key: 'totalimages',
        align: 'center'
      },
      // {
      //   value: 'Total Classes',
      //   key: 'class_count',
      //   align: 'center'
      // },

      {
        value: 'Inference Status',
        key: 'inference_status',
        align: 'center',
        render: ({ value, record }) => {
          const trainingStatus = record?.inference_status;

          if (trainingStatus === 'COMPLETED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Completed
              </Typography>
            );
          }
          if (trainingStatus === 'FAILED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                FAILED
              </Typography>
            );
          }
          if (trainingStatus === 'INPROGRESS' && record?.mode === 'live') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                In Progress
              </Typography>
            );
          }

          if (trainingStatus === 'QUEUED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Queued
              </Typography>
            );
          }
          if (trainingStatus === 'STOPPED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Stopped
              </Typography>
            );
          }

          // If it's neither "COMPLETED" nor "QUEUED", show the progress bar
          return (
            <Box position="relative" display="inline-flex" ml={4}>
              <CircularProgress
                variant="determinate"
                // value={typeof trainingStatus === 'number' ? trainingStatus : 0} // Ensure it's a number
                value={
                  typeof trainingStatus === 'number'
                    ? Number(trainingStatus.toFixed(2))
                    : 0
                }
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                  style={{ fontSize: '10px' }}
                >
                  {`${Math.round(trainingStatus as number)}%`}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
      {
        value: 'Novelty Status',
        key: 'nov_status',
        align: 'center',
        render: ({ value, record }) => {
          const trainingStatus = record?.nov_status;

          if (trainingStatus === 'COMPLETED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Completed
              </Typography>
            );
          }
          if (trainingStatus === 'FAILED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                FAILED
              </Typography>
            );
          }
          if (trainingStatus === 'INPROGRESS' && record?.mode === 'live') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                INPROGRESS
              </Typography>
            );
          }

          if (trainingStatus === 'QUEUED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Queued
              </Typography>
            );
          }
          if (trainingStatus === 'STOPPED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Stopped
              </Typography>
            );
          } else {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                N/A
              </Typography>
            );
          }
        }
      },
      {
        value: 'Action',
        key: 'Novelty',
        align: 'center',
        render: ({ value, record }) => {
          return (
            <Box
              display="flex"
              gap={1}
              justifyContent="center"
              alignItems="center"
              ml={4}
            >
              {/* IconButton for "View Training Data" */}

              <Tooltip
                title={
                  record?.mode === 'live' &&
                  record?.inference_status === 'INPROGRESS'
                    ? 'Play Live Inference'
                    : 'View Inferred Result'
                }
              >
                <IconButton
                  disabled={
                    record.inference_status === 'FAILED' ||
                    record?.inference_status === 'QUEUED' ||
                    (record?.inference_status === 'COMPLETED' &&
                      record?.totalimages === 0)
                  }
                  onClick={() => {
                    if (
                      record?.mode === 'live' &&
                      record?.inference_status === 'INPROGRESS'
                    ) {
                      setCurrentInferenceID(record.inference_id as string);
                      // Navigate to step 3 for live inference
                      onChange(3);
                    } else {
                      dispatch(
                        setSelectedConfiguration(record.config_id as string)
                      );
                      setCurrentInferenceID(record.inference_id as string);
                      setSelectedModel(record.model_id as string);
                      record?.mode === 'live' ? onChange(4) : onChange(2);
                      setStatusValue('COMPLETED');
                    }
                  }}
                >
                  {/* <img
                  src={
                    record?.mode === 'live' &&
                    record?.inference_status === 'INPROGRESS'
                      ? ReportIcon // Replace this with the image source for "live in progress"
                      : record?.inference_status === 'COMPLETED'
                      ? Reports
                      : reportsNotFound
                  }
                  className={
                    record?.mode === 'live' &&
                    record?.inference_status === 'INPROGRESS'
                      ? 'blink'
                      : ''
                  }
                  alt="Report"
                  style={{
                    width: '30px',
                    height: '30px'
                    // filter: `grayscale(90%)`
                  }}
                /> */}
                  <IconWrapper>
                    {record?.mode === 'live' &&
                      record?.inference_status === 'INPROGRESS' && (
                        <BlinkingCircle />
                      )}
                    <BlinkingImage
                      src={
                        record?.mode === 'live' &&
                        record?.inference_status === 'INPROGRESS'
                          ? ReportIcon
                          : (record?.inference_status === 'COMPLETED' &&
                              record?.totalimages !== 0) ||
                            record?.inference_status === 'STOPPED'
                          ? Reports
                          : reportsNotFound
                      }
                      alt="Report"
                    />
                  </IconWrapper>
                </IconButton>
              </Tooltip>
              <Tooltip title="View Novelty Data">
                <IconButton
                  disabled={!record.novelty_status}
                  onClick={() => {
                    if (record.novelty_status) {
                      navigate('/novelty', {
                        state: {
                          inference_id: record.inference_id,
                          configId: record.config_id,
                          selectedModal: record.model_id,
                          statusValue: 'COMPLETED',
                          type:
                            record.calss_name === 'OK' ||
                            record.calss_name === 'NG'
                              ? 'LABELLED'
                              : 'UNLABELLED'
                        }
                      });
                    }
                  }}
                >
                  <img
                    src={
                      record.novelty_status ? ViewNovelty : ViewNoveltyDisabled
                    }
                    alt="Report"
                    style={{
                      width: '30px',
                      height: '30px',
                      filter: `grayscale(90%)`
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }
      }
    ];
    setRows(Updatedrows);
    const filteredColumns = advancedDetails
      ? updatedcolumns
      : updatedcolumns.filter(
          column => !['ModelArchitecture'].includes(column.key)
        );
    setColumns(filteredColumns);
  }, [
    data,
    searchTerm,
    advancedDetails,
    InferenceList,
    fromWebsocket
    // totalCounts
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedConfig != '') {
      setStep('INITIAL');
    }
  }, []);
  const handleClose = () => setLiveInferenceModal(false);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} mt={2}>
          <Card
            style={{
              height:
                configs &&
                configs.length > 0 &&
                selectedConfig != '' &&
                !startInference
                  ? '86vh'
                  : '86vh'
            }}
          >
            <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  lineHeight: 2.5
                }}
              >
                Live Inference Dashboard
              </Typography>{' '}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Toggle Button */}
                {configs &&
                  configs.length > 0 &&
                  selectedConfig != '' &&
                  selectedConfig != undefined &&
                  !startInference &&
                  (profile?.role === 'organization_admin' ||
                    userFeatures?.includes('ADVANCEDSETTINGS')) && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={advancedDetails}
                          onChange={handleToggleChange}
                          name="toggleButton"
                          color="primary"
                        />
                      }
                      label="Advanced Details"
                      style={{ marginLeft: '18px', marginRight: '30px' }}
                      labelPlacement="start" // Ensure label is on the left (before the switch)
                    />
                  )}

                {configs && !startInference && (
                  <SelectInput
                    name="configurationType"
                    label="Select Configuration"
                    variant="outlined"
                    width={'250px'}
                    placeholder="Select or Search Configuration"
                    // disabled={value != undefined}
                    options={configs?.map(i => ({
                      label: i.name,
                      value: i.id
                    }))}
                    value={selectedConfig || ''}
                    onChange={value => {
                      dispatch(setSelectedConfiguration(value));

                      setStep('INITIAL');
                    }}
                  />
                )}
              </div>
            </Stack>
            {configs &&
              configs.length > 0 &&
              selectedConfig != '' &&
              selectedConfig != undefined &&
              !startInference && (
                // !startInference &&
                <Box>
                  <Box>
                    {/* {rows && (
                  <Table
                    stickyHeader={true}
                    columns={filteredColumns}
                    rows={rows}
                    striped={false}
                  />
                )} */}
                    <div
                      style={{
                        height: 'calc(100vh - 220px)'
                      }}
                    >
                      {rows && (
                        <ListTable
                          columns={columns}
                          rows={rows}
                          searchVal={searchTerm}
                          setSearchVal={setSearchTerm}
                          striped={true}
                          loading={false}
                          stickyHeader
                          showSearch={true}
                          tableContainerStyle={{ height: '82%' }}
                          actionButtons={
                            configs &&
                            configs.length > 0 &&
                            selectedConfig != '' &&
                            !startInference && (
                              <>
                                <Stack
                                  direction={'row'}
                                  justifyContent={'center'}
                                  mt={2}
                                  sx={{ width: '100%' }}
                                >
                                  {' '}
                                  <Button
                                    onClick={() => {
                                      setStep('INITIAL');
                                      setInferenceValue('live_inference');
                                      setCreateModalOpen(true);
                                      setSelectedModel('');
                                      // setStartInference(true);
                                    }}
                                    style={{
                                      width: '310px',
                                      height: '45px',
                                      marginLeft: '20px',
                                      borderRadius: '6px'
                                    }}
                                  >
                                    {' '}
                                    Start Live Inference
                                  </Button>
                                </Stack>
                              </>
                            )
                          }
                          // containerStyle={{ overflow: 'auto' }}
                        />
                      )}
                    </div>
                  </Box>
                </Box>
              )}
            {configs && configs.length == 0 && (
              <Stack style={{ textAlign: 'center' }}>
                {' '}
                <Typography>
                  {' '}
                  Please add configuration to inference a model
                </Typography>{' '}
              </Stack>
            )}

            {configs && configs.length > 0 && selectedConfig == '' && (
              <Stack
                style={{
                  height: '41vh',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {/* <img
                  src={Inference}
                  alt="Inference"
                  style={{ width: '350px', height: '400px' }}
                /> */}
                <NoDataComponent />
                <Typography style={{ color: '#707070', fontSize: '26px' }}>
                  Select Configuration type to proceed with the process{' '}
                </Typography>
              </Stack>
            )}

            <Stack spacing={2} direction="column" alignItems="center">
              <Typography
                variant="body2"
                style={{ color: 'red', fontWeight: '16px' }}
              >
                {errorMessage}
              </Typography>
            </Stack>
          </Card>

          <CreateModalLive
            open={createModalOpen}
            InferenceId={currentInferenceID}
            objects={objects || []}
            ModelList={modelList}
            setSelectedModel={setSelectedModel}
            count={count}
            onClose={() => setCreateModalOpen(false)}
            selectedModel={selectedModel}
            InferenceListArray={InferenceListArray}
            refetchInference={refetchInference}
            inferenceValue={inferenceValue}
            onSucess={() => {
              InferenceListArray().then(res => {
                setInferenceList(res.data.data);
              });
              setCreateModalOpen(false);
              setStep('INITIAL');
              setStartInference(false);
              onChange(1);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
