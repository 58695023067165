import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Stack
} from '@mui/material';
import { Card, Typography } from 'components';
import React, { useEffect, useState } from 'react';
import GroupStatistics from './GroupStatistics';
import StorageDetails from './StorageDetails';
import AccessDetails from './AccessDetails';
import ConfigDetails from './ConfigDetails';
import { SelectInput } from 'components/Form';
import { useGetGroups, useGroupAnalytics } from 'api/sdk';
import { GroupAnalyticsResponseSchema, GroupSchema, Type } from 'schemas';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SkeletonLoader from './SkeletonLoader';

const Admindashboard = () => {
  const [selectedValue, setSelectedValue] = useState<string>('self');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  //hetgroups api
  const [groupList, SetGroupList] = useState<GroupSchema[]>([]);

  const [groupLevelDetails, SetGroupLevelDetails] =
    useState<GroupAnalyticsResponseSchema>();
  const { isLoading, isError, refetch } = useGetGroups();
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && SetGroupList(res.data?.data);
    });
  }, [groupList]);

  const groupNames =
    groupList &&
    groupList?.map((group: GroupSchema) => ({
      label: group?.name,
      value: group?.id
    }));
  const UserId = useSelector((state: RootState) => state?.auth?.profile?.id);

  const [selectedGroup, setSelectedGroup] = useState<string>('');

  //access level details fetching

  const {
    isLoading: groupDelLoading,
    isError: groupDelError,
    refetch: getGroupData
  } = useGroupAnalytics({
    id:
      selectedValue === 'self' && UserId
        ? UserId
        : selectedValue === 'group'
        ? selectedGroup || groupNames?.[0]?.value
        : '', // Fallback to an empty string if no value is found
    type: selectedValue === 'self' ? Type.self : Type.group
  });
  useEffect(() => {
    getGroupData().then(res => {
      res.data?.data && SetGroupLevelDetails(res.data?.data);
    });
  }, [groupLevelDetails, selectedGroup, selectedValue]);

  // Set the default selected group to the first group's value
  useEffect(() => {
    if (groupNames.length > 0 && !selectedGroup) {
      setSelectedGroup(groupNames[0].value);
    }
  }, [groupNames, selectedGroup]);

  return (
    <>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: 2.5
        }}
      >
        Admin Dashboard
      </Typography>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <GroupStatistics />
            </Grid>
            <Grid item xs={2}>
              <Card style={{ backgroundColor: '#102633' }}>
                <Stack
                  direction="column"
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  ml={1}
                >
                  <RadioGroup
                    value={selectedValue}
                    onChange={handleChange}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px'
                    }}
                  >
                    <FormControlLabel
                      value="self"
                      label={<Typography variant="body1">Self</Typography>}
                      control={
                        <Radio
                          sx={{
                            color: '#FFFFFF',
                            '&.Mui-checked': {
                              color: '#FFFFFF'
                            },
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.1)'
                            },
                            width: '24px', // Consistent size
                            height: '24px' // Consistent size
                          }}
                        />
                      }
                      sx={{
                        color: '#FFFFFF'
                      }}
                    />
                    <FormControlLabel
                      value="group"
                      control={
                        <Radio
                          sx={{
                            color: '#FFFFFF',
                            '&.Mui-checked': {
                              color: '#FFFFFF'
                            },
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.1)'
                            },
                            width: '24px', // Consistent size
                            height: '24px' // Consistent size
                          }}
                        />
                      }
                      label={<Typography variant="body1">Group</Typography>}
                      sx={{
                        color: '#FFFFFF'
                      }}
                    />
                  </RadioGroup>
                  <Grid container xs={12}>
                    {
                      <Grid item xs={12}>
                        <SelectInput
                          fullwidth
                          disabled={selectedValue === 'self'}
                          // label="Select Group"
                          placeholder="Select Group"
                          variant="outlined"
                          name="groups"
                          defaultValue={groupNames[0]?.value} // Default value tied to the first group's value
                          value={selectedGroup} // Show the selected group's value or default to the first group's value
                          options={groupNames} // Use dummy group data here
                          onChange={value => setSelectedGroup(value)} // Update the state on change
                        />
                      </Grid>
                    }
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0.5}>
            <Grid
              item
              xs={12}
              sm={12}
              md={selectedValue === 'self' ? 12 : 6}
              lg={3}
            >
              {' '}
              <StorageDetails groupLevelDetails={groupLevelDetails} />
            </Grid>
            {/* AccessDetails - Hidden if selectedValue is "self" */}
            {selectedValue !== 'self' && (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <AccessDetails groupLevelDetails={groupLevelDetails} />
              </Grid>
            )}

            {/* ConfigDetails - Expands to lg={9} if selectedValue is "self" */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={selectedValue === 'self' ? 9 : 5}
            >
              <ConfigDetails
                groupLevelDetails={groupLevelDetails}
                selectedValue={selectedValue}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Admindashboard;
