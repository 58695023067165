import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Contents } from '../../schemasMedia';
import Folder from '../../assets/images/Folder.png';
import DraggableItem from './Draggable';
import { MyContext } from 'layouts/PrivateLayout';
import ImageModal from './imageViewer';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useWebSocket } from 'hooks';
import FolderType from './FolderType';
import Env from 'config/env';
import FolderUnzipping from './FolderUnzipping';
import { Checkbox, LinearProgress, TableCell, TableRow } from '@mui/material';

interface ListViewProps {
  handleRefetchDirectory: () => void;
  currentDirectory: string;
}

interface UnzippingProgress {
  foldername: string;
  path: string;
  progress: number;
}

const ListUnzipping: React.FC<ListViewProps> = ({
  handleRefetchDirectory,
  currentDirectory
}) => {
  const user_id = useSelector((state: RootState) => state.auth.profile?.id);
  const group_id = useSelector(
    (state: RootState) => state.auth.profile?.group_id || '/'
  );
  const userID = useSelector((state: RootState) => state.auth.profile?.id);
  const [unzippingProgress, setUnzippingProgress] = useState<
    UnzippingProgress[]
  >([]);
  const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL_MEDIA}/ws/media_manager/${userID}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        try {
          const data = event.data != 'ping' && JSON.parse(event.data);
          if (
            data.pipeline_type === 'updatezip' &&
            data.type === 'IN_PROGRESS'
          ) {
            setUnzippingProgress(prev => {
              const index = prev.findIndex(
                item => item.foldername === data.data.foldername
              );
              if (index !== -1) {
                const updatedProgress = [...prev];
                updatedProgress[index] = {
                  ...updatedProgress[index],
                  progress: data.data.progress
                };
                return updatedProgress;
              } else {
                return [
                  ...prev,
                  {
                    foldername: data.data.foldername,
                    path: data.data.file_save_path,
                    progress: data.data.progress
                  }
                ];
              }
            });
          }
          if (data.pipeline_type === 'updatezip' && data.type === 'COMPLETED') {
            handleRefetchDirectory();
            setUnzippingProgress(prev =>
              prev.filter(item => item.foldername !== data.data.foldername)
            );
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      });
    }
  }, [socket]);

  const fullDirectory = useMemo(() => {
    const a = `${group_id}/${user_id}/${currentDirectory}`;
    const b = a
      .split('/')
      .filter(item => item !== '')
      .join('/');
    return b;
  }, [group_id, user_id, currentDirectory]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '10px'
  }));

  return (
    <>
      {unzippingProgress.map(
        (item, index) =>
          fullDirectory == item.path && (
            <TableRow key={index}>
              <StyledTableCell align="left">
                <Checkbox disabled />
              </StyledTableCell>
              <StyledTableCell align="left">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',

                    cursor: 'pointer'
                  }}
                >
                  <img
                    loading="lazy"
                    src={Folder}
                    alt=""
                    style={{
                      width: '50px',
                      height: '40px',
                      transition: 'transform 0.3s ease'
                    }}
                    onMouseEnter={e => {
                      e.currentTarget.style.transform = 'scale(1.05)';
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.transform = 'scale(1)';
                    }}
                  />
                  {item.foldername}
                </div>
              </StyledTableCell>
              <StyledTableCell align="left">
                {<LinearProgress variant="determinate" value={item.progress} />}
              </StyledTableCell>
              <StyledTableCell align="left">
                Unzipping : {item.progress}%
              </StyledTableCell>
            </TableRow>
          )
      )}
    </>
  );
};

export default ListUnzipping;
