/* eslint-disable */

import { Button, Card, Typography, Modal } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react';

import { Stage, Layer, Image as KonvaImage, Circle } from 'react-konva';
import useImage from 'use-image';
import {
  Stack,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  IconButton,
  CircularProgress,
  Skeleton,
  Checkbox,
  Tooltip,
  Popover,
  Divider,
  styled
} from '@mui/material';
import Thumbnail from './Thumbnail';
import {
  ConfigSchema,
  LabelAsisitRequestQualityControl,
  LabelAsisitRequestQualityControlIncremental,
  ObjResponseSchemaCount,
  ObjSchema,
  QualitygeneratemaskRequest,
  SyncObjectsRequest,
  TrainingResultSchema
} from 'schemas';
// import { Graph } from './graph';
import { SelectInput, TextField } from 'components/Form';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import InfoIcon from '../../assets/images/InfoIcon.svg';
import Brightness from '../../assets/images/modelAugmentation/Brightness.png';
import Contrast from '../../assets/images/modelAugmentation/Contrast.png';
import Horizontal_Flip from '../../assets/images/modelAugmentation/Horizontal Flip.png';
import Hue from '../../assets/images/modelAugmentation/Hue.png';
import Original from '../../assets/images/modelAugmentation/Original.png';
import Rotation_180 from '../../assets/images/modelAugmentation/Rotation 180°.png';
import Rotation_270 from '../../assets/images/modelAugmentation/Rotation 270°.png';
import Rotation_90 from '../../assets/images/modelAugmentation/Rotation 90°.png';
import Rotation from '../../assets/images/modelAugmentation/Rotation.png';
import Saturation from '../../assets/images/modelAugmentation/Saturation.png';
import Scaling from '../../assets/images/modelAugmentation/Scaling.png';
import Shear from '../../assets/images/modelAugmentation/Shear.png';
import Translation from '../../assets/images/modelAugmentation/Translation.png';
import Vertical_Flip from '../../assets/images/modelAugmentation/Vertical Flip.png';

import { useNavigate } from 'react-router-dom';

import {
  useStartIncremental,
  useStartTraining,
  useModelArchitectureList,
  useSyncObjectByDataid
} from 'api/sdk';
import { v4 } from 'uuid';
import SkeletonLoader from 'components/SkeletonLoader';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { original } from '@reduxjs/toolkit';

interface TrainingModalProps {
  currentInferenceID: string;
  selectedConfig: string;
  mode: string;
  trainingModal: boolean;
  setTrainingModal: (data: boolean) => void;
  objectAll: ObjResponseSchemaCount | undefined;
  selectedModel?: string;
  setStep?: (data: number) => void;
  models?: TrainingResultSchema[];
}

const TrainingModal: React.FC<TrainingModalProps> = ({
  currentInferenceID,
  selectedConfig,
  selectedModel,
  mode,
  setStep,
  trainingModal,
  setTrainingModal,
  objectAll,
  models
}) => {
  const [modelArchitectureList, setModelArchitectureList] = useState<string[]>(
    []
  );
  const [advancedDetails, setAdvancedDetails] = useState(false);
  const [modelAugmentation, setModelAugmentation] = useState<boolean>(false);

  const profile = useSelector((state: RootState) => state.auth.profile);
  // Extract the feature list from the profile
  const userFeatures = profile?.features_list || [];
  const { data: objectData, refetch: startArchitecture } =
    useModelArchitectureList();
  const { mutateAsync: startTraining, error, isError } = useStartTraining();
  const { mutateAsync: startIncremental } = useStartIncremental();

  const navigate = useNavigate();
  interface FormValues {
    modelName: string;
    trainTestSplit: number;
    batchSize: number;
    learningRate: number;
    epochs: number;
    classesType: string;
  }
  const initialValues: FormValues = {
    modelName: '',
    classesType: '',
    trainTestSplit: 80,
    batchSize: 16,
    learningRate: 0.0001,
    epochs: 50
  };

  const validationInferenceSchema = Yup.object({
    modelName: Yup.string()
      .trim()
      .required('Model Name is required')
      .min(1, 'Model Name cannot be empty'),
    trainTestSplit: Yup.number()
      .required('Train Test Split is required')
      .min(50, 'Train Test Split must be between 50 -95')
      .max(95, 'Train Test Split must be between 50 -95'),
    batchSize: Yup.number()
      .required('Batch Size is required')
      .min(1, 'Batch Size must be between 1-32')
      .max(32, 'Batch Size must be between 1-32'),
    learningRate: Yup.number()
      .required('Learning Rate is required')
      .min(0.00001, 'Learning Rate must be between 0.00001 - 0.1')
      .max(0.1, 'Learning Rate must be  between 0.00001 - 0.1'),
    epochs: Yup.number()
      .required('Epochs is required')
      .min(1, 'Epochs must be between 1-500')
      .max(500, 'Epochs must be between 1-500')
  });

  const validationSchema = Yup.object({
    modelName: Yup.string()
      .trim()
      .required('Model Name is required')
      .min(1, 'Model Name cannot be empty'),
    trainTestSplit: Yup.number()
      .required('Train Test Split is required')
      .min(50, 'Train Test Split must be between 50 -95')
      .max(95, 'Train Test Split must be between 50 -95'),
    batchSize: Yup.number()
      .required('Batch Size is required')
      .min(1, 'Batch Size must be between 1-32')
      .max(32, 'Batch Size must be between 1-32'),
    learningRate: Yup.number()
      .required('Learning Rate is required')
      .min(0.00001, 'Learning Rate must be between 0.00001 - 0.1')
      .max(0.1, 'Learning Rate must be  between 0.00001 - 0.1'),
    epochs: Yup.number()
      .required('Epochs is required')
      .min(1, 'Epochs must be between 1-500')
      .max(500, 'Epochs must be between 1-500')
  });
  useEffect(() => {
    startArchitecture().then(res => {
      res != undefined &&
        setModelArchitectureList(res?.data?.data?.names || []);
    });
  }, []);

  const toggleAdvancedSettings = () => {
    if (advancedDetails && modelAugmentation) {
      setModelAugmentation(false);
      setAdvancedDetails(true);
    } else {
      setAdvancedDetails(prev => !prev);

      // setModelAugmentation(false);
    }
  };
  useEffect(() => {
    if (advancedDetails && !modelAugmentation) {
      setActiveTab('model');
    }
  }, [advancedDetails && modelAugmentation]);

  const mapAugmentations = (settings: any) => {
    const defaultAugmentations = {
      Color_Jitter: false,
      Fixed_Rotation: false,
      Normalize: true,
      Random_Affine_Transform: false,
      Random_Horizontal_Flip: true,
      Random_Vertical_Flip: true
    };

    const mappedAugmentations = settings?.reduce((acc: any, item: any) => {
      const formattedKey = item.label
        .replace(/\s+/g, '_') // Replace spaces with underscores
        .replace(/\(.*?\)/g, '') // Remove anything in parentheses
        .trim(); // Trim spaces

      acc[formattedKey] = item.checked; // Assign checked value
      return acc;
    }, {});

    return { ...defaultAugmentations, ...mappedAugmentations };
  };
  const handleSubmit = async (
    values: FormValues,
    { setFieldError }: FormikHelpers<FormValues>
  ) => {
    const augmentations = mapAugmentations(settings);
    const currentID = currentInferenceID;
    const data: LabelAsisitRequestQualityControl = {
      batch_size: values.batchSize,
      epoches: values.epochs,
      augmentations,
      learning_rate: values.learningRate,
      model_name: values.modelName,
      model_architecture: values.classesType || modelArchitectureList[1],
      data_id: currentID,
      config_id: selectedConfig || '',
      train_test_split_percentage: values.trainTestSplit
    };
    const data2: LabelAsisitRequestQualityControlIncremental = {
      batch_size: values.batchSize,
      epoches: values.epochs,
      learning_rate: values.learningRate,
      model_name: values.modelName,
      data_id: currentID,
      augmentations,
      // inference_id: currentID || '',
      config_id: selectedConfig || '',
      train_test_split_percentage: values.trainTestSplit,
      model_id: selectedModel || ''
    };
    if (mode == 'TRAINING') {
      try {
        await startTraining({ configId: selectedConfig, data: data });
        // setTrainingModal(false);
        setAdvancedDetails(false);
        setTrainingModal(false);
        setActiveTab('model');
        setModelAugmentation(false);
        setSettings(AdvanceSettingoptions);
        setStep && setStep(1);
      } catch (error: any) {
        if (error.response.data.detail) {
          setFieldError('modelName', error.response.data.detail);
        }
      }
    } else {
      try {
        await startIncremental({ configId: selectedConfig, data: data2 });
        // setTrainingModal(false);
        setAdvancedDetails(false);
        setTrainingModal(false);
        setSettings(AdvanceSettingoptions);
        setModelAugmentation(false);
        setStep && setStep(1);
        navigate('/train');
      } catch (error: any) {
        if (error.response.data.detail) {
          setFieldError('modelName', error.response.data.detail);
        }
      }
    }
  };
  const AugmentationValue = models?.find(
    i => i.model_id === selectedModel
  )?.augmentations;

  const AugmentationValueMap = Object.entries(AugmentationValue ?? {});
  const augmentationStatusMap = Object.fromEntries(AugmentationValueMap);
  console.log('fdsgdgfg', models, selectedModel, augmentationStatusMap);
  const AdvanceSettingoptions = [
    {
      label: 'Random Affine Transform',
      checked: augmentationStatusMap['Random_Affine_Transform'] || false,
      content: {
        title:
          'Applies Random Rotation, Translation, Scaling, And Shearing To Simulate Real-World Distortions.',
        description: 'Simulate Real-World Distortions.',
        original: [{ label: 'Original', imgSrc: Original }],
        examples: [
          { label: 'Translation', imgSrc: Translation },
          { label: 'Rotation', imgSrc: Rotation },
          { label: 'Scaling', imgSrc: Scaling },
          { label: 'Shear', imgSrc: Shear }
        ]
      }
    },
    {
      label: 'Color Jitter',
      checked: augmentationStatusMap['Color_Jitter'] || false,
      content: {
        title:
          'Randomly Changes Brightness, Contrast, Saturation, And Hue To Make The Model Resilient To Lighting Variations.',
        description: '',
        original: [{ label: 'Original', imgSrc: Original }],
        examples: [
          { label: 'Brightness', imgSrc: Brightness },
          { label: 'Contrast', imgSrc: Contrast },
          { label: 'Saturation', imgSrc: Saturation },
          { label: 'Hue', imgSrc: Hue }
        ]
      }
    },
    {
      label: 'Fixed Rotation',
      checked: augmentationStatusMap['Fixed_Rotation'] || false,
      content: {
        title:
          'Rotates The Image By 90°, 180°, Or 270° At Random, Helping Models Adapt To Different Fixed Orientations.',
        description: '',
        original: [{ label: 'Original', imgSrc: Original }],
        examples: [
          { label: 'Rotation 90°', imgSrc: Rotation_90 },
          { label: 'Rotation 180°', imgSrc: Rotation_180 },
          { label: 'Rotation 270°', imgSrc: Rotation_270 }
        ]
      }
    },
    {
      label: 'Random Horizontal Flip',
      checked: augmentationStatusMap['Random_Horizontal_Flip'] || true,
      content: {
        title:
          'Flips The Image Left-Right Randomly, Improving Robustness For Objects That Don’t Have A Fixed Left-Right Orientation.',
        description: '',
        original: [{ label: 'Original', imgSrc: Original }],
        examples: [{ label: 'Horizontal Flip', imgSrc: Horizontal_Flip }]
      }
    },
    {
      label: 'Random Vertical Flip',
      checked: augmentationStatusMap['Random_Vertical_Flip'] || true,
      content: {
        title:
          'Flips The Image Top-Bottom Randomly, Useful For Specific Cases Like Aerial Or Medical Images.',
        description: '',
        original: [{ label: 'Original', imgSrc: Original }],
        examples: [{ label: 'Vertical Flip', imgSrc: Vertical_Flip }]
      }
    },
    {
      label: 'Normalize',
      checked: augmentationStatusMap['Normalize'] || true,
      content: {
        title:
          'Adjusts Pixel Values To A Standard Range, Ensuring Stable Training And Compatibility With Pre-Trained Models.',
        description: '',
        original: [],
        examples: []
      }
    }
  ];

  const [settings, setSettings] = useState(
    mode == 'TRAINING' ? AdvanceSettingoptions : AdvanceSettingoptions
  );
  console.log('settings', settings);
  const [selectAll, setSelectAll] = useState(false);
  //to update the setting based on seleted models
  useEffect(() => {
    setSettings(AdvanceSettingoptions);
  }, [selectedModel]);

  // Function to handle individual checkbox toggle
  const handleAdvanceSettingChange = (index: number) => {
    const updatedSettings = settings.map((item, i) =>
      i === index ? { ...item, checked: !item.checked } : item
    );
    setSettings(updatedSettings);

    // Check if all checkboxes are selected
    setSelectAll(updatedSettings.every(item => item.checked));
  };

  // Function to handle "Select All" button
  const handleSelectAll = () => {
    const updatedSettings = settings.map(item => ({
      ...item,
      checked: true // Always set checked to true
    }));
    setSettings(updatedSettings);
  };
  //select all checking
  // Check if all items are selected
  const allSelected = settings && settings?.every(item => item.checked);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverContent, setPopoverContent] = useState<any>(null);
  // Open popover
  const handleClickInfo = (
    event: React.MouseEvent<HTMLButtonElement>,
    content: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  // Close popover
  const handleClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  // Whether the popover is open
  const open = Boolean(anchorEl);

  const [activeTab, setActiveTab] = useState<'model' | 'augmentation'>('model');

  // A custom styled Button for the toggle
  const ToggleButton = styled(Button)<{ active?: boolean }>(
    ({ active, theme }) => ({
      borderRadius: 9999, // Very large radius for a "pill" shape
      textTransform: 'none', // So text stays normal case
      fontWeight: 600,
      // Adjust colors and transitions:
      backgroundColor: active ? '#10263333' : 'transparent',
      color: '#373737',
      boxShadow: active ? theme.shadows[2] : 'none',
      '&:hover': {
        backgroundColor: active ? '#10263333' : '#FFFFFF33',
        color: '#373737'
      },
      transition: 'all 0.3s ease',
      whiteSpace: 'nowrap',
      border: active ? '1px solid #102633' : 'transparent'
    })
  );

  return (
    <Modal
      open={trainingModal}
      onClose={() => {
        setAdvancedDetails(false);
        setTrainingModal(false);
        setModelAugmentation(false);
        setActiveTab('model');
      }}
    >
      <>
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={
              mode === 'TRAINING' ? validationSchema : validationInferenceSchema
            }
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 'bold', fontSize: '18px' }}
                    >
                      {(profile?.role === 'organization_admin' ||
                        userFeatures?.includes('ADVANCEDSETTINGS')) && (
                        <>
                          {advancedDetails
                            ? 'Advance Details'
                            : 'Training Summary'}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  {advancedDetails && (
                    <>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center" // This will vertically center the item(s)
                      >
                        {/* Outer Stack for the "container" styling */}
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={1}
                          mb={1}
                        >
                          <Stack
                            direction="row"
                            spacing={0}
                            sx={{
                              backgroundColor: '#FFFFFF33',
                              borderRadius: 9999,
                              border: '1px solid #102633'
                            }}
                            justifyContent="space-between"
                          >
                            {/* Model Button */}
                            <ToggleButton
                              active={activeTab === 'model'}
                              onClick={() => {
                                setAdvancedDetails(true);
                                setActiveTab('model');
                                setModelAugmentation(false);
                              }}
                              sx={{
                                flex: 1 // So both buttons expand equally
                              }}
                            >
                              Model Details
                            </ToggleButton>

                            {/* Augmentation Button */}
                            <ToggleButton
                              active={activeTab === 'augmentation'}
                              onClick={() => {
                                setAdvancedDetails(true);
                                setActiveTab('augmentation');
                                setModelAugmentation(true);
                              }}
                              sx={{
                                flex: 1,
                                width: '300px'
                              }}
                            >
                              Augmentation Details
                            </ToggleButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {!modelAugmentation && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          Total Labeled Images
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          {objectAll?.labeled_count}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          Total no. of classes
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          {' '}
                          {
                            Object.keys(objectAll?.classname_counts || {})
                              .length
                          }{' '}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          Model Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="modelName"
                          type="text"
                          variant="outlined"
                          error={errors.modelName}
                        />
                      </Grid>
                    </>
                  )}

                  {advancedDetails && !modelAugmentation && (
                    <>
                      {mode == 'TRAINING' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 'bold' }}
                            >
                              Model Architecture
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} justifyContent="center">
                            {modelArchitectureList && (
                              <SelectInput
                                label="Select Model Architecture"
                                placeholder="Select Model Architecture"
                                variant="outlined"
                                width={'220px'}
                                name="classesType"
                                value={
                                  values.classesType || modelArchitectureList[1]
                                }
                                options={
                                  modelArchitectureList &&
                                  modelArchitectureList?.map(obj => ({
                                    label: obj,
                                    value: obj
                                  }))
                                }
                                onChange={value =>
                                  setFieldValue('classesType', value)
                                }
                              />
                            )}
                            {errors.classesType && touched.classesType && (
                              <Typography
                                color="error"
                                variant="caption"
                                style={{ marginLeft: '20px' }}
                              >
                                {errors.classesType}
                              </Typography>
                            )}
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Train Test Split
                        </Typography>
                        <Typography variant="caption" align="center">
                          (50 to 95)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="trainTestSplit"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Batch Size
                        </Typography>
                        <Typography variant="caption">(1 to 32)</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="batchSize"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Learning Rate
                        </Typography>
                        <Typography variant="caption">
                          (0.00001 to 0.1)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="learningRate"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          Epochs
                        </Typography>
                        <Typography variant="caption">(1 to 500)</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="epochs"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                  {advancedDetails && modelAugmentation && (
                    <>
                      <Grid container spacing={1} ml={1}>
                        {settings.map((item, index) => (
                          <Grid item xs={12} key={index}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Checkbox
                                checked={item.checked}
                                onChange={() =>
                                  handleAdvanceSettingChange(index)
                                }
                              />
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '16px'
                                }}
                              >
                                {item.label}
                              </Typography>
                              {/* <Tooltip title={`More info about ${item.label}`}> */}
                              <IconButton
                                size="small"
                                onClick={e => handleClickInfo(e, item.content)}
                              >
                                <img
                                  src={InfoIcon}
                                  style={{ height: '18px' }}
                                />
                              </IconButton>
                              {/* </Tooltip> */}
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                      {/* Popover that always appears below the icon */}
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom', // anchor below the icon
                          horizontal: 'center' // center horizontally relative to icon
                        }}
                        transformOrigin={{
                          vertical: 'top', // transform from the top of the popup
                          horizontal: 'center'
                        }}
                        PaperProps={{
                          sx: {
                            padding: 2,
                            maxWidth: 370 // Adjust as needed
                          }
                        }}
                      >
                        {popoverContent && (
                          <Box>
                            <Typography fontSize={'13px'} mb={1}>
                              {popoverContent.title}
                            </Typography>
                            {/* <Typography fontSize={'13px'} gutterBottom>
                              {popoverContent.description}
                            </Typography> */}
                            <Divider />
                            {/* Example images */}
                            {popoverContent?.original?.length > 0 && (
                              <>
                                <Grid container spacing={1} mb={2}>
                                  {popoverContent?.original.map(
                                    (example: any, idx: number) => (
                                      <Grid
                                        item
                                        xs={3} // each image takes 3 out of 12 columns -> 4 images per row
                                        key={idx}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start'
                                        }}
                                      >
                                        <Typography
                                          fontSize={'12px'}
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {example.label}
                                        </Typography>
                                        <Box
                                          component="img"
                                          src={example.imgSrc}
                                          alt={example.label}
                                          sx={{
                                            width: '66.27px',
                                            height: '66.27px',
                                            objectFit: 'contain',
                                            border: '1px solid #ccc',
                                            borderRadius: 1,
                                            mt: 0.5
                                          }}
                                        />
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                                <Divider />
                                <Grid container spacing={1}>
                                  {popoverContent.examples.map(
                                    (example: any, idx: number) => (
                                      <Grid
                                        item
                                        xs={3} // each image takes 3 out of 12 columns -> 4 images per row
                                        key={idx}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          fontSize={'12px'}
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {example.label}
                                        </Typography>
                                        <Box
                                          component="img"
                                          src={example.imgSrc}
                                          alt={example.label}
                                          sx={{
                                            width: '66.27px',
                                            height: '66.27px',
                                            objectFit: 'contain',
                                            border: '1px solid #ccc',
                                            borderRadius: 1,
                                            mt: 0.5
                                          }}
                                        />
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </>
                            )}
                          </Box>
                        )}
                      </Popover>
                    </>
                  )}
                  <Stack
                    direction="row"
                    justifyContent={'center'}
                    width={'100%'}
                    mt={2}
                    gap={'10px'}
                  >
                    <Button
                      version="light"
                      disabled={isSubmitting}
                      variant="outlined"
                      onClick={toggleAdvancedSettings}
                    >
                      {(profile?.role === 'organization_admin' ||
                        userFeatures?.includes('ADVANCEDSETTINGS')) && (
                        <>
                          {advancedDetails && !modelAugmentation
                            ? 'Back'
                            : advancedDetails && modelAugmentation
                            ? 'Previous'
                            : 'Advanced Settings'}
                        </>
                      )}
                    </Button>
                    {advancedDetails && modelAugmentation && (
                      <Button
                        version="light"
                        disabled={allSelected} // Change this as needed
                        variant="outlined"
                        onClick={handleSelectAll}
                      >
                        Select All
                      </Button>
                    )}
                    {advancedDetails && !modelAugmentation ? (
                      <Button
                        type="button"
                        disabled={isSubmitting || !values.modelName.trim()}
                        onClick={(e: any) => {
                          e.preventDefault();

                          setModelAugmentation(true);
                          setActiveTab('augmentation');
                        }}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button type="submit" disabled={isSubmitting}>
                        Start Training
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </>
    </Modal>
  );
};

export default TrainingModal;
