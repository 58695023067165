import React, { useState, useEffect, useRef } from 'react';

import Modal from 'components/Modal';
import {
  ConfigSchema,
  CreateProcessedDataRequestLabeledDataItem,
  CreateProcessedDataRequestSemilabeledDataItem,
  CreateProcessedDataRequestUnlabeledDataItem
} from 'schemas';
import {
  Stack,
  Typography,
  TextField,
  grid2Classes,
  Grid,
  CircularProgress
} from '@mui/material';
import { SelectInput } from 'components/Form';
import { ISelectInputOption } from 'components/Form/Inputs/SelectInput';
import { Button } from 'components/Button/Button';
import { useCreatePreProcessedObjects } from 'api/sdk';
import { useNavigate } from 'react-router-dom';
import { setSelectedConfiguration } from '../../store/configSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Env from 'config/env';
import { useWebSocket } from 'hooks';
import { useSnack } from 'plugins/snack';
interface configSelectionModalProps {
  configSelectionModalOpen: boolean;
  setConfigSelectionModalOpen: (data: boolean) => void;

  labeled_data: CreateProcessedDataRequestLabeledDataItem[];
  semilabeled_data: CreateProcessedDataRequestSemilabeledDataItem[];
  unlabelled_data: CreateProcessedDataRequestUnlabeledDataItem[];
  mode: string;
}

const ConfigSelectionModal: React.FC<configSelectionModalProps> = ({
  configSelectionModalOpen,
  setConfigSelectionModalOpen,
  mode,
  labeled_data,
  semilabeled_data,
  unlabelled_data
}) => {
  const [groupName, setGroupName] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [selectedConfig, setSelectedConfig] = useState<string>('');
  const [copyLoading, setCopyLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const configs = useSelector((state: RootState) => state.config.allConfigs);
  const snack = useSnack();

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );

  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    const pipeline_type = message?.pipeline_type;

    if (pipeline_type == 'quality_control_copy_data' && type == 'COMPLETED') {
      navigate(`/labelAssist1/${data.data_id}/null`);
      setConfigSelectionModalOpen(false);
    }
  };
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  const { mutateAsync: createObject, isLoading } =
    useCreatePreProcessedObjects();

  const createObjects = async (
    configid: string,
    group_name: string,
    labeled_data: CreateProcessedDataRequestLabeledDataItem[],
    semilabeled_data: CreateProcessedDataRequestSemilabeledDataItem[],
    unlabelled_data: CreateProcessedDataRequestUnlabeledDataItem[]
  ) => {
    const res = await createObject({
      configId: configid,
      data: {
        labeled_data: labeled_data,
        semilabeled_data: semilabeled_data,
        unlabeled_data: unlabelled_data
      },
      params: { group_name: group_name, mode: mode }
    });
    return res.data;
  };

  const options: ISelectInputOption[] = configs
    ? configs?.map(i => {
        return { label: i.name, value: i.id };
      })
    : [];
  return (
    <Modal
      open={configSelectionModalOpen}
      onClose={() => {
        setConfigSelectionModalOpen(false);
        setError(undefined);
        setGroupName('');
      }}
    >
      {!copyLoading && (
        <>
          {' '}
          <SelectInput
            name="configuration"
            variant="outlined"
            placeholder="Select Configuration"
            value={selectedConfig}
            options={options}
            onChange={e => {
              setSelectedConfig(e);
              dispatch(setSelectedConfiguration(e));
            }}
          />
          <Stack>
            <TextField
              name=""
              variant="outlined"
              placeholder="Enter Dataset name"
              size="small"
              fullWidth
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
            />
            {error && (
              <Typography variant="caption" sx={{ color: 'red' }}>
                {' '}
                {error}{' '}
              </Typography>
            )}
          </Stack>
          <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={2}>
            <Button
              version="light"
              onClick={() => {
                setConfigSelectionModalOpen(false);
                setError(undefined);
                setGroupName('');
              }}
            >
              {' '}
              cancel{' '}
            </Button>
            <Button
              loading={isLoading}
              disabled={!selectedConfig || groupName.length == 0 || isLoading}
              version="dark"
              onClick={() => {
                if (groupName.trim() == '') {
                  snack({
                    message: 'Please enter a valid Dataset name',
                    severity: 'error'
                  });
                  return;
                }
                createObjects(
                  selectedConfig,
                  groupName,
                  labeled_data,
                  semilabeled_data,
                  unlabelled_data
                )
                  .then(res => {
                    setCopyLoading(true);
                  })
                  .catch(error => {
                    setError(error.response.data.detail);
                    setTimeout(() => {
                      setError(undefined);
                    }, 3000);
                  });
              }}
            >
              {' '}
              save{' '}
            </Button>
          </Stack>
        </>
      )}

      {copyLoading && (
        <Stack spacing={2} alignItems={'center'}>
          <CircularProgress size={40} />

          <Typography> please wait till the data is being copied</Typography>
        </Stack>
      )}
    </Modal>
  );
};

export default ConfigSelectionModal;
