/* eslint-disable  */
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
// import {
//   ITableHeadColumn,
//   PageTitle,
//   Table,
//   Chat,
//   ChatMessage,
//   Card,
//   Button,
//   AppLoading
// } from 'components';
import { ChatMessage, Card, Button, Switch, RecordValue } from 'components';
import { ITableHeadColumn } from 'components';
import React, { useEffect, useState } from 'react';
import { FORMAT_DATE_TIME, dateToString, FORMAT_DATE } from 'utils';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useGetGroups, useListConfigurations } from 'api/sdk';
import moment from 'moment';
import { useSnack } from 'plugins/snack';
import { useDeleteConfiguration } from 'api/sdk';
import { useConfirm } from 'plugins/confirm';
import { useDispatch } from 'react-redux';
import AddconfigIcon from 'components/icons/Addconfig';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
// import { setAllConfigs } from 'store/configSlice';
import { SearchOutlined } from '@mui/icons-material';
import { ListTable } from 'components/ListTable';
import { CreateConfigModal } from './createConfigModal';
import { DropdownList } from './dropdownlist';
import grpUser from '../../assets/images/grpUser.svg';
import EditViewer from '../../assets/images/EditExpandable.svg';
import { RemoveUserModal } from './removeUserModel';
import { RemoveGroupModal } from './removeGrpModel';
import { GroupSchema } from 'schemas';

export const Group: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [deleteGrpModalOpen, setDeleteGrpModel] = React.useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState<
    RecordValue | RecordValue[]
  >([]);
  console.log('selectedFeatures', selectedFeatures);
  const snack = useSnack();
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.auth.profile);
  const [configName, setConfigName] = useState<string | undefined>('');

  const [GrpName, setGrpName] = useState<RecordValue | RecordValue[]>('');
  const [GrpShare, setGrpShare] = useState<RecordValue | RecordValue[]>(false);
  const [GrpId, setGrpId] = useState<RecordValue | RecordValue[]>('');
  const [editStatus, setEditStatus] = React.useState(false);
  const { isLoading, isError, refetch } = useGetGroups();
  const {
    mutateAsync,
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete
  } = useDeleteConfiguration();
  const confirm = useConfirm();
  const [searchTerm, setSearchTerm] = useState('');

  // const data = useSelector((state: RootState) => state.config.allConfigs);
  const [groupList, SetGroupList] = useState<GroupSchema[]>([]);
  const [rows, setRows] = useState<any>();
  const [columns, setColumns] = React.useState<ITableHeadColumn[]>([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  useEffect(() => {
    refetch().then(res => {
      res.data?.data &&
        // dispatch(setAllConfigs(res.data?.data));
        SetGroupList(res.data?.data);
    });
  }, [groupList]);
  const [toggleStates, setToggleStates] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleToggleChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setToggleStates(prevState => ({
        ...prevState, // Spread previous state to maintain other toggle states
        [id]: event.target.checked // Update the toggle state for the specific id
      }));

      console.log(`Toggle changed for record ${id}:`, event.target.checked);
    };
  const capitalizeFirstLetter = (text: string) => {
    if (!text) return '';
    return text?.charAt(0)?.toUpperCase() + text?.slice(1);
  };
  useEffect(() => {
    const updatedColumns: ITableHeadColumn[] = [
      {
        value: 'Group name',
        key: 'name',
        align: 'center',
        render: ({ value }) => {
          if (typeof value === 'string') {
            const truncatedName =
              value.length > 50 ? `${value.slice(0, 50)}...` : value;
            return (
              <Tooltip title={value.length > 50 ? value : ''}>
                <Typography
                  variant="subtitle2"
                  style={{
                    textAlign: 'center' // Ensures all text starts from the same point
                  }}
                >
                  {capitalizeFirstLetter(truncatedName)}
                </Typography>
              </Tooltip>
            );
          }
          return null;
        }
      },
      {
        value: 'Created Date',
        key: 'creator',
        align: 'center'
      },
      {
        value: '# of Users',
        key: 'userList',
        align: 'center'
      },
      {
        value: '# of Configurations',
        key: 'configCount',
        align: 'center'
      },
      {
        value: 'Global Share',
        key: 'globalShare',
        align: 'center',
        render: ({ value, record }) => {
          return (
            <>
              {record &&
                typeof record?.id === 'string' &&
                typeof record?.name === 'string' && (
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <IconButton
                      onClick={
                        () =>
                          record &&
                          typeof record?.id === 'string' &&
                          typeof record?.name === 'string' &&
                          console.log('Record', record)

                        // handleDeleteClick(record.id, record?.name)
                      }
                    > */}
                    <Switch
                      checked={
                        !!record?.globalShare
                        // || toggleStates[record.id]
                      }
                      // onChange={handleToggleChange(record.id)}
                      name={`toggleButton-${record.id}`}
                      color="primary"
                      size="small"
                      disabled={!record?.globalShare}
                    />
                    {/* </IconButton> */}
                  </Box>
                )}
            </>
          );
        }
      },
      {
        value: 'Action',
        key: 'actions',
        align: 'center',
        render: ({ value, record }) => {
          console.log('record?.GroupFeatureList', record?.GroupFeatureList);
          return (
            <>
              {
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    {' '}
                    <Tooltip title={'Manage Access'}>
                      <img
                        src={EditViewer}
                        onClick={() => {
                          setSelectedFeatures(record?.GroupFeatureList);
                          setGrpName(record?.name);
                          setGrpId(record?.id);
                          setGrpShare(record?.globalShare);
                          setEditStatus(true);
                          setCreateModalOpen(true);
                        }}
                      ></img>
                    </Tooltip>
                    {/* <Tooltip title={'Manage Viewer'}>
                      <img src={grpUser} alt="" />
                    </Tooltip> */}
                    <DeleteIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setDeleteGrpModel(true);
                        setGrpName(record?.name);
                        setGrpId(record?.id);
                      }}
                    />
                  </Stack>
                </Box>
              }
            </>
          );
        }
      }
    ];
    setColumns(updatedColumns);

    const updatedRows = groupList
      ?.filter((item: GroupSchema) => {
        // Check if searchTerm is valid
        const trimmedSearchTerm = searchTerm?.trim().toLowerCase();
        if (!trimmedSearchTerm) return true; // If no valid search term, include all rows

        // Filter rows based on the search term
        if (typeof item.name === 'string') {
          return item.name.toLowerCase().includes(trimmedSearchTerm);
        }
        return false; // Exclude rows without a valid 'name' field
      })
      .map((item: GroupSchema) => ({
        id: item?.id,
        name: item.name,

        creator: formatDateTime(
          item?.created_at && (item?.created_at as string)
        ),
        userList: item?.users?.length,
        configCount: item?.config_count,
        globalShare: item?.global_share,
        UserList: item?.users,
        GroupFeatureList: item?.features_list
      }));
    setRows(updatedRows);
    function formatDateTime(dateTimeString: string) {
      const dateObj = new Date(dateTimeString);

      // Extract components
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');

      // Format as mm-dd-yyyy hh:mm
      return `${month}-${day}-${year} ${hours}:${minutes}`;
    }
  }, [groupList, searchTerm]);
  let DataCount = groupList && groupList?.length;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Card>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              marginBottom={2}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  lineHeight: 2.5
                }}
              >
                Manage Groups and Users
              </Typography>
              <Button
                style={{ borderRadius: '6px' }}
                onClick={() => {
                  setConfigName('');
                  setCreateModalOpen(true);
                  setSearchTerm('');
                }}
              >
                <AddIcon sx={{ marginRight: '6px' }} /> Add Group
              </Button>
            </Stack>
            {/* Search Bar */}

            <ListTable
              columns={columns}
              rows={rows}
              searchVal={searchTerm}
              setSearchVal={setSearchTerm}
              expandedRowIndex={expandedRowIndex}
              TooltipName={'Manage User'}
              setExpandedRowIndex={setExpandedRowIndex}
              striped={true}
              loading={false}
              stickyHeader
              showSearch={true}
              expandedRowRender={record => {
                return (
                  <DropdownList
                    record={record as RecordValue | RecordValue[]}
                    refetch={refetch}
                    SetGroupList={SetGroupList} // Pass SetGroupList here
                  />
                );
              }}
            />
            {/* {DataCount === 0 && !isLoading && (
              <>
                <Grid container xs={12} style={{ height: '50vh' }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Stack direction="column">
                      <IconButton
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => setCreateModalOpen(true)}
                      >
                        <AddconfigIcon />
                      </IconButton>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Add Group
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )} */}
          </Card>
        </Grid>
      </Grid>
      <CreateConfigModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          setEditStatus(false);
        }}
        configName={configName}
        featuresList={selectedFeatures}
        GrpName={GrpName}
        GrpId={GrpId}
        editStatus={editStatus}
        GrpShare={GrpShare}
        onSucess={() => {
          setCreateModalOpen(false);
          setEditStatus(false);
          refetch().then(res => {
            res.data?.data && SetGroupList(res.data?.data);
          });
        }}
      />
      <RemoveGroupModal
        open={deleteGrpModalOpen}
        onClose={() => {
          setDeleteGrpModel(false);
        }}
        GrpName={GrpName}
        GrpId={GrpId}
        onSucess={() => {
          setDeleteGrpModel(false);
          refetch().then(res => {
            res.data?.data && SetGroupList(res.data?.data);
          });
        }}
      />
    </>
  );
};
