import { Box, Grid, Stack, Tooltip } from '@mui/material';
import { Card, Typography } from 'components';
import React from 'react';
import { GroupAnalyticsResponseSchema } from 'schemas';

interface IConfigDetailsProps {
  groupLevelDetails?: GroupAnalyticsResponseSchema;
  selectedValue?: string;
}

const ConfigDetails: React.FC<IConfigDetailsProps> = ({
  groupLevelDetails,
  selectedValue
}) => {
  const data = [
    {
      title: 'Models',
      trainedCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.models_complete_count,
      inProgressCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.models_inprogress_count,
      queuedCount: groupLevelDetails?.config_details?.models_queued_count || 0,
      failedCount: groupLevelDetails?.config_details?.models_failed_count || 0,
      trainedLabel: 'Trained Models',
      inProgressLabel: 'Models in Training',
      queuedLabel: 'Queued Models',
      failedLabel: 'Failed Models'
    },
    {
      title: 'Incremental Models',
      trainedCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.inc_models_complete_count,
      inProgressCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.inc_models_inprogress_count,
      queuedCount:
        groupLevelDetails?.config_details?.inc_models_queued_count || 0,
      failedCount:
        groupLevelDetails?.config_details?.inc_models_failed_count || 0,
      trainedLabel: 'Trained Incremental Models',
      inProgressLabel: 'Models in Training',
      queuedLabel: 'Queued Models',
      failedLabel: 'Failed Models'
    },
    {
      title: 'Recorded Inference',
      trainedCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.record_inferences_complete_count,
      inProgressCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.record_inferences_inprogress_count,
      queuedCount:
        groupLevelDetails?.config_details?.record_inferences_queued_count || 0,
      failedCount:
        groupLevelDetails?.config_details?.record_inferences_failed_count || 0,
      trainedLabel: 'Completed Inference',
      inProgressLabel: 'In-progress Inference',
      queuedLabel: 'Queued Inference',
      failedLabel: 'Failed Inference'
    },
    {
      title: 'Live Inferences',
      trainedCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.live_inferences_complete_count,
      inProgressCount:
        groupLevelDetails &&
        groupLevelDetails?.config_details?.live_inferences_inprogress_count,
      queuedCount:
        groupLevelDetails?.config_details?.live_inferences_queued_count || 0,
      failedCount:
        groupLevelDetails?.config_details?.live_inferences_failed_count || 0,
      trainedLabel: 'Completed Inference',
      inProgressLabel: 'In-progress Inference',
      queuedLabel: 'Queued Inference',
      failedLabel: 'Failed Inference'
    }
  ];
  return (
    <>
      <Card sx={{ p: 3 }} style={{ height: '100%' }}>
        <Typography
          variant="subtitle1"
          color="primary"
          fontWeight="bold"
          gutterBottom
        >
          Configuration Details
        </Typography>
        <Grid container mt={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent={
                selectedValue === 'self' ? 'flex-end' : 'space-between'
              }
              alignItems="center"
            >
              <>
                <Stack
                  direction="row"
                  spacing={0}
                  alignItems="center"
                  gap={1.5}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#61B4AE',
                      borderRadius: '50%'
                    }}
                  ></div>
                  <Typography
                    flexGrow={1}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginLeft: '10px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Queued
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={0}
                  alignItems="center"
                  gap={1.5}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#5694B8',
                      borderRadius: '50%'
                    }}
                  ></div>
                  <Typography
                    flexGrow={1}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginLeft: '10px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    In Progress
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={0}
                  alignItems="center"
                  gap={1.5}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#001E3C',
                      borderRadius: '50%'
                    }}
                  ></div>
                  <Typography
                    flexGrow={1}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginLeft: '10px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Completed
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={0}
                  alignItems="center"
                  gap={1.5}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#CC5834',
                      borderRadius: '50%'
                    }}
                  ></div>
                  <Typography
                    flexGrow={1}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginLeft: '10px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Failed
                  </Typography>
                </Stack>
              </>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="caption"
                component="div"
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                Total no. of Configurations :
                <span
                  style={{
                    fontSize: '28px',
                    fontWeight: 'bold',
                    color: '#1E4E6A',
                    textAlign: 'center',
                    marginTop: '10px',
                    marginLeft: '10px'
                  }}
                >
                  {groupLevelDetails &&
                    groupLevelDetails?.number_of_configurations}
                </span>
              </Typography>
              <></>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {data &&
            data?.map((item, index) => {
              const total =
                (item.trainedCount ?? 0) +
                (item.inProgressCount ?? 0) +
                (item.queuedCount ?? 0) +
                (item.failedCount ?? 0);

              return (
                <Grid item xs={12} md={6} key={index}>
                  <Stack spacing={2}>
                    {/* Title */}
                    <Typography variant="subtitle1" fontWeight="bold">
                      {item.title} :
                    </Typography>

                    {/* Progress Bar */}
                    <Box
                      sx={{
                        position: 'relative',
                        height: 26,
                        backgroundColor: '#E0E0E0',
                        overflow: 'hidden'
                      }}
                    >
                      {/* Trained */}
                      <Tooltip
                        title={`${item.trainedCount} ${item.trainedLabel}`}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: `${
                              ((item.trainedCount ?? 0) / total) * 100
                            }%`,
                            backgroundColor: '#102633',
                            left: 0
                          }}
                        />
                      </Tooltip>

                      {/* In Progress */}
                      <Tooltip
                        title={`${item.inProgressCount} ${item.inProgressLabel}`}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: `${
                              ((item.inProgressCount ?? 0) / total) * 100
                            }%`,
                            backgroundColor: '#5694B8',
                            left: `${((item.trainedCount ?? 0) / total) * 100}%` // Set based on Trained
                          }}
                        />
                      </Tooltip>

                      {/* Queued */}
                      <Tooltip
                        title={`${item.queuedCount} ${item.queuedLabel}`}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: `${
                              ((item.queuedCount ?? 0) / total) * 100
                            }%`,
                            backgroundColor: '#61B4AE',
                            left: `${
                              (((item.trainedCount ?? 0) +
                                (item.inProgressCount ?? 0)) /
                                total) *
                              100
                            }%` // Set based on Trained + In Progress
                          }}
                        />
                      </Tooltip>

                      {/* Failed */}
                      <Tooltip
                        title={`${item.failedCount} ${item.failedLabel}`}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: `${
                              ((item.failedCount ?? 0) / total) * 100
                            }%`,
                            backgroundColor: '#CC5834',
                            left: `${
                              (((item.trainedCount ?? 0) +
                                (item.inProgressCount ?? 0) +
                                (item.queuedCount ?? 0)) /
                                total) *
                              100
                            }%` // Set based on all previous sections
                          }}
                        />
                      </Tooltip>
                    </Box>

                    {/* Labels */}
                    <Stack direction="row" justifyContent="space-between">
                      <Box sx={{ color: '#102633' }}>
                        <Typography variant="h5" fontWeight="bold">
                          {item.trainedCount}
                        </Typography>
                        <Typography variant="caption">
                          {item.trainedLabel}
                        </Typography>
                      </Box>
                      <Box sx={{ color: '#5694B8' }}>
                        <Typography variant="h5" fontWeight="bold">
                          {item.inProgressCount}
                        </Typography>
                        <Typography variant="caption">
                          {item.inProgressLabel}
                        </Typography>
                      </Box>
                      <Box sx={{ color: '#61B4AE' }}>
                        <Typography variant="h5" fontWeight="bold">
                          {item.queuedCount}
                        </Typography>
                        <Typography variant="caption">
                          {item.queuedLabel}
                        </Typography>
                      </Box>
                      <Box sx={{ color: '#CC5834' }}>
                        <Typography variant="h5" fontWeight="bold">
                          {item.failedCount}
                        </Typography>
                        <Typography variant="caption">
                          {item.failedLabel}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
        </Grid>
      </Card>
    </>
  );
};

export default ConfigDetails;
