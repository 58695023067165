import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  Typography
} from 'components';
import React, { useRef, useState, useEffect, SetStateAction } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  LinearProgress,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Switch,
  Tooltip
} from '@mui/material';

import Reports from '../../assets/images/reportsQuality.svg';
import {
  ConfigSchema,
  CreateObjectSchema,
  ObjSchema,
  TrainingResultSchema
} from 'schemas';
import Inference from '../../assets/images/trainingImage.svg';
import { useWebSocket } from 'hooks';

import Env from 'config/env';
import { setSelectedConfiguration } from '../../store/configSlice';
import { useDispatch } from 'react-redux';
// import { useSaveFolderFiles } from 'api/sdk';
import { useGetTrainingResult } from '../../api/sdk';
import moment from 'moment';
import { ListTable } from 'components/ListTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import NoDataComponent from 'components/NoDataComponent';
import axios from 'axios';
import { useSnack } from 'plugins/snack';

interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  models: TrainingResultSchema[];
  setCurrentInferenceID: (config: string | undefined) => void;
  selectedModel: string | undefined;
  setSelectedModel: (config: string | undefined) => void;

  setViewMode: (data: boolean) => void;
  setStep: (data: number) => void;
  step: number;
  setIsIncrementl: (data: boolean) => void;
  setObjectsinModels: (data: string[]) => void;
  setDataIDModel: (data: string) => void;
}

export const Main: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  setCurrentInferenceID,
  setViewMode,
  setStep,
  setSelectedModel,
  models,
  setIsIncrementl,
  selectedModel,
  setObjectsinModels,
  step,
  setDataIDModel
}) => {
  // const { mutateAsync: saveFile } = useSaveFolderFiles();
  // const { data, refetch: refectchTrainingResults } = useGetTrainingResult({
  //   config_id: selectedConfig || ''
  // });

  const { data, refetch: refectchTrainingResults } = useGetTrainingResult(
    selectedConfig || ''
  );

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [statusValue, setStatus] = useState('QUEUED');
  const [advancedDetails, setAdvancedDetails] = useState(false);
  const [fromWebsocket, setFromWebsocket] = useState<any>();
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [deployModelOpen, setDeployModalOpen] = React.useState(false);

  const [proceedDisable, setProceedDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [rows, setRows] = useState<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = React.useState<any>([]);
  const profile = useSelector((state: RootState) => state.auth.profile);
  const token = useSelector((state: RootState) => state.auth.token);
  const snack = useSnack();

  const userFeatures = profile?.features_list || [];
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvancedDetails(event.target.checked);
  };
  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    if (
      message?.pipeline_type === 'quality_control_training' ||
      message?.pipeline_type === 'quality_control_incremental'
    ) {
      setFromWebsocket(data);
      setStatus(type);

      if (type == 'COMPLETED' || type == 'FAILED') {
        setIsIncrementl(false);
        refectchTrainingResults();
      }
    }
  };
  const fetchDeployModel = async () => {
    try {
      // Make the POST request with the correct arguments
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/configurations/${selectedConfig}/deploy?model_id=${selectedModel}`,
        {}, // Empty body (or add any body content if needed)
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      snack({
        message: 'Model has been deployed successfully',
        severity: 'success'
      });
      setDeployModalOpen(false);
      refectchTrainingResults();
    } catch (err) {
      // Handle the error in a more descriptive way
      console.error('Error fetching training result:', err);
    }
  };

  useEffect(() => {
    if (selectedConfig != '') {
      refectchTrainingResults().then(res => {});
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    const updatedcolumns: ITableHeadColumn[] = [
      {
        value: 'Model Name',
        key: 'ModelName',
        align: 'center'
      },
      {
        value: 'Base Model',
        key: 'baseModel',
        align: 'center'
      },
      {
        value: 'Model Architecture',
        key: 'ModelArchitecture',
        align: 'center'
      },
      {
        value: 'Training started at',
        key: 'Date',
        align: 'center'
      },
      {
        value: 'Total # Images',
        key: 'TotalImages',
        align: 'center'
      },
      {
        value: 'DataSet Name',
        key: 'dataSetName',
        align: 'center'
      },
      {
        value: 'Total # Classes',
        key: 'totalClasses',
        align: 'center'
      },

      {
        value: 'Train test split',
        key: 'Traintestsplit',
        align: 'center'
      },
      {
        value: 'Batch size',
        key: 'Batchsize',
        align: 'center'
      },
      {
        value: 'Learning Rate',
        key: 'Learningrate',
        align: 'center'
      },
      {
        value: 'Epochs',
        key: 'Epochs',
        align: 'center'
      },
      {
        value: 'Training Status',
        key: 'TrainingStatus',
        align: 'center',
        render: ({ value, record }) => {
          const trainingStatus = record?.TrainingStatus;
          if (trainingStatus === 'COMPLETED') {
            return (
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                Completed
              </Typography>
            );
          }
          if (trainingStatus === 'FAILED') {
            return (
              <Typography style={{ textAlign: 'center' }} variant="body2">
                Failed
              </Typography>
            );
          }
          if (trainingStatus === 'INPROGRESS') {
            return (
              <Typography style={{ textAlign: 'center' }} variant="body2">
                In Progress
              </Typography>
            );
          }

          if (trainingStatus === 'QUEUED') {
            return (
              <Typography style={{ textAlign: 'center' }} variant="body2">
                Queued
              </Typography>
            );
          }

          // If it's neither "COMPLETED" nor "QUEUED", show the progress bar

          return (
            <Box position="relative" display="inline-flex" ml={4}>
              <CircularProgress
                variant="determinate"
                value={typeof trainingStatus === 'number' ? trainingStatus : 0} // Ensure it's a number
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                  style={{ fontSize: '10px' }}
                >
                  {`${Math.round(trainingStatus as number)}%`}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
      {
        value: 'Test Accuracy',
        key: 'TestAccuracy'
      },
      {
        value: 'View Training Data',
        key: 'actions',
        render: ({ value, record }) => {
          return (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title="View Training Data">
                <IconButton
                  disabled={record.isDeleted != false}
                  onClick={() => {
                    if (record) {
                      setCurrentInferenceID(record.inferenceID as string);
                      setViewMode(true);
                      onChange(3);
                    }
                  }}
                >
                  <img
                    src={Reports}
                    alt="Report"
                    style={{ width: '30px', height: '30px' }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }
      }
    ];
    const filteredColumns = advancedDetails
      ? updatedcolumns
      : updatedcolumns.filter(
          column =>
            ![
              'Traintestsplit',
              'ModelArchitecture',
              'Batchsize',
              'Learningrate',
              'Epochs'
            ].includes(column.key)
        );
    setColumns(filteredColumns);
    function formatDateTime(dateTimeString: string) {
      const dateObj = new Date(dateTimeString);

      // Extract components
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');

      // Format as mm-dd-yyyy hh:mm
      return `${month}-${day}-${year} ${hours}:${minutes}`;
    }
    function formatDate(dateTimeString: string) {
      const dateObj = new Date(dateTimeString);

      // Extract components
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();

      // Format as mm-dd-yyyy hh:mm
      return `${month}-${day}-${year}`;
    }

    const Updatedrows =
      data?.data
        ?.filter((item: any) => {
          // Check if searchTerm is valid
          const trimmedSearchTerm = searchTerm?.trim().toLowerCase();
          if (!trimmedSearchTerm) return true; // If no valid search term, include all rows

          // Filter rows based on the search term
          if (typeof item.model_name === 'string') {
            return item.model_name.toLowerCase().includes(trimmedSearchTerm);
          }
          return false; // Exclude rows without a valid 'name' field
        })
        .map(item => ({
          ModelName: item.model_name,
          TotalImages: item?.total_image_count,
          dataSetName: item?.datasetname,
          totalClasses: item?.class_count || 0,
          ModelArchitecture: item?.model_architecture,
          Traintestsplit: item.train_spilt,
          Batchsize: item.batch_size,
          Learningrate: item.learning_rate,
          Epochs: item.total_epochs,
          Date: formatDateTime(item.training_started_at as string),
          TrainingStatus:
            fromWebsocket?.['Test accuracy'] &&
            fromWebsocket?.model_id === item.model_id
              ? 'COMPLETED' // Show "Completed" when data is received and inference_id matches
              : fromWebsocket?.progress !== undefined &&
                fromWebsocket?.model_id === item.model_id
              ? fromWebsocket.progress // Show progress if available and inference_id matches
              : item.status,

          TestAccuracy: item.test_accuracy
            ? (item.test_accuracy * 100).toFixed(2) + ' %'
            : 'N/A',

          inferenceID: item.data_id,
          baseModel: item?.old_model_name || '-',
          isDeleted: item?.is_deleted,
          isDeployed: item?.is_deployed,
          deployedAt: formatDate(item?.deployed_at as string)
        })) || [];
    setRows(Updatedrows);
  }, [data, searchTerm, advancedDetails, fromWebsocket]);

  const handleClose = () => {
    setCreateModalOpen(false);
  };

  const handleDeployClose = () => {
    setDeployModalOpen(false);
  };

  useEffect(() => {
    if (selectedModel) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
  }, [selectedModel]);

  return (
    <Card sx={{ height: '100%' }}>
      {configs && (
        <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
          <Typography
            style={{
              textAlign: 'left',
              font: "normal normal bold 18px/20px 'Proxima Nova', sans-serif",
              letterSpacing: '0.32px',
              color: '#000000',
              opacity: 1
            }}
          >
            Train Model
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {/* Toggle Button */}
            {configs &&
              configs.length > 0 &&
              selectedConfig != '' &&
              (profile?.role === 'organization_admin' ||
                userFeatures?.includes('ADVANCEDSETTINGS')) && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={advancedDetails}
                      onChange={handleToggleChange}
                      name="toggleButton"
                      color="primary"
                    />
                  }
                  label="Advanced Details"
                  style={{ marginLeft: '18px', marginRight: '30px' }}
                  labelPlacement="start"
                />
              )}

            <SelectInput
              label="Select Configuration"
              placeholder="Select or Search Configuration"
              variant="outlined"
              width={'250px'}
              name="configurationType"
              options={configs?.map(i => ({ label: i.name, value: i.id }))}
              value={selectedConfig || ''}
              onChange={(value: string) => {
                dispatch(setSelectedConfiguration(value));
              }}
            />
            {configs &&
              configs.length > 0 &&
              selectedConfig != '' &&
              (profile?.role === 'organization_admin' ||
                userFeatures?.includes('ADVANCEDSETTINGS')) && (
                <Button
                  version="dark"
                  onClick={() => {
                    setDeployModalOpen(true);
                    setSelectedModel('');
                    setIsIncrementl(true);
                  }}
                  style={{
                    width: '200px',
                    height: '45px',
                    borderRadius: '4px',
                    marginLeft: '10px'
                  }}
                >
                  {' '}
                  Deploy Model
                </Button>
              )}
          </div>
        </Stack>
      )}

      {selectedConfig != '' && rows && (
        <div
          style={{
            height: 'calc(100vh - 220px)'
          }}
        >
          <ListTable
            columns={columns}
            rows={rows}
            searchVal={searchTerm}
            setSearchVal={setSearchTerm}
            striped={true}
            loading={false}
            stickyHeader
            showSearch={true}
            selectedConfig={selectedConfig}
            tableContainerStyle={{ height: '82%' }}
            actionButtons={
              <>
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  style={{ width: '100%' }}
                  mt={2}
                  spacing={2}
                >
                  <Button
                    version="dark"
                    onClick={() => {
                      setViewMode(false);
                      setStep(2);
                      setSelectedModel('');
                      setIsIncrementl(false);
                    }}
                    style={{
                      width: '310px',
                      height: '45px',
                      borderRadius: '4px'
                    }}
                  >
                    {' '}
                    Start Training
                  </Button>
                  {(profile?.role === 'organization_admin' ||
                    userFeatures?.includes('INCTRAINING')) && (
                    <Button
                      version="dark"
                      onClick={() => {
                        setCreateModalOpen(true);
                        setSelectedModel('');
                        setIsIncrementl(true);
                      }}
                      style={{
                        width: '310px',
                        height: '45px',
                        borderRadius: '4px'
                      }}
                    >
                      {' '}
                      Start Incremental Training
                    </Button>
                  )}
                </Stack>
              </>
            }
          />
        </div>
      )}

      {configs && configs.length == 0 && (
        <Stack style={{ textAlign: 'center' }}>
          <Typography>Please add configuration to train a model</Typography>
        </Stack>
      )}

      {configs && configs.length > 0 && selectedConfig == '' && (
        <Stack
          style={{
            height: '41vh',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <NoDataComponent />
          <Typography style={{ color: '#707070', fontSize: '26px' }}>
            Select Configuration type to proceed with the process{' '}
          </Typography>
        </Stack>
      )}

      {errorMessage && (
        <Stack spacing={2} direction="column" alignItems="center">
          <Typography
            variant="body2"
            style={{ color: 'red', fontWeight: '16px' }}
          >
            {errorMessage}
          </Typography>
        </Stack>
      )}
      <Modal
        open={deployModelOpen}
        onClose={handleDeployClose}
        title="Select Model to Deploy"
        size="md"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {models && (
              <SelectInput
                label="Select Model"
                placeholder="Select Model"
                variant="outlined"
                width={'100%'}
                name="modelType"
                options={
                  models
                    ?.filter((model: any) => model?.status === 'COMPLETED')
                    ?.map(i => ({
                      label: String(i.model_name),
                      value: String(i.model_id)
                    })) || []
                }
                value={selectedModel}
                onChange={value => {
                  setSelectedModel(value);
                  const a = models.find(i => i.model_id === value)?.classnames;
                  const b = models.find(i => i.model_id === value);

                  setObjectsinModels(a || []);
                  setDataIDModel(b?.data_id || '');
                }}
              />
            )}
          </Grid>
        </Grid>

        {/* Display Error Message */}
        {errorMessage && (
          <Typography variant="caption" sx={{ color: 'red' }} component="div">
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </Typography>
        )}

        {/* Buttons for Proceed or Cancel */}
        <Grid item xs={12} mt={2} container justifyContent="center">
          <Button
            version="light"
            variant="outlined"
            onClick={handleDeployClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={proceedDisable}
            style={{ marginLeft: '8px' }}
            onClick={() => {
              fetchDeployModel();
            }}
          >
            Deploy
          </Button>
        </Grid>
      </Modal>

      {/* Modal */}
      <Modal
        open={createModalOpen}
        onClose={handleClose}
        title="Select Model to Start Incremental Training"
        size="md"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {models && (
              <SelectInput
                label="Select Model"
                placeholder="Select Model"
                variant="outlined"
                width={'100%'}
                name="modelType"
                options={
                  models
                    ?.filter((model: any) => model?.status === 'COMPLETED')
                    ?.map(i => ({
                      label: String(i.model_name),
                      value: String(i.model_id)
                    })) || []
                }
                value={selectedModel}
                onChange={value => {
                  setSelectedModel(value);
                  const a = models.find(i => i.model_id === value)?.classnames;
                  const b = models.find(i => i.model_id === value);

                  setObjectsinModels(a || []);
                  setDataIDModel(b?.data_id || '');
                }}
              />
            )}
          </Grid>
          <div style={{ padding: '8px 16px', width: '100%' }}>
            {selectedModel && (
              <div
                style={{ display: 'block', fontFamily: 'Proxima-Nova-Bold' }}
              >
                Classes in selected model :{' '}
              </div>
            )}
            <>
              <Grid
                container
                mt={1}
                style={{ maxHeight: '300px', overflow: 'auto' }}
              >
                {' '}
                {selectedModel &&
                  models
                    .find(i => i.model_id === selectedModel)
                    ?.classnames?.map((item, index) => {
                      return (
                        <Grid key={index} item xs={6}>
                          {index + 1}
                          {' : '}
                          {item}
                        </Grid>
                      );
                    })}
              </Grid>
            </>
          </div>
          {/* <div
            style={{
              width: '100%',
              textWrap: 'wrap'
            }}
          >
            {selectedModel &&
              models
                .find(i => i.model_id === selectedModel)
                ?.classnames?.join(',')}
          </div> */}
        </Grid>

        {/* Display Error Message */}
        {errorMessage && (
          <Typography variant="caption" sx={{ color: 'red' }} component="div">
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </Typography>
        )}

        {/* Buttons for Proceed or Cancel */}
        <Grid item xs={12} mt={2} container justifyContent="center">
          <Button version="light" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={proceedDisable}
            style={{ marginLeft: '8px' }}
            onClick={() => {
              setViewMode(false);
              setStep(2);
            }}
          >
            Proceed
          </Button>
        </Grid>
      </Modal>
    </Card>
  );
};
