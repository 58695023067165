/* eslint-disable */
import { Stack } from '@mui/material';
import { useDeleteMember } from 'api/sdk';
import { Button, Modal, RecordValue, Typography } from 'components';
import { useSnack } from 'plugins/snack';
import { Snack } from 'plugins/snack/Snack';
import React, { useState } from 'react';
interface IRemoveUserModalProps {
  open: boolean;
  onClose: () => void;
  onSucess?: () => void;
  UserName: RecordValue | RecordValue[];
  record: any | RecordValue[];
  UserId: RecordValue | RecordValue[];
}

export const RemoveUserModal: React.FC<IRemoveUserModalProps> = ({
  open,
  onClose,
  onSucess,
  UserName,
  record,
  UserId
}) => {
  const { mutateAsync: RemoveUser, isLoading: isEditLoading } =
    useDeleteMember();
  const snack = useSnack();
  const handRemoveUser = async () => {
    await RemoveUser({
      data: {
        user_id: String(UserId)
      }
    })
      .then(response => {
        snack({
          message: 'User Deleted successfully',
          severity: 'success'
        });
      })
      .catch(err => {
        if (err.response.status === 422) {
          Snack({
            message: 'User Failed Delete',
            severity: 'error'
          });
        }
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      size="md"
    >
      <>
        <Stack
          style={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography
            mt={3}
            mb={1}
            style={{ fontWeight: 'bold', fontSize: '16px' }}
          >
            Do you want to remove {UserName} from {record?.name} ?
          </Typography>
        </Stack>
        {/* Buttons */}
        <Stack
          mt={2}
          direction="row"
          spacing={2}
          width={'100%'}
          justifyContent={'center'}
        >
          <Button
            type="submit"
            variant="contained"
            version="light"
            onClick={() => {
              onClose();
            }}
          >
            No
          </Button>
          <Button
            type="submit"
            variant="contained"
            version="dark"
            onClick={async () => {
              await handRemoveUser();

              if (onSucess) {
                onSucess();
              }
            }}
          >
            Yes
          </Button>
        </Stack>
      </>
    </Modal>
  );
};
