import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Card, Typography, Box } from '@mui/material';
import { GroupAnalyticsResponseSchema } from 'schemas';

ChartJS.register(ArcElement, Tooltip, Legend);
interface IStorageDetailsProps {
  groupLevelDetails?: GroupAnalyticsResponseSchema;
}
const StorageDetails: React.FC<IStorageDetailsProps> = ({
  groupLevelDetails
}) => {
  // Dummy Data
  const data = {
    labels: [
      'Total Storage for RawData',
      'Total Storage for ProcessedData',
      'Total Storage for Models',
      'Others Size',
      'Available Storage'
    ],
    datasets: [
      {
        data: [
          parseFloat(
            String(
              groupLevelDetails?.storage_details?.total_storage_for_raw_data
            )
              ?.replace('GB', '')
              ?.replace('TB', '')
          ).toFixed(1) || '0.00',
          parseFloat(
            String(
              groupLevelDetails?.storage_details
                ?.total_storage_for_processed_data
            )
              ?.replace('GB', '')
              ?.replace('TB', '')
          ).toFixed(1) || '0.00',
          parseFloat(
            String(groupLevelDetails?.storage_details?.total_storage_for_model)
              ?.replace('GB', '')
              ?.replace('TB', '')
          ).toFixed(1) || '0.00',
          parseFloat(
            String(groupLevelDetails?.storage_details?.others_size)
              ?.replace('GB', '')
              ?.replace('TB', '')
          ).toFixed(1) || '0.00',
          parseFloat(
            String(groupLevelDetails?.storage_details?.available_storage)
              ?.replace('GB', '')
              ?.replace('TB', '')
          ).toFixed(1) || '0.00'
        ].map(Number), // Convert back to numbers to avoid string issues in charts
        backgroundColor: [
          '#001E3C',
          '#5694B8',
          '#007FFF',
          '#007194',
          '#B8E2FB'
        ], // Colors for each section
        hoverBackgroundColor: [
          '#003366',
          '#5A9BBF',
          '#0056B3',
          '#1989a8',
          '#A6D8F7'
        ] // Colors on hover
      }
    ]
  };

  return (
    <Card sx={{ p: 3, height: '100%' }}>
      <Typography
        variant="subtitle1"
        color="primary"
        fontWeight="bold"
        gutterBottom
      >
        Storage Details (Global)
      </Typography>
      <Grid container spacing={4} alignItems="center">
        {/* Chart Section */}
        <Grid
          item
          xs={12}
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            display="inline-flex"
            sx={{ position: 'relative', width: 250, height: 250 }}
          >
            <Doughnut
              style={{ position: 'relative', zIndex: 1 }} // Make sure the chart is on top
              data={data}
              options={{
                cutout: '70%', // Makes it a donut chart
                maintainAspectRatio: true,

                plugins: {
                  legend: {
                    display: false // This hides the legend
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        return `${label}: ${value} GB`;
                      }
                    }
                  }
                }
              }}
            />
            <Box
              top={0}
              left={0}
              right={0}
              bottom={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                zIndex: 0 // Set a lower z-index for the text overlay
              }}
            >
              <Stack direction="column" spacing={0} alignItems="center">
                <Typography
                  variant="caption"
                  component="div"
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                >
                  Total <br /> Storage
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  style={{
                    fontSize: '28px',
                    fontWeight: 'bold',
                    color: '#1E4E6A',
                    textAlign: 'center'
                  }}
                >
                  {(
                    parseFloat(
                      String(
                        groupLevelDetails?.storage_details?.total_storage ?? '0'
                      )
                    ) / 1000 || 0
                  ).toFixed(1)}{' '}
                  TB
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>

        {/* Legend Section */}
        <Grid item xs={12}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#001E3C'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Total Storage for RawData
              </Typography>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#1E4E6A',
                  alignItems: 'start'
                }}
              >
                {(
                  parseFloat(
                    String(
                      groupLevelDetails?.storage_details
                        ?.total_storage_for_raw_data
                    )
                  ) || 0
                ).toFixed(1)}{' '}
                GB
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#5694B8'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Total Storage for ProcessedData
              </Typography>
              <Typography sx={{ fontWeight: 'bold', color: '#1E4E6A' }}>
                {(
                  parseFloat(
                    String(
                      groupLevelDetails?.storage_details
                        ?.total_storage_for_processed_data
                    )
                  ) || 0
                ).toFixed(1)}{' '}
                GB
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: ' #007FFF'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Total Storage for Models
              </Typography>
              <Typography sx={{ fontWeight: 'bold', color: '#1E4E6A' }}>
                {(
                  parseFloat(
                    String(
                      groupLevelDetails?.storage_details
                        ?.total_storage_for_model
                    )
                  ) || 0
                ).toFixed(1)}{' '}
                GB
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#007194'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Others Size
              </Typography>
              <Typography sx={{ fontWeight: 'bold', color: '#1E4E6A' }}>
                {(
                  parseFloat(
                    String(groupLevelDetails?.storage_details?.others_size)
                  ) || 0
                ).toFixed(1)}
                GB
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#B8E2FB'
                }}
              ></div>
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Available Storage
              </Typography>
              <Typography sx={{ fontWeight: 'bold', color: '#1E4E6A' }}>
                {(
                  parseFloat(
                    String(
                      groupLevelDetails?.storage_details?.available_storage
                    )
                  ) || 0
                ).toFixed(1)}
                GB
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default StorageDetails;
