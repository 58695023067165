import { useState, useCallback, useEffect } from 'react';

export const useWebSocket = (url: string) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<
    'connecting' | 'open' | 'closed'
  >('connecting');

  const sendMessage = useCallback(
    (message: string) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      }
    },
    [socket]
  );

  const initializeSocket = useCallback(() => {
    setConnectionStatus('connecting');
    const newSocket = new WebSocket(url);
    setSocket(newSocket);
    return newSocket;
  }, [url]);

  const reconnect = useCallback(() => {
    if (connectionStatus === 'closed') {
      initializeSocket();
    }
  }, [connectionStatus]);

  useEffect(() => {
    const newSocket = initializeSocket();

    return () => {
      newSocket.close();
    };
  }, [initializeSocket]);

  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        setConnectionStatus('open');
        const pingInterval = setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send('ping');
          }
        }, 5000);

        socket.onclose = () => {
          clearInterval(pingInterval);
          setConnectionStatus('closed');
        };
      };

      socket.onerror = () => {
        setConnectionStatus('closed');
      };

      return () => {
        socket.close();
      };
    }
  }, [socket]);

  return {
    socket,
    connectionStatus,
    sendMessage,
    reconnect
  };
};

// import { useState, useCallback, useEffect, useRef } from 'react';

// export const useWebSocket = (url: string) => {
//   const [socket, setSocket] = useState<WebSocket | null>(null);
//   const [connectionStatus, setConnectionStatus] = useState<
//     'connecting' | 'open' | 'closed'
//   >('connecting');
//   const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
//   const pongTimeoutRef = useRef<NodeJS.Timeout | null>(null);

//   const sendMessage = useCallback(
//     (message: string) => {
//       if (socket && socket.readyState === WebSocket.OPEN) {
//         socket.send(message);
//       }
//     },
//     [socket]
//   );

//   const initializeSocket = useCallback(() => {
//     setConnectionStatus('connecting');
//     const newSocket = new WebSocket(url);

//     newSocket.onopen = () => {
//       console.log('WebSocket connection established');
//       setConnectionStatus('open');

//       // Set up ping interval
//       if (pingIntervalRef.current) {
//         clearInterval(pingIntervalRef.current);
//       }

//       pingIntervalRef.current = setInterval(() => {
//         if (newSocket.readyState === WebSocket.OPEN) {
//           newSocket.send('ping');

//           // Set a timeout to check if pong is received
//           pongTimeoutRef.current = setTimeout(() => {
//             console.warn('Pong not received, reconnecting...');
//             reconnect();
//           }, 3000);
//         }
//       }, 5000);
//     };

//     newSocket.onmessage = event => {
//       if (event.data === 'pong') {
//         console.log('Received pong');
//         if (pongTimeoutRef.current) {
//           clearTimeout(pongTimeoutRef.current);
//         }
//       }
//     };

//     newSocket.onclose = event => {
//       console.log('WebSocket connection closed', event);
//       if (pingIntervalRef.current) {
//         clearInterval(pingIntervalRef.current);
//       }
//       setConnectionStatus('closed');
//     };

//     newSocket.onerror = error => {
//       console.error('WebSocket error:', error);
//       setConnectionStatus('closed');
//     };

//     setSocket(newSocket);
//     return newSocket;
//   }, [url]);

//   const reconnect = useCallback(() => {
//     if (socket) {
//       socket.close();
//     }

//     if (pingIntervalRef.current) {
//       clearInterval(pingIntervalRef.current);
//     }

//     initializeSocket();
//   }, [initializeSocket, socket]);

//   useEffect(() => {
//     const newSocket = initializeSocket();

//     return () => {
//       if (pingIntervalRef.current) {
//         clearInterval(pingIntervalRef.current);
//       }
//       if (newSocket && newSocket.readyState !== WebSocket.CLOSED) {
//         newSocket.close();
//       }
//     };
//   }, [initializeSocket]);

//   return {
//     socket,
//     connectionStatus,
//     sendMessage,
//     reconnect
//   };
// };
