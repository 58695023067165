import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { Button } from 'components/Button/Button';
import { useDeleteDirectoryFileManagerDirectoriesDelete } from 'api/media';
import { DirectoriesContents, Contents } from 'schemasMedia';
import Modal from 'components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface DeleteModalProps {
  openDeleteModal: boolean;
  onClose: (data: boolean) => void;
  currentDirectory: string;
  selectedFiles: Contents[];
  setSelectedFiles: (file: Contents[]) => void;
  setFiles: (Data: Contents[]) => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  openDeleteModal,
  onClose,
  currentDirectory,
  selectedFiles,
  setSelectedFiles,
  setFiles
}) => {
  const { mutateAsync: deleteFiles, isLoading } =
    useDeleteDirectoryFileManagerDirectoriesDelete();
  const user_id = useSelector((state: RootState) => state.auth.profile?.id);
  const group_id = useSelector(
    (state: RootState) => state.auth.profile?.group_id || '/'
  );

  function determineFileType(selectedFiles: Contents[]): string {
    if (selectedFiles.length > 1) {
      if (selectedFiles.every(file => file.type === 'directory')) {
        return 'folders';
      }

      if (
        selectedFiles.every(file =>
          ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(
            file.type || ''
          )
        )
      ) {
        return 'images';
      }

      return 'files';
    } else {
      if (selectedFiles.every(file => file.type === 'directory')) {
        return 'folder';
      }

      if (
        selectedFiles.every(file =>
          ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(
            file.type || ''
          )
        )
      ) {
        return 'image';
      }
      return 'file';
    }
  }

  return (
    <Modal
      onClose={() => onClose(false)}
      open={openDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Typography sx={{ textAlign: 'center' }}>
          Are you sure that you want to delete the selected{' '}
          {determineFileType(selectedFiles)}?
        </Typography>
        <Stack direction={'row'} justifyContent={'center'} spacing={1} mt={3}>
          <Button
            version="light"
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            onClick={() => {
              selectedFiles != undefined &&
                deleteFiles({
                  data: selectedFiles.map(i => i.path as string),
                  params: {
                    current_directory: `${group_id}/${user_id}/${currentDirectory}`
                  }
                }).then(res => {
                  setFiles(res.data.contents);
                  setSelectedFiles([]);
                  onClose(false);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </>
    </Modal>
  );
};

export default DeleteModal;
