import {
  Autocomplete,
  MenuItem,
  Select,
  Skeleton,
  styled,
  TextField
} from '@mui/material';
import React, { useMemo } from 'react';

import { BaseInput, IBaseInputProps } from '../BaseInput';

export type ISelectValueType = string;

export interface ISelectInputOption {
  value?: ISelectValueType;
  label?: string;
}

export interface ISelectInputProps extends IBaseInputProps {
  options: ISelectInputOption[];
  value?: ISelectValueType | null;
  onChange?: (e: ISelectValueType) => void;
  light?: boolean;
  disabled?: boolean;
  defaultValue?: ISelectValueType;
  placeholder?: string;
  required?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  width?: string | number;
  maxWidth?: string | number;
  loading?: boolean;
}

const StyledSelect = styled(Select)<
  Pick<ISelectInputProps, 'variant' | 'width' | 'maxWidth'>
>(({ theme, variant, width, maxWidth }) => ({
  width: width || '100%',
  maxWidth: maxWidth || '100%',
  marginTop: '5px',
  minWidth: '150px',
  '&.MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiList-root': {
    borderRadius: '0px'
  },
  ...(variant === 'outlined' && {
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #102633',
    backgroundColor: 'white',
    '& .MuiPaper-rounded': {
      borderRadius: '0px'
    }
  }),
  ...(variant === 'standard' && {
    boxShadow: '4px 1px 10px 2px grey'
  })
}));

const StyledAutocomplete = styled(Autocomplete)<
  Pick<ISelectInputProps, 'width' | 'maxWidth'>
>(({ width, maxWidth }) => ({
  width: width || '100%',
  maxWidth: maxWidth || '100%',
  marginTop: '5px',
  minWidth: '150px',
  backgroundColor: 'white',
  border: '1px solid #102633'
}));
export const SelectInput: React.FC<ISelectInputProps> = ({
  id,
  name,
  label,
  options,
  placeholder,
  disabled = false,
  loading,
  error,
  helperText,
  required,
  value,
  onChange,
  defaultValue,
  light,
  variant = 'standard',
  width,
  maxWidth
}) => {
  const inputId = useMemo(() => id || name, [id, name]);

  return (
    <BaseInput
      id={id}
      name={name}
      label={label}
      required={required}
      helperText={helperText}
      error={error}
      light={light}
      width={width}
      fullwidth={!width}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={40} width={width || '100%'} />
      ) : (
        // <StyledSelect
        //   name={name}
        //   id={inputId}
        //   disabled={disabled}
        //   defaultValue={defaultValue}
        //   error={!!error}
        //   required={required}
        //   value={value}
        //   placeholder={placeholder}
        //   onChange={e => onChange && onChange(String(e.target.value))}
        //   variant={variant}
        //   size="small"
        //   label={variant === 'outlined' ? label : undefined}
        //   width={width}
        //   maxWidth={maxWidth}
        //   MenuProps={{
        //     PaperProps: {
        //       style: {
        //         maxHeight: 300 // Set your desired max height here
        //       }
        //     }
        //   }}
        // >
        //   {options.map(option => (
        //     <MenuItem key={option.value} value={option.value}>
        //       {option.label}
        //     </MenuItem>
        //   ))}
        // </StyledSelect>
        <StyledAutocomplete
          id={inputId}
          options={options as ISelectInputOption[]} // ✅ Explicitly cast options
          getOptionLabel={(option: any) => option.label || ''} // ✅ Type is now correct
          value={
            options.find(opt => opt?.value?.trim() === value?.trim()) || null
          }
          onChange={(_, newValue) =>
            onChange &&
            onChange((newValue as ISelectInputOption)?.value?.trim() || '')
          }
          disabled={disabled}
          clearOnEscape={false} // Prevent clear on escape
          disableClearable={true} // Prevent clear button
          renderInput={params => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
              size="small"
              onBlur={e => (e.target.value = e.target.value.trim())} // ✅ Prevent spaces on blur
            />
          )}
        />
      )}
    </BaseInput>
  );
};

export default SelectInput;
