/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';
import type {
  AdminRegisterRequestSchema,
  AvailableSitesResponseSchema,
  CameraSchema,
  ChangePasswordRequestSchema,
  ChangeSiteRequestSchema,
  ConfigSchema,
  CreateAdminSiteResponseSchema,
  CreateCameraRequestSchema,
  CreateConfigurationRequestSchema,
  CreateGroupRequestSchema,
  CreateObjectSchema,
  CreatePreProcessedObjectsParams,
  CreateProcessedDataRequest,
  Createobjectresponseschema,
  DataDistributationResultSchema,
  DeleteGroupParams,
  DeleteInferenceParams,
  DeleteObjectDataIdParams,
  DeleteObjectObjectIdParams,
  DeletePreprocessedData200,
  DeployModel200,
  DeployModelParams,
  ForgotPasswordRequestSchema,
  GetAllObjectsParams,
  GetAllObjectsProcessedDataParams,
  GetInferenceDataDistributionParams,
  GetInferenceResultParams,
  GetNoveltyDetailsParams,
  GetOrganisationSitesParams,
  GetTrainingInferenceDetailsParams,
  GroupAnalyticsParams,
  GroupAnalyticsResponseSchema,
  GroupSchema,
  GroupsAnalyticsResponseSchema,
  HTTPValidationError,
  InferenceResultSchema,
  InferenceSchema,
  LabelAsisitRequestQualityControl,
  LabelAsisitRequestQualityControlIncremental,
  LabelAsisitRequestQualityControlInference,
  LabelAsisitRequestQualityControlnovelty,
  ListConfigurationsParams,
  LoginResponseSchema,
  MemberDeleteRequestSchema,
  MemberRegisterRequestSchema,
  ModelArchitectureResponse,
  NoveltySchema,
  ObjResponseSchema,
  ObjResponseSchemaCount,
  ObjResponseSchemas,
  OrganisationValidateRequestSchema,
  OrganisationValidateResponseSchema,
  ProfileSchema,
  QueueRequest,
  QueueStatus200,
  ResetPasswordRequestSchema,
  StartIncremental200,
  StartInference200,
  StartLiveRequestSchema,
  StartNovelty200,
  StartTraining200,
  SyncObjectsRequest,
  TrainingInferenceDetailsSchema,
  TrainingResultSchema,
  UpdateCameraParams,
  UpdateConfigurationParams,
  UpdateFeatureListRequest,
  UserLoginRequestSchema,
  UserProfileSchema,
  VerifyAdminRegisterRequestSchema,
  VerifyTokenRequest
} from '../schemas';
import { apiAxiosInstanceFn } from './apiAxiosInstance';
import type { ErrorType } from './apiAxiosInstance';

/**
 * Login Endpoint
 * @summary Login
 */
export const login = (userLoginRequestSchema: UserLoginRequestSchema) => {
  return apiAxiosInstanceFn<LoginResponseSchema>({
    url: `/auth/login`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userLoginRequestSchema
  });
};

export const getLoginMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: UserLoginRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: UserLoginRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof login>>,
    { data: UserLoginRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return login(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof login>>
>;
export type LoginMutationBody = UserLoginRequestSchema;
export type LoginMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Login
 */
export const useLogin = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: UserLoginRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Register Request Endpoint
 * @summary Register Request
 */
export const registerAdmin = (
  adminRegisterRequestSchema: AdminRegisterRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/register-admin`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: adminRegisterRequestSchema
  });
};

export const getRegisterAdminMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerAdmin>>,
    TError,
    { data: AdminRegisterRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerAdmin>>,
  TError,
  { data: AdminRegisterRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerAdmin>>,
    { data: AdminRegisterRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return registerAdmin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterAdminMutationResult = NonNullable<
  Awaited<ReturnType<typeof registerAdmin>>
>;
export type RegisterAdminMutationBody = AdminRegisterRequestSchema;
export type RegisterAdminMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Register Request
 */
export const useRegisterAdmin = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerAdmin>>,
    TError,
    { data: AdminRegisterRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getRegisterAdminMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Register Request Member Endpoint
 * @summary Register Request Member
 */
export const registerMember = (
  memberRegisterRequestSchema: MemberRegisterRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/register-member`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: memberRegisterRequestSchema
  });
};

export const getRegisterMemberMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerMember>>,
    TError,
    { data: MemberRegisterRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerMember>>,
  TError,
  { data: MemberRegisterRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerMember>>,
    { data: MemberRegisterRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return registerMember(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof registerMember>>
>;
export type RegisterMemberMutationBody = MemberRegisterRequestSchema;
export type RegisterMemberMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Register Request Member
 */
export const useRegisterMember = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerMember>>,
    TError,
    { data: MemberRegisterRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getRegisterMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Verify Admin Endpoint
 * @summary Verify Admin
 */
export const verifyAdminRegister = (
  verifyAdminRegisterRequestSchema: VerifyAdminRegisterRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/verify-admin-register`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyAdminRegisterRequestSchema
  });
};

export const getVerifyAdminRegisterMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyAdminRegister>>,
    TError,
    { data: VerifyAdminRegisterRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof verifyAdminRegister>>,
  TError,
  { data: VerifyAdminRegisterRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verifyAdminRegister>>,
    { data: VerifyAdminRegisterRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return verifyAdminRegister(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyAdminRegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof verifyAdminRegister>>
>;
export type VerifyAdminRegisterMutationBody = VerifyAdminRegisterRequestSchema;
export type VerifyAdminRegisterMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Verify Admin
 */
export const useVerifyAdminRegister = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyAdminRegister>>,
    TError,
    { data: VerifyAdminRegisterRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getVerifyAdminRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Verify token Endpoint
 * @summary Verify token
 */
export const verifyToken = (verifyTokenRequest: VerifyTokenRequest) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/verify-token`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyTokenRequest
  });
};

export const getVerifyTokenMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyToken>>,
    TError,
    { data: VerifyTokenRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof verifyToken>>,
  TError,
  { data: VerifyTokenRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verifyToken>>,
    { data: VerifyTokenRequest }
  > = props => {
    const { data } = props ?? {};

    return verifyToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof verifyToken>>
>;
export type VerifyTokenMutationBody = VerifyTokenRequest;
export type VerifyTokenMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Verify token
 */
export const useVerifyToken = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyToken>>,
    TError,
    { data: VerifyTokenRequest },
    TContext
  >;
}) => {
  const mutationOptions = getVerifyTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Change Password Endpoint
 * @summary Change Password
 */
export const changePassword = (
  changePasswordRequestSchema: ChangePasswordRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/change-password`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: changePasswordRequestSchema
  });
};

export const getChangePasswordMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changePassword>>,
  TError,
  { data: ChangePasswordRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changePassword>>,
    { data: ChangePasswordRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return changePassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof changePassword>>
>;
export type ChangePasswordMutationBody = ChangePasswordRequestSchema;
export type ChangePasswordMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Change Password
 */
export const useChangePassword = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get User Profile
 * @summary Get Profile
 */
export const getProfile = (signal?: AbortSignal) => {
  return apiAxiosInstanceFn<ProfileSchema>({
    url: `/auth/profile`,
    method: 'GET',
    signal
  });
};

export const getGetProfileQueryKey = () => {
  return [`/auth/profile`] as const;
};

export const getGetProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getProfile>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getProfile>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfile>>> = ({
    signal
  }) => getProfile(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProfile>>
>;
export type GetProfileQueryError = ErrorType<unknown>;

/**
 * @summary Get Profile
 */
export const useGetProfile = <
  TData = Awaited<ReturnType<typeof getProfile>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getProfile>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get Viewer Profile
 * @summary Get viewer profile
 */
export const getViewerProfile = (signal?: AbortSignal) => {
  return apiAxiosInstanceFn<UserProfileSchema[]>({
    url: `/auth/viewer_profilelist`,
    method: 'GET',
    signal
  });
};

export const getGetViewerProfileQueryKey = () => {
  return [`/auth/viewer_profilelist`] as const;
};

export const getGetViewerProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getViewerProfile>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getViewerProfile>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetViewerProfileQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getViewerProfile>>
  > = ({ signal }) => getViewerProfile(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getViewerProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetViewerProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getViewerProfile>>
>;
export type GetViewerProfileQueryError = ErrorType<unknown>;

/**
 * @summary Get viewer profile
 */
export const useGetViewerProfile = <
  TData = Awaited<ReturnType<typeof getViewerProfile>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getViewerProfile>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetViewerProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Forgot Password
 * @summary Forgot Password
 */
export const forgotPassword = (
  forgotPasswordRequestSchema: ForgotPasswordRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/forgot-password`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: forgotPasswordRequestSchema
  });
};

export const getForgotPasswordMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof forgotPassword>>,
  TError,
  { data: ForgotPasswordRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forgotPassword>>,
    { data: ForgotPasswordRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return forgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof forgotPassword>>
>;
export type ForgotPasswordMutationBody = ForgotPasswordRequestSchema;
export type ForgotPasswordMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Forgot Password
 */
export const useForgotPassword = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Reset Password Endpoint
 * @summary Reset Password
 */
export const resetPassword = (
  resetPasswordRequestSchema: ResetPasswordRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/reset-password`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: resetPasswordRequestSchema
  });
};

export const getResetPasswordMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetPassword>>,
  TError,
  { data: ResetPasswordRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { data: ResetPasswordRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetPassword>>
>;
export type ResetPasswordMutationBody = ResetPasswordRequestSchema;
export type ResetPasswordMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Reset Password
 */
export const useResetPassword = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List of Available Sites
 * @summary Available Sites
 */
export const availableSites = (signal?: AbortSignal) => {
  return apiAxiosInstanceFn<AvailableSitesResponseSchema[]>({
    url: `/auth/available_sites`,
    method: 'GET',
    signal
  });
};

export const getAvailableSitesQueryKey = () => {
  return [`/auth/available_sites`] as const;
};

export const getAvailableSitesQueryOptions = <
  TData = Awaited<ReturnType<typeof availableSites>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof availableSites>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAvailableSitesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof availableSites>>> = ({
    signal
  }) => availableSites(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof availableSites>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AvailableSitesQueryResult = NonNullable<
  Awaited<ReturnType<typeof availableSites>>
>;
export type AvailableSitesQueryError = ErrorType<unknown>;

/**
 * @summary Available Sites
 */
export const useAvailableSites = <
  TData = Awaited<ReturnType<typeof availableSites>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof availableSites>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAvailableSitesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Change Site Endpoint
 * @summary Change Site
 */
export const changeSite = (
  changeSiteRequestSchema: ChangeSiteRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/change_site`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: changeSiteRequestSchema
  });
};

export const getChangeSiteMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeSite>>,
    TError,
    { data: ChangeSiteRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeSite>>,
  TError,
  { data: ChangeSiteRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeSite>>,
    { data: ChangeSiteRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return changeSite(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeSiteMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeSite>>
>;
export type ChangeSiteMutationBody = ChangeSiteRequestSchema;
export type ChangeSiteMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Change Site
 */
export const useChangeSite = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeSite>>,
    TError,
    { data: ChangeSiteRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getChangeSiteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete Request Member Endpoint
 * @summary Delete Request Member
 */
export const deleteMember = (
  memberDeleteRequestSchema: MemberDeleteRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/auth/delete-member`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: memberDeleteRequestSchema
  });
};

export const getDeleteMemberMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMember>>,
    TError,
    { data: MemberDeleteRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMember>>,
  TError,
  { data: MemberDeleteRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMember>>,
    { data: MemberDeleteRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return deleteMember(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMember>>
>;
export type DeleteMemberMutationBody = MemberDeleteRequestSchema;
export type DeleteMemberMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Request Member
 */
export const useDeleteMember = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMember>>,
    TError,
    { data: MemberDeleteRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Validate Organisation Code
 * @summary Validate Organisation
 */
export const validateOrganisation = (
  organisationValidateRequestSchema: OrganisationValidateRequestSchema
) => {
  return apiAxiosInstanceFn<OrganisationValidateResponseSchema>({
    url: `/organisations/validate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: organisationValidateRequestSchema
  });
};

export const getValidateOrganisationMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateOrganisation>>,
    TError,
    { data: OrganisationValidateRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateOrganisation>>,
  TError,
  { data: OrganisationValidateRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateOrganisation>>,
    { data: OrganisationValidateRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return validateOrganisation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidateOrganisationMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateOrganisation>>
>;
export type ValidateOrganisationMutationBody =
  OrganisationValidateRequestSchema;
export type ValidateOrganisationMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Validate Organisation
 */
export const useValidateOrganisation = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateOrganisation>>,
    TError,
    { data: OrganisationValidateRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getValidateOrganisationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get All Sites of an Organisation by token. (Applicable for Admin regiser page only)
 * @summary Get Organisation Sites
 */
export const getOrganisationSites = (
  params: GetOrganisationSitesParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<CreateAdminSiteResponseSchema[]>({
    url: `/sites/get-organisation-sites`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetOrganisationSitesQueryKey = (
  params: GetOrganisationSitesParams
) => {
  return [
    `/sites/get-organisation-sites`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetOrganisationSitesQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganisationSites>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetOrganisationSitesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrganisationSites>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOrganisationSitesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrganisationSites>>
  > = ({ signal }) => getOrganisationSites(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganisationSites>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrganisationSitesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrganisationSites>>
>;
export type GetOrganisationSitesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Organisation Sites
 */
export const useGetOrganisationSites = <
  TData = Awaited<ReturnType<typeof getOrganisationSites>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetOrganisationSitesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrganisationSites>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganisationSitesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Groups Analytics Details Endpoint
 * @summary groups_analytics
 */
export const groupsAnalytics = (signal?: AbortSignal) => {
  return apiAxiosInstanceFn<GroupsAnalyticsResponseSchema>({
    url: `/dashboard/groups_analytics`,
    method: 'GET',
    signal
  });
};

export const getGroupsAnalyticsQueryKey = () => {
  return [`/dashboard/groups_analytics`] as const;
};

export const getGroupsAnalyticsQueryOptions = <
  TData = Awaited<ReturnType<typeof groupsAnalytics>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof groupsAnalytics>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsAnalyticsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsAnalytics>>> = ({
    signal
  }) => groupsAnalytics(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof groupsAnalytics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GroupsAnalyticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsAnalytics>>
>;
export type GroupsAnalyticsQueryError = ErrorType<unknown>;

/**
 * @summary groups_analytics
 */
export const useGroupsAnalytics = <
  TData = Awaited<ReturnType<typeof groupsAnalytics>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof groupsAnalytics>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGroupsAnalyticsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Group Analytics Details Endpoint
 * @summary group_analytics
 */
export const groupAnalytics = (
  params: GroupAnalyticsParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<GroupAnalyticsResponseSchema>({
    url: `/dashboard/group_analytics`,
    method: 'GET',
    params,
    signal
  });
};

export const getGroupAnalyticsQueryKey = (params: GroupAnalyticsParams) => {
  return [`/dashboard/group_analytics`, ...(params ? [params] : [])] as const;
};

export const getGroupAnalyticsQueryOptions = <
  TData = Awaited<ReturnType<typeof groupAnalytics>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GroupAnalyticsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupAnalytics>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupAnalyticsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupAnalytics>>> = ({
    signal
  }) => groupAnalytics(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof groupAnalytics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GroupAnalyticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupAnalytics>>
>;
export type GroupAnalyticsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary group_analytics
 */
export const useGroupAnalytics = <
  TData = Awaited<ReturnType<typeof groupAnalytics>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GroupAnalyticsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupAnalytics>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGroupAnalyticsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create group
 * @summary Create group
 */
export const createGroup = (
  createGroupRequestSchema: CreateGroupRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/users/create_group`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createGroupRequestSchema
  });
};

export const getCreateGroupMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGroup>>,
    TError,
    { data: CreateGroupRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createGroup>>,
  TError,
  { data: CreateGroupRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createGroup>>,
    { data: CreateGroupRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return createGroup(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof createGroup>>
>;
export type CreateGroupMutationBody = CreateGroupRequestSchema;
export type CreateGroupMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create group
 */
export const useCreateGroup = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGroup>>,
    TError,
    { data: CreateGroupRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getCreateGroupMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get group
 * @summary get groups
 */
export const getGroups = (signal?: AbortSignal) => {
  return apiAxiosInstanceFn<GroupSchema[]>({
    url: `/users/get_groups`,
    method: 'GET',
    signal
  });
};

export const getGetGroupsQueryKey = () => {
  return [`/users/get_groups`] as const;
};

export const getGetGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getGroups>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGroupsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroups>>> = ({
    signal
  }) => getGroups(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGroups>>
>;
export type GetGroupsQueryError = ErrorType<unknown>;

/**
 * @summary get groups
 */
export const useGetGroups = <
  TData = Awaited<ReturnType<typeof getGroups>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update features_list
 * @summary Update features_list
 */
export const featuresList = (
  updateFeatureListRequest: UpdateFeatureListRequest
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/users/features_list`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateFeatureListRequest
  });
};

export const getFeaturesListMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresList>>,
    TError,
    { data: UpdateFeatureListRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof featuresList>>,
  TError,
  { data: UpdateFeatureListRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof featuresList>>,
    { data: UpdateFeatureListRequest }
  > = props => {
    const { data } = props ?? {};

    return featuresList(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type FeaturesListMutationResult = NonNullable<
  Awaited<ReturnType<typeof featuresList>>
>;
export type FeaturesListMutationBody = UpdateFeatureListRequest;
export type FeaturesListMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update features_list
 */
export const useFeaturesList = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featuresList>>,
    TError,
    { data: UpdateFeatureListRequest },
    TContext
  >;
}) => {
  const mutationOptions = getFeaturesListMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * delete  group
 * @summary delete group
 */
export const deleteGroup = (params: DeleteGroupParams) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/users/group`,
    method: 'DELETE',
    params
  });
};

export const getDeleteGroupMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGroup>>,
    TError,
    { params: DeleteGroupParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteGroup>>,
  TError,
  { params: DeleteGroupParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteGroup>>,
    { params: DeleteGroupParams }
  > = props => {
    const { params } = props ?? {};

    return deleteGroup(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteGroup>>
>;

export type DeleteGroupMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary delete group
 */
export const useDeleteGroup = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGroup>>,
    TError,
    { params: DeleteGroupParams },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteGroupMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create Configuration
 * @summary Create Configuration
 */
export const createConfiguration = (
  createConfigurationRequestSchema: CreateConfigurationRequestSchema
) => {
  return apiAxiosInstanceFn<ConfigSchema>({
    url: `/configurations/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createConfigurationRequestSchema
  });
};

export const getCreateConfigurationMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConfiguration>>,
    TError,
    { data: CreateConfigurationRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createConfiguration>>,
  TError,
  { data: CreateConfigurationRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createConfiguration>>,
    { data: CreateConfigurationRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return createConfiguration(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createConfiguration>>
>;
export type CreateConfigurationMutationBody = CreateConfigurationRequestSchema;
export type CreateConfigurationMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Configuration
 */
export const useCreateConfiguration = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConfiguration>>,
    TError,
    { data: CreateConfigurationRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getCreateConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List Configurations
 * @summary List Configurations
 */
export const listConfigurations = (
  params: ListConfigurationsParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<ConfigSchema[]>({
    url: `/configurations/`,
    method: 'GET',
    params,
    signal
  });
};

export const getListConfigurationsQueryKey = (
  params: ListConfigurationsParams
) => {
  return [`/configurations/`, ...(params ? [params] : [])] as const;
};

export const getListConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<typeof listConfigurations>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListConfigurationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listConfigurations>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListConfigurationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listConfigurations>>
  > = ({ signal }) => listConfigurations(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listConfigurations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listConfigurations>>
>;
export type ListConfigurationsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Configurations
 */
export const useListConfigurations = <
  TData = Awaited<ReturnType<typeof listConfigurations>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListConfigurationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listConfigurations>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListConfigurationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get Configuration
 * @summary Get Configuration
 */
export const getConfiguration = (configId: string, signal?: AbortSignal) => {
  return apiAxiosInstanceFn<ConfigSchema>({
    url: `/configurations/${configId}`,
    method: 'GET',
    signal
  });
};

export const getGetConfigurationQueryKey = (configId: string) => {
  return [`/configurations/${configId}`] as const;
};

export const getGetConfigurationQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfiguration>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfiguration>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConfigurationQueryKey(configId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfiguration>>
  > = ({ signal }) => getConfiguration(configId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!configId,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getConfiguration>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfiguration>>
>;
export type GetConfigurationQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Configuration
 */
export const useGetConfiguration = <
  TData = Awaited<ReturnType<typeof getConfiguration>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfiguration>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConfigurationQueryOptions(configId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update Configuration Name
 * @summary Update Configuration Name
 */
export const updateConfiguration = (
  configId: string,
  params: UpdateConfigurationParams
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configurations/${configId}`,
    method: 'PATCH',
    params
  });
};

export const getUpdateConfigurationMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConfiguration>>,
    TError,
    { configId: string; params: UpdateConfigurationParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateConfiguration>>,
  TError,
  { configId: string; params: UpdateConfigurationParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateConfiguration>>,
    { configId: string; params: UpdateConfigurationParams }
  > = props => {
    const { configId, params } = props ?? {};

    return updateConfiguration(configId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateConfiguration>>
>;

export type UpdateConfigurationMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Configuration Name
 */
export const useUpdateConfiguration = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConfiguration>>,
    TError,
    { configId: string; params: UpdateConfigurationParams },
    TContext
  >;
}) => {
  const mutationOptions = getUpdateConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete Configuration
 * @summary Delete Configuration
 */
export const deleteConfiguration = (configId: string) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configurations/${configId}`,
    method: 'DELETE'
  });
};

export const getDeleteConfigurationMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConfiguration>>,
    TError,
    { configId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteConfiguration>>,
  TError,
  { configId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteConfiguration>>,
    { configId: string }
  > = props => {
    const { configId } = props ?? {};

    return deleteConfiguration(configId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteConfiguration>>
>;

export type DeleteConfigurationMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Configuration
 */
export const useDeleteConfiguration = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConfiguration>>,
    TError,
    { configId: string },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Add Training Input to Queue Quality Control
 * @summary Add Training Input to Queue Quality Control
 */
export const startTraining = (
  configId: string,
  labelAsisitRequestQualityControl: LabelAsisitRequestQualityControl
) => {
  return apiAxiosInstanceFn<StartTraining200>({
    url: `/configurations/${configId}/training`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: labelAsisitRequestQualityControl
  });
};

export const getStartTrainingMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startTraining>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControl },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startTraining>>,
  TError,
  { configId: string; data: LabelAsisitRequestQualityControl },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startTraining>>,
    { configId: string; data: LabelAsisitRequestQualityControl }
  > = props => {
    const { configId, data } = props ?? {};

    return startTraining(configId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartTrainingMutationResult = NonNullable<
  Awaited<ReturnType<typeof startTraining>>
>;
export type StartTrainingMutationBody = LabelAsisitRequestQualityControl;
export type StartTrainingMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Add Training Input to Queue Quality Control
 */
export const useStartTraining = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startTraining>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControl },
    TContext
  >;
}) => {
  const mutationOptions = getStartTrainingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get training Result
 * @summary Get training Result
 */
export const getTrainingResult = (configId: string, signal?: AbortSignal) => {
  return apiAxiosInstanceFn<TrainingResultSchema[]>({
    url: `/configurations/${configId}/training`,
    method: 'GET',
    signal
  });
};

export const getGetTrainingResultQueryKey = (configId: string) => {
  return [`/configurations/${configId}/training`] as const;
};

export const getGetTrainingResultQueryOptions = <
  TData = Awaited<ReturnType<typeof getTrainingResult>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTrainingResult>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTrainingResultQueryKey(configId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTrainingResult>>
  > = ({ signal }) => getTrainingResult(configId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!configId,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTrainingResult>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTrainingResultQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTrainingResult>>
>;
export type GetTrainingResultQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get training Result
 */
export const useGetTrainingResult = <
  TData = Awaited<ReturnType<typeof getTrainingResult>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTrainingResult>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTrainingResultQueryOptions(configId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add Inference Input to Queue Quality Control
 * @summary Add Inference Input to Queue Quality Control
 */
export const startInference = (
  configId: string,
  labelAsisitRequestQualityControlInference: LabelAsisitRequestQualityControlInference
) => {
  return apiAxiosInstanceFn<StartInference200>({
    url: `/configurations/${configId}/inference`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: labelAsisitRequestQualityControlInference
  });
};

export const getStartInferenceMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startInference>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControlInference },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startInference>>,
  TError,
  { configId: string; data: LabelAsisitRequestQualityControlInference },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startInference>>,
    { configId: string; data: LabelAsisitRequestQualityControlInference }
  > = props => {
    const { configId, data } = props ?? {};

    return startInference(configId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartInferenceMutationResult = NonNullable<
  Awaited<ReturnType<typeof startInference>>
>;
export type StartInferenceMutationBody =
  LabelAsisitRequestQualityControlInference;
export type StartInferenceMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Add Inference Input to Queue Quality Control
 */
export const useStartInference = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startInference>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControlInference },
    TContext
  >;
}) => {
  const mutationOptions = getStartInferenceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get Inference List According to Config ID
 * @summary Get Inference List
 */
export const getInferenceList = (configId: string, signal?: AbortSignal) => {
  return apiAxiosInstanceFn<InferenceSchema[]>({
    url: `/configurations/${configId}/inference`,
    method: 'GET',
    signal
  });
};

export const getGetInferenceListQueryKey = (configId: string) => {
  return [`/configurations/${configId}/inference`] as const;
};

export const getGetInferenceListQueryOptions = <
  TData = Awaited<ReturnType<typeof getInferenceList>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInferenceList>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInferenceListQueryKey(configId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInferenceList>>
  > = ({ signal }) => getInferenceList(configId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!configId,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInferenceList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInferenceListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInferenceList>>
>;
export type GetInferenceListQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Inference List
 */
export const useGetInferenceList = <
  TData = Awaited<ReturnType<typeof getInferenceList>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInferenceList>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInferenceListQueryOptions(configId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add Novelty Input to Queue Quality Control
 * @summary Add Novelty Input to Queue Quality Control
 */
export const startNovelty = (
  configId: string,
  labelAsisitRequestQualityControlnovelty: LabelAsisitRequestQualityControlnovelty
) => {
  return apiAxiosInstanceFn<StartNovelty200>({
    url: `/configurations/${configId}/novelty`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: labelAsisitRequestQualityControlnovelty
  });
};

export const getStartNoveltyMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startNovelty>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControlnovelty },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startNovelty>>,
  TError,
  { configId: string; data: LabelAsisitRequestQualityControlnovelty },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startNovelty>>,
    { configId: string; data: LabelAsisitRequestQualityControlnovelty }
  > = props => {
    const { configId, data } = props ?? {};

    return startNovelty(configId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartNoveltyMutationResult = NonNullable<
  Awaited<ReturnType<typeof startNovelty>>
>;
export type StartNoveltyMutationBody = LabelAsisitRequestQualityControlnovelty;
export type StartNoveltyMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Add Novelty Input to Queue Quality Control
 */
export const useStartNovelty = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startNovelty>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControlnovelty },
    TContext
  >;
}) => {
  const mutationOptions = getStartNoveltyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Add Incremental Input to Queue Quality Control
 * @summary Add Incremental Input to Queue Quality Control
 */
export const startIncremental = (
  configId: string,
  labelAsisitRequestQualityControlIncremental: LabelAsisitRequestQualityControlIncremental
) => {
  return apiAxiosInstanceFn<StartIncremental200>({
    url: `/configurations/${configId}/incremental_training`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: labelAsisitRequestQualityControlIncremental
  });
};

export const getStartIncrementalMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startIncremental>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControlIncremental },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startIncremental>>,
  TError,
  { configId: string; data: LabelAsisitRequestQualityControlIncremental },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startIncremental>>,
    { configId: string; data: LabelAsisitRequestQualityControlIncremental }
  > = props => {
    const { configId, data } = props ?? {};

    return startIncremental(configId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartIncrementalMutationResult = NonNullable<
  Awaited<ReturnType<typeof startIncremental>>
>;
export type StartIncrementalMutationBody =
  LabelAsisitRequestQualityControlIncremental;
export type StartIncrementalMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Add Incremental Input to Queue Quality Control
 */
export const useStartIncremental = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startIncremental>>,
    TError,
    { configId: string; data: LabelAsisitRequestQualityControlIncremental },
    TContext
  >;
}) => {
  const mutationOptions = getStartIncrementalMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get Model List According to Config ID
 * @summary Get Model List
 */
export const getModelList = (configId: string, signal?: AbortSignal) => {
  return apiAxiosInstanceFn<TrainingResultSchema[]>({
    url: `/configurations/${configId}/models`,
    method: 'GET',
    signal
  });
};

export const getGetModelListQueryKey = (configId: string) => {
  return [`/configurations/${configId}/models`] as const;
};

export const getGetModelListQueryOptions = <
  TData = Awaited<ReturnType<typeof getModelList>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getModelList>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModelListQueryKey(configId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelList>>> = ({
    signal
  }) => getModelList(configId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!configId,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getModelList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetModelListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModelList>>
>;
export type GetModelListQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Model List
 */
export const useGetModelList = <
  TData = Awaited<ReturnType<typeof getModelList>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getModelList>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetModelListQueryOptions(configId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Deploy model to deployment for the specified configuration
 * @summary Deploy model to deployment for the specified configuration
 */
export const deployModel = (configId: string, params: DeployModelParams) => {
  return apiAxiosInstanceFn<DeployModel200>({
    url: `/configurations/${configId}/deploy`,
    method: 'POST',
    params
  });
};

export const getDeployModelMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deployModel>>,
    TError,
    { configId: string; params: DeployModelParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deployModel>>,
  TError,
  { configId: string; params: DeployModelParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deployModel>>,
    { configId: string; params: DeployModelParams }
  > = props => {
    const { configId, params } = props ?? {};

    return deployModel(configId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeployModelMutationResult = NonNullable<
  Awaited<ReturnType<typeof deployModel>>
>;

export type DeployModelMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Deploy model to deployment for the specified configuration
 */
export const useDeployModel = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deployModel>>,
    TError,
    { configId: string; params: DeployModelParams },
    TContext
  >;
}) => {
  const mutationOptions = getDeployModelMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get Inference Result
 * @summary Get Inference Result
 */
export const getInferenceResult = (
  configId: string,
  dataId: string,
  params: GetInferenceResultParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<InferenceResultSchema>({
    url: `/configurations/${configId}/inference/${dataId}`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetInferenceResultQueryKey = (
  configId: string,
  dataId: string,
  params: GetInferenceResultParams
) => {
  return [
    `/configurations/${configId}/inference/${dataId}`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetInferenceResultQueryOptions = <
  TData = Awaited<ReturnType<typeof getInferenceResult>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  params: GetInferenceResultParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInferenceResult>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetInferenceResultQueryKey(configId, dataId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInferenceResult>>
  > = ({ signal }) => getInferenceResult(configId, dataId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(configId && dataId),
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInferenceResult>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInferenceResultQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInferenceResult>>
>;
export type GetInferenceResultQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Inference Result
 */
export const useGetInferenceResult = <
  TData = Awaited<ReturnType<typeof getInferenceResult>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  params: GetInferenceResultParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInferenceResult>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInferenceResultQueryOptions(
    configId,
    dataId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get Inference Data Distribution
 * @summary Get Inference Data Distribution
 */
export const getInferenceDataDistribution = (
  configId: string,
  dataId: string,
  params: GetInferenceDataDistributionParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<DataDistributationResultSchema[]>({
    url: `/configurations/${configId}/inference/${dataId}/data_distribution`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetInferenceDataDistributionQueryKey = (
  configId: string,
  dataId: string,
  params: GetInferenceDataDistributionParams
) => {
  return [
    `/configurations/${configId}/inference/${dataId}/data_distribution`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetInferenceDataDistributionQueryOptions = <
  TData = Awaited<ReturnType<typeof getInferenceDataDistribution>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  params: GetInferenceDataDistributionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInferenceDataDistribution>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetInferenceDataDistributionQueryKey(configId, dataId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInferenceDataDistribution>>
  > = ({ signal }) =>
    getInferenceDataDistribution(configId, dataId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(configId && dataId),
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInferenceDataDistribution>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInferenceDataDistributionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInferenceDataDistribution>>
>;
export type GetInferenceDataDistributionQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Inference Data Distribution
 */
export const useGetInferenceDataDistribution = <
  TData = Awaited<ReturnType<typeof getInferenceDataDistribution>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  params: GetInferenceDataDistributionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInferenceDataDistribution>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInferenceDataDistributionQueryOptions(
    configId,
    dataId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get Training Inference Details
 * @summary Get training inference details
 */
export const getTrainingInferenceDetails = (
  params: GetTrainingInferenceDetailsParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<TrainingInferenceDetailsSchema>({
    url: `/configurations//training_inference_details`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetTrainingInferenceDetailsQueryKey = (
  params: GetTrainingInferenceDetailsParams
) => {
  return [
    `/configurations//training_inference_details`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetTrainingInferenceDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTrainingInferenceDetails>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetTrainingInferenceDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTrainingInferenceDetails>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTrainingInferenceDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTrainingInferenceDetails>>
  > = ({ signal }) => getTrainingInferenceDetails(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTrainingInferenceDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTrainingInferenceDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTrainingInferenceDetails>>
>;
export type GetTrainingInferenceDetailsQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get training inference details
 */
export const useGetTrainingInferenceDetails = <
  TData = Awaited<ReturnType<typeof getTrainingInferenceDetails>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetTrainingInferenceDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTrainingInferenceDetails>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTrainingInferenceDetailsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get novelty Details
 * @summary Get novelty details
 */
export const getNoveltyDetails = (
  configId: string,
  dataId: string,
  params: GetNoveltyDetailsParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<NoveltySchema>({
    url: `/configurations/${configId}/novelty/${dataId}`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetNoveltyDetailsQueryKey = (
  configId: string,
  dataId: string,
  params: GetNoveltyDetailsParams
) => {
  return [
    `/configurations/${configId}/novelty/${dataId}`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetNoveltyDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getNoveltyDetails>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  params: GetNoveltyDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNoveltyDetails>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetNoveltyDetailsQueryKey(configId, dataId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNoveltyDetails>>
  > = ({ signal }) => getNoveltyDetails(configId, dataId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(configId && dataId),
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getNoveltyDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNoveltyDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNoveltyDetails>>
>;
export type GetNoveltyDetailsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get novelty details
 */
export const useGetNoveltyDetails = <
  TData = Awaited<ReturnType<typeof getNoveltyDetails>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  params: GetNoveltyDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNoveltyDetails>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNoveltyDetailsQueryOptions(
    configId,
    dataId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Delete Inference
 * @summary Delete Inference
 */
export const deleteInference = (params: DeleteInferenceParams) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configurations//inference`,
    method: 'DELETE',
    params
  });
};

export const getDeleteInferenceMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInference>>,
    TError,
    { params: DeleteInferenceParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInference>>,
  TError,
  { params: DeleteInferenceParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInference>>,
    { params: DeleteInferenceParams }
  > = props => {
    const { params } = props ?? {};

    return deleteInference(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInferenceMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInference>>
>;

export type DeleteInferenceMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Inference
 */
export const useDeleteInference = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInference>>,
    TError,
    { params: DeleteInferenceParams },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteInferenceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete objects by data_id
 * @summary Delete objects by data_id
 */
export const deleteObjectDataId = (params: DeleteObjectDataIdParams) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configurations//objects`,
    method: 'DELETE',
    params
  });
};

export const getDeleteObjectDataIdMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteObjectDataId>>,
    TError,
    { params: DeleteObjectDataIdParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteObjectDataId>>,
  TError,
  { params: DeleteObjectDataIdParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteObjectDataId>>,
    { params: DeleteObjectDataIdParams }
  > = props => {
    const { params } = props ?? {};

    return deleteObjectDataId(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteObjectDataIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteObjectDataId>>
>;

export type DeleteObjectDataIdMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete objects by data_id
 */
export const useDeleteObjectDataId = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteObjectDataId>>,
    TError,
    { params: DeleteObjectDataIdParams },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteObjectDataIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete object by object_id
 * @summary Delete object by object_id
 */
export const deleteObjectObjectId = (params: DeleteObjectObjectIdParams) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configurations//object`,
    method: 'DELETE',
    params
  });
};

export const getDeleteObjectObjectIdMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteObjectObjectId>>,
    TError,
    { params: DeleteObjectObjectIdParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteObjectObjectId>>,
  TError,
  { params: DeleteObjectObjectIdParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteObjectObjectId>>,
    { params: DeleteObjectObjectIdParams }
  > = props => {
    const { params } = props ?? {};

    return deleteObjectObjectId(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteObjectObjectIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteObjectObjectId>>
>;

export type DeleteObjectObjectIdMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete object by object_id
 */
export const useDeleteObjectObjectId = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteObjectObjectId>>,
    TError,
    { params: DeleteObjectObjectIdParams },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteObjectObjectIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * To maintain queue status
 * @summary Update the queue status
 */
export const queueStatus = (queueRequest: QueueRequest) => {
  return apiAxiosInstanceFn<QueueStatus200>({
    url: `/configurations//queue_status`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: queueRequest
  });
};

export const getQueueStatusMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof queueStatus>>,
    TError,
    { data: QueueRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof queueStatus>>,
  TError,
  { data: QueueRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof queueStatus>>,
    { data: QueueRequest }
  > = props => {
    const { data } = props ?? {};

    return queueStatus(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type QueueStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof queueStatus>>
>;
export type QueueStatusMutationBody = QueueRequest;
export type QueueStatusMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update the queue status
 */
export const useQueueStatus = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof queueStatus>>,
    TError,
    { data: QueueRequest },
    TContext
  >;
}) => {
  const mutationOptions = getQueueStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * get model architecture list
 * @summary get model architecture list
 */
export const modelArchitectureList = (signal?: AbortSignal) => {
  return apiAxiosInstanceFn<ModelArchitectureResponse>({
    url: `/configurations//model_architectures`,
    method: 'GET',
    signal
  });
};

export const getModelArchitectureListQueryKey = () => {
  return [`/configurations//model_architectures`] as const;
};

export const getModelArchitectureListQueryOptions = <
  TData = Awaited<ReturnType<typeof modelArchitectureList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof modelArchitectureList>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getModelArchitectureListQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof modelArchitectureList>>
  > = ({ signal }) => modelArchitectureList(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof modelArchitectureList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ModelArchitectureListQueryResult = NonNullable<
  Awaited<ReturnType<typeof modelArchitectureList>>
>;
export type ModelArchitectureListQueryError = ErrorType<unknown>;

/**
 * @summary get model architecture list
 */
export const useModelArchitectureList = <
  TData = Awaited<ReturnType<typeof modelArchitectureList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof modelArchitectureList>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getModelArchitectureListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * get all objects of a Data ID
 * @summary get all objects of a Data ID
 */
export const getObjectsByDataId = (
  configId: string,
  dataId: string,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<ObjResponseSchemaCount>({
    url: `/configurations${configId}/data/${dataId}/object`,
    method: 'GET',
    signal
  });
};

export const getGetObjectsByDataIdQueryKey = (
  configId: string,
  dataId: string
) => {
  return [`/configurations${configId}/data/${dataId}/object`] as const;
};

export const getGetObjectsByDataIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getObjectsByDataId>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getObjectsByDataId>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetObjectsByDataIdQueryKey(configId, dataId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getObjectsByDataId>>
  > = ({ signal }) => getObjectsByDataId(configId, dataId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(configId && dataId),
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getObjectsByDataId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetObjectsByDataIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getObjectsByDataId>>
>;
export type GetObjectsByDataIdQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary get all objects of a Data ID
 */
export const useGetObjectsByDataId = <
  TData = Awaited<ReturnType<typeof getObjectsByDataId>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  dataId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getObjectsByDataId>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetObjectsByDataIdQueryOptions(
    configId,
    dataId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * get all objects of a configuration
 * @summary get all objects of a configuration
 */
export const getAllObjects = (
  configId: string,
  params: GetAllObjectsParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<ObjResponseSchema>({
    url: `/configurations/${configId}/objects`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetAllObjectsQueryKey = (
  configId: string,
  params: GetAllObjectsParams
) => {
  return [
    `/configurations/${configId}/objects`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetAllObjectsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllObjects>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  params: GetAllObjectsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllObjects>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllObjectsQueryKey(configId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllObjects>>> = ({
    signal
  }) => getAllObjects(configId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!configId,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllObjects>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllObjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllObjects>>
>;
export type GetAllObjectsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary get all objects of a configuration
 */
export const useGetAllObjects = <
  TData = Awaited<ReturnType<typeof getAllObjects>>,
  TError = ErrorType<HTTPValidationError>
>(
  configId: string,
  params: GetAllObjectsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllObjects>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllObjectsQueryOptions(configId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Sync Objects using configuration id
 * @summary Sync Objects using configuration id
 */
export const syncObject = (
  configId: string,
  syncObjectsRequest: SyncObjectsRequest[]
) => {
  return apiAxiosInstanceFn<ObjResponseSchema>({
    url: `/configurations/${configId}/objects`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: syncObjectsRequest
  });
};

export const getSyncObjectMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syncObject>>,
    TError,
    { configId: string; data: SyncObjectsRequest[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof syncObject>>,
  TError,
  { configId: string; data: SyncObjectsRequest[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof syncObject>>,
    { configId: string; data: SyncObjectsRequest[] }
  > = props => {
    const { configId, data } = props ?? {};

    return syncObject(configId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SyncObjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof syncObject>>
>;
export type SyncObjectMutationBody = SyncObjectsRequest[];
export type SyncObjectMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Sync Objects using configuration id
 */
export const useSyncObject = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syncObject>>,
    TError,
    { configId: string; data: SyncObjectsRequest[] },
    TContext
  >;
}) => {
  const mutationOptions = getSyncObjectMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create Objects
 * @summary Create Objects
 */
export const createObjects = (
  configId: string,
  createObjectSchema: CreateObjectSchema[]
) => {
  return apiAxiosInstanceFn<Createobjectresponseschema[]>({
    url: `/configurations/${configId}/objects`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createObjectSchema
  });
};

export const getCreateObjectsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createObjects>>,
    TError,
    { configId: string; data: CreateObjectSchema[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createObjects>>,
  TError,
  { configId: string; data: CreateObjectSchema[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createObjects>>,
    { configId: string; data: CreateObjectSchema[] }
  > = props => {
    const { configId, data } = props ?? {};

    return createObjects(configId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateObjectsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createObjects>>
>;
export type CreateObjectsMutationBody = CreateObjectSchema[];
export type CreateObjectsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Objects
 */
export const useCreateObjects = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createObjects>>,
    TError,
    { configId: string; data: CreateObjectSchema[] },
    TContext
  >;
}) => {
  const mutationOptions = getCreateObjectsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * get all processed data objects of a configuration  group by data_id
 * @summary get all processed data objects  of a configuration group by data_id
 */
export const getAllObjectsProcessedData = (
  params?: GetAllObjectsProcessedDataParams,
  signal?: AbortSignal
) => {
  return apiAxiosInstanceFn<ObjResponseSchemas>({
    url: `/configurations//data`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetAllObjectsProcessedDataQueryKey = (
  params?: GetAllObjectsProcessedDataParams
) => {
  return [`/configurations//data`, ...(params ? [params] : [])] as const;
};

export const getGetAllObjectsProcessedDataQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllObjectsProcessedData>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetAllObjectsProcessedDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllObjectsProcessedData>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllObjectsProcessedDataQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllObjectsProcessedData>>
  > = ({ signal }) => getAllObjectsProcessedData(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllObjectsProcessedData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllObjectsProcessedDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllObjectsProcessedData>>
>;
export type GetAllObjectsProcessedDataQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary get all processed data objects  of a configuration group by data_id
 */
export const useGetAllObjectsProcessedData = <
  TData = Awaited<ReturnType<typeof getAllObjectsProcessedData>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetAllObjectsProcessedDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllObjectsProcessedData>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllObjectsProcessedDataQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Sync Objects using data id
 * @summary Sync Objects data id
 */
export const syncObjectByDataid = (
  configId: string,
  dataId: string,
  syncObjectsRequest: SyncObjectsRequest[]
) => {
  return apiAxiosInstanceFn<ObjResponseSchema>({
    url: `/configurations/${configId}/data/${dataId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: syncObjectsRequest
  });
};

export const getSyncObjectByDataidMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syncObjectByDataid>>,
    TError,
    { configId: string; dataId: string; data: SyncObjectsRequest[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof syncObjectByDataid>>,
  TError,
  { configId: string; dataId: string; data: SyncObjectsRequest[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof syncObjectByDataid>>,
    { configId: string; dataId: string; data: SyncObjectsRequest[] }
  > = props => {
    const { configId, dataId, data } = props ?? {};

    return syncObjectByDataid(configId, dataId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SyncObjectByDataidMutationResult = NonNullable<
  Awaited<ReturnType<typeof syncObjectByDataid>>
>;
export type SyncObjectByDataidMutationBody = SyncObjectsRequest[];
export type SyncObjectByDataidMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Sync Objects data id
 */
export const useSyncObjectByDataid = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syncObjectByDataid>>,
    TError,
    { configId: string; dataId: string; data: SyncObjectsRequest[] },
    TContext
  >;
}) => {
  const mutationOptions = getSyncObjectByDataidMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete Preprocessed Data
 * @summary Delete Preprocessed Data
 */
export const deletePreprocessedData = (configId: string, dataId: string) => {
  return apiAxiosInstanceFn<DeletePreprocessedData200>({
    url: `/configurations/${configId}/data/${dataId}`,
    method: 'DELETE'
  });
};

export const getDeletePreprocessedDataMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePreprocessedData>>,
    TError,
    { configId: string; dataId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePreprocessedData>>,
  TError,
  { configId: string; dataId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePreprocessedData>>,
    { configId: string; dataId: string }
  > = props => {
    const { configId, dataId } = props ?? {};

    return deletePreprocessedData(configId, dataId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePreprocessedDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePreprocessedData>>
>;

export type DeletePreprocessedDataMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Delete Preprocessed Data
 */
export const useDeletePreprocessedData = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePreprocessedData>>,
    TError,
    { configId: string; dataId: string },
    TContext
  >;
}) => {
  const mutationOptions = getDeletePreprocessedDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update data usage status
 * @summary Update data usage status
 */
export const saveLabaelAssist = (configId: string, dataId: string) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configurations/${configId}/data/${dataId}/status`,
    method: 'PUT'
  });
};

export const getSaveLabaelAssistMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveLabaelAssist>>,
    TError,
    { configId: string; dataId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveLabaelAssist>>,
  TError,
  { configId: string; dataId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveLabaelAssist>>,
    { configId: string; dataId: string }
  > = props => {
    const { configId, dataId } = props ?? {};

    return saveLabaelAssist(configId, dataId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveLabaelAssistMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveLabaelAssist>>
>;

export type SaveLabaelAssistMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update data usage status
 */
export const useSaveLabaelAssist = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveLabaelAssist>>,
    TError,
    { configId: string; dataId: string },
    TContext
  >;
}) => {
  const mutationOptions = getSaveLabaelAssistMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create pre_processed_objects
 * @summary Create pre_processed_objects
 */
export const createPreProcessedObjects = (
  configId: string,
  createProcessedDataRequest: CreateProcessedDataRequest,
  params: CreatePreProcessedObjectsParams
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configurations/${configId}/data`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createProcessedDataRequest,
    params
  });
};

export const getCreatePreProcessedObjectsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPreProcessedObjects>>,
    TError,
    {
      configId: string;
      data: CreateProcessedDataRequest;
      params: CreatePreProcessedObjectsParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPreProcessedObjects>>,
  TError,
  {
    configId: string;
    data: CreateProcessedDataRequest;
    params: CreatePreProcessedObjectsParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPreProcessedObjects>>,
    {
      configId: string;
      data: CreateProcessedDataRequest;
      params: CreatePreProcessedObjectsParams;
    }
  > = props => {
    const { configId, data, params } = props ?? {};

    return createPreProcessedObjects(configId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePreProcessedObjectsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPreProcessedObjects>>
>;
export type CreatePreProcessedObjectsMutationBody = CreateProcessedDataRequest;
export type CreatePreProcessedObjectsMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create pre_processed_objects
 */
export const useCreatePreProcessedObjects = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPreProcessedObjects>>,
    TError,
    {
      configId: string;
      data: CreateProcessedDataRequest;
      params: CreatePreProcessedObjectsParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getCreatePreProcessedObjectsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create Configuration Camera
 * @summary Create Configuration Camera
 */
export const createConfigurationCamera = (
  createCameraRequestSchema: CreateCameraRequestSchema
) => {
  return apiAxiosInstanceFn<CameraSchema>({
    url: `/configuration/camera`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createCameraRequestSchema
  });
};

export const getCreateConfigurationCameraMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConfigurationCamera>>,
    TError,
    { data: CreateCameraRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createConfigurationCamera>>,
  TError,
  { data: CreateCameraRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createConfigurationCamera>>,
    { data: CreateCameraRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return createConfigurationCamera(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateConfigurationCameraMutationResult = NonNullable<
  Awaited<ReturnType<typeof createConfigurationCamera>>
>;
export type CreateConfigurationCameraMutationBody = CreateCameraRequestSchema;
export type CreateConfigurationCameraMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Configuration Camera
 */
export const useCreateConfigurationCamera = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConfigurationCamera>>,
    TError,
    { data: CreateCameraRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getCreateConfigurationCameraMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List of cameras
 * @summary List of cameras
 */
export const listCameras = (id: string, signal?: AbortSignal) => {
  return apiAxiosInstanceFn<CameraSchema[]>({
    url: `/configuration/${id}/cameras`,
    method: 'GET',
    signal
  });
};

export const getListCamerasQueryKey = (id: string) => {
  return [`/configuration/${id}/cameras`] as const;
};

export const getListCamerasQueryOptions = <
  TData = Awaited<ReturnType<typeof listCameras>>,
  TError = ErrorType<HTTPValidationError>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCameras>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCamerasQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCameras>>> = ({
    signal
  }) => listCameras(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listCameras>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCamerasQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCameras>>
>;
export type ListCamerasQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List of cameras
 */
export const useListCameras = <
  TData = Awaited<ReturnType<typeof listCameras>>,
  TError = ErrorType<HTTPValidationError>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCameras>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCamerasQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Start live inference
 * @summary Start live inference
 */
export const startLiveInference = (
  startLiveRequestSchema: StartLiveRequestSchema
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configuration/camera/start_live_inference`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: startLiveRequestSchema
  });
};

export const getStartLiveInferenceMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startLiveInference>>,
    TError,
    { data: StartLiveRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startLiveInference>>,
  TError,
  { data: StartLiveRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startLiveInference>>,
    { data: StartLiveRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return startLiveInference(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartLiveInferenceMutationResult = NonNullable<
  Awaited<ReturnType<typeof startLiveInference>>
>;
export type StartLiveInferenceMutationBody = StartLiveRequestSchema;
export type StartLiveInferenceMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Start live inference
 */
export const useStartLiveInference = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startLiveInference>>,
    TError,
    { data: StartLiveRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = getStartLiveInferenceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete camera
 * @summary Delete camera
 */
export const deleteCamera = (id: string, cameraId: string) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configuration/${id}/cameras/${cameraId}`,
    method: 'DELETE'
  });
};

export const getDeleteCameraMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCamera>>,
    TError,
    { id: string; cameraId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCamera>>,
  TError,
  { id: string; cameraId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCamera>>,
    { id: string; cameraId: string }
  > = props => {
    const { id, cameraId } = props ?? {};

    return deleteCamera(id, cameraId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCameraMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCamera>>
>;

export type DeleteCameraMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete camera
 */
export const useDeleteCamera = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCamera>>,
    TError,
    { id: string; cameraId: string },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteCameraMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * update camera
 * @summary update camera
 */
export const updateCamera = (
  id: string,
  cameraId: string,
  params: UpdateCameraParams
) => {
  return apiAxiosInstanceFn<unknown>({
    url: `/configuration/${id}/cameras/${cameraId}`,
    method: 'PATCH',
    params
  });
};

export const getUpdateCameraMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCamera>>,
    TError,
    { id: string; cameraId: string; params: UpdateCameraParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCamera>>,
  TError,
  { id: string; cameraId: string; params: UpdateCameraParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCamera>>,
    { id: string; cameraId: string; params: UpdateCameraParams }
  > = props => {
    const { id, cameraId, params } = props ?? {};

    return updateCamera(id, cameraId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCameraMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCamera>>
>;

export type UpdateCameraMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary update camera
 */
export const useUpdateCamera = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCamera>>,
    TError,
    { id: string; cameraId: string; params: UpdateCameraParams },
    TContext
  >;
}) => {
  const mutationOptions = getUpdateCameraMutationOptions(options);

  return useMutation(mutationOptions);
};
