import { Button, Card, Typography } from 'components';
import React, { useRef, useState, useEffect } from 'react';

import { Main } from './main';

import { ViewDetails } from 'pages/train/viewDetails';
import { ViewReports } from './viewReport';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ObjResponseSchemaCount, ObjSchema } from 'schemas';
import { useGetObjectsByDataId, useListConfigurations } from 'api/sdk';
import { useDispatch } from 'react-redux';
import { setAllConfigs } from 'store/configSlice';

interface IncrementalTrainingReport {
  before_incremental: {
    train_accuracy: number;
    [key: string]: any;
  };
  after_incremental: {
    train_accuracy: number;
    [key: string]: any;
  };
}

interface ModelReport {
  model_name: string; // "test56"
  model_architecture: string; // "resnet18"
  train_spilt: number; // 80 (changed key to match common spelling: train_split)
  batch_size: number; // 8
  learning_rate: number; // 0.001
  total_epochs: number; // 10
  current_epoch: number; // 10
  test_accuracy: number; // 1 (0 to 1, where 1 represents 100%)
  status: string; // "COMPLETED"
  total_image_count: number; // 1344
  class_count: number; // 2
  model_id: string; // "050f6d95-ac45-485e-80a0-5509a8637319"
  mode: string; // "Training" (or other modes as applicable)
  training_started_at: string; // Date in ISO format (e.g., "2024-11-13T09:05:26.671860")
  inference_id: string; // "aa49b774-aaa3-46d4-a34f-441a406bd417"
}
interface InferenceData {
  accuracy: number;
  class_count: number;
  total_images: number;
  unlabeled_count: number;
  confusion_matrix: any;
}

interface InferenceReport {
  training_report: TrainingReport[];
  model_details: ModelReport[];
  config_name: string;
  inference_report: InferenceData[];
  incremental_training_report?: IncrementalTrainingReport[];
  inference_report_label?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
    true_positive: number;
    true_negative: number;
    false_positive: number;
    false_nagative: number;
    accuracy: number;
  }>;
  inference_report_unlabel?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
  }>;
}

interface TrainingReport {
  train_accuracy: number;
  train_error: number;
  test_accuracy: number;
  test_error: number;
  validation_accuracy: number;
  validation_loss: number;
}
export const Reports: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [objectAll, setObjectAll] = useState<ObjResponseSchemaCount>();
  const [inferenceReport, setInferenceReport] = useState<InferenceReport>({
    training_report: [],
    incremental_training_report: [],
    model_details: [],
    config_name: '',
    inference_report: []
  });
  const onChange = (num: number) => {
    setStep(num);
  };

  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const configs = useSelector((state: RootState) => state.config.allConfigs);
  const [currentObjects, setCurrentObjects] = useState<ObjSchema[]>([]);
  const [reportsLoaded, setReportsLoaded] = useState(true);
  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const profile = useSelector((state: RootState) => state.auth.profile);

  const { refetch: currentObjectsRefetch } = useGetObjectsByDataId(
    selectedConfig || '',
    currentInferenceID || ''
  );

  useEffect(() => {
    if (currentInferenceID) {
      currentObjectsRefetch().then(res => {
        setCurrentObjects(res?.data?.data.object_details || []);
        setObjectAll(res?.data?.data);
      });
    }
  }, [currentInferenceID]);

  const dispatch = useDispatch();

  const { isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);

  return (
    <>
      {step == 1 && (
        <Main
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          objects={currentObjects}
          handelRefetchObjects={currentObjectsRefetch}
          setCurrentInferenceID={setCurrentInferenceID}
          setViewMode={setViewMode}
          setInferenceReport={setInferenceReport}
          setReportsLoaded={setReportsLoaded}
        />
      )}
      {step === 2 && (
        <ViewReports
          onChange={onChange}
          currentInferenceID={currentInferenceID}
          inferenceReport={inferenceReport}
          reportsLoaded={reportsLoaded}
        />
      )}

      {step == 3 && (
        <ViewDetails
          onChange={onChange}
          selectedConfig={selectedConfig}
          objects={currentObjects}
          objectAll={objectAll}
          currentInferenceID={currentInferenceID}
          mode={'TRAINING'}
          setStep={setStep}
          viewMode={viewMode}
          configs={configs}
        />
      )}
    </>
  );
};
