/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */

export type Type = (typeof Type)[keyof typeof Type];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Type = {
  group: 'group',
  self: 'self'
} as const;
