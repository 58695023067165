/* eslint-disable */
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField
} from '@mui/material';
import {
  useCreateObjects,
  useGetObjectsByDataId,
  useStartInference,
  useSyncObjectByDataid
} from 'api/sdk';
import { Modal, Typography } from 'components';
import { SelectInput } from 'components/Form';
import { useSnack } from 'plugins/snack';

import { Snack } from 'plugins/snack/Snack';
import { config } from 'process';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CreateObjectSchema,
  InferenceSchema,
  ModelListSchema,
  ObjSchema,
  ObjSchemaInferenceId,
  SyncObjectsRequest
} from 'schemas';
import { RootState } from 'store';
import { v4 } from 'uuid';
import * as Yup from 'yup';

interface ICreateConfigModalProps {
  open: boolean;
  onClose: () => void;
  onSucess: () => void;
  ModelList: any;
  objects: ObjSchema[];

  InferenceId: string | undefined;

  count: number | undefined;
  setSelectedModel: (modalID: string) => void;
  selectedModel: string | undefined;

  refetchInference: () => void;

  selectedObjects: ObjSchema[] | undefined;
}

interface IFormValues {
  model_name: string;
  number_of_image: number | null;
  infer_all: boolean;
  specific_number: number | null;
}

export const CreateModal: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  onSucess,
  ModelList,
  InferenceId,
  count,
  selectedModel,
  setSelectedModel,
  objects,
  selectedObjects,
  refetchInference
}) => {
  const [values, setValues] = useState<IFormValues>({
    model_name: '',
    number_of_image: 50,
    infer_all: true,
    specific_number: 50
  });
  const [newInferenceID, setNewInferenceID] = useState<string>('');
  const [newObjects, setNewObjects] = useState<ObjSchema[]>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  // const [error, setError] = useState<string | undefined>(undefined);

  const [proceedDisable, setProceedDisable] = useState(true);
  const { mutateAsync, isLoading } = useStartInference();
  // const { mutateAsync: liveInference } = useLiveInference();
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const resetValues = () => {
    setValues({
      model_name: '',
      number_of_image: 50,
      infer_all: true,
      specific_number: 50
    });
  };

  // useEffect(() => {
  //   if (selectedModel) {
  //     setProceedDisable(false);
  //   } else {
  //     setProceedDisable(true);
  //   }
  // }, [selectedModel]);

  // data: {
  //   config_id: selectedConfig?.toString() ?? '',
  //   data_id: newInferenceID,
  //   model_id: selectedModel?.toString() ?? ''
  // }

  const handleTriggerInference = (newInferenceID: string) => {
    // if (selectedModel) {
    mutateAsync({
      configId: selectedConfig?.toString() ?? '',

      data: {
        config_id: selectedConfig?.toString() ?? '',
        data_id: newInferenceID
        // model_id: selectedModel?.toString() ?? ''
      }
    })
      .then(() => {
        refetchInference();
        onSucess();

        Snack({
          message: 'PipeLine Triggered successfully',
          severity: 'success'
        });
        // onClose();
      })
      .catch(error => {
        setErrorMessage(error.response.data.detail);
        setProceedDisable(false);
        // setTimeout(() => {
        //   setErrorMessage(undefined);
        // }, 3000);
      });
  };

  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  const { data: objectData, refetch: currentObjectsRefetch } =
    useGetObjectsByDataId(selectedConfig || '', newInferenceID || '');

  useEffect(() => {
    if (newInferenceID) {
      currentObjectsRefetch().then(res => {
        setNewObjects(res.data?.data.object_details || []);
      });
    }
  }, [newInferenceID]);
  const snack = useSnack();

  const {
    data: objectListArr,
    mutateAsync: syncObject,
    isLoading: syncLoading
  } = useSyncObjectByDataid();
  const handleSync = async (
    data: ObjSchema[],
    configID: string,
    inferenceid: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      processed_id: null,
      configuration_id: configID || '',
      groupname: obj.groupname as string,
      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          nas_path: imagePath.nas_path,
          image_path: imagePath.thumb_url,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: obj.mode || ''
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid,
      configId: configID || ''
    });
  };
  useEffect(() => {
    if (newObjects?.length && newObjects.length > 0) {
      const transformedObjects: ObjSchema[] = [];

      newObjects?.forEach(i => {
        let temp2: ObjSchema;
        const currentObj = objects.find(j => j.classname === i.classname);

        temp2 = {
          ...i,
          images: currentObj?.images || []
        };

        transformedObjects.push(temp2);
      });

      handleSync(
        transformedObjects,
        selectedConfig || '',
        transformedObjects[0].data_id || ''
      ).then(res => {
        handleTriggerInference(newInferenceID);
      });
    }
  }, [newObjects]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setErrorMessage(undefined);
        resetValues();
      }}
      title="Are you sure you want to do Inference ?"
      size="md"
    >
      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectInput
            name="Model"
            options={
              ModelList?.filter(
                (model: any) => model?.status === 'COMPLETED'
              ).map((model: any) => ({
                label: model?.model_name,
                value: model?.model_id
              })) || []
            }
            placeholder={'Select Model'}
            value={selectedModel}
            variant="outlined"
            onChange={(value: any) => {
              setSelectedModel(value);
              setErrorMessage(undefined);
              const selectedModelData = ModelList.find(
                (model: any) => model.model_id === value // Find the full model data by `model_id`
              );
              const className = selectedModelData?.classnames?.join(', ');

              if (
                selectedObjects &&
                selectedObjects[0]?.classname != 'unlabeled'
              ) {
                const isNoOverlap = selectedObjects?.every(
                  x => !className?.includes(x.classname)
                );
                const isSubset = selectedObjects.every(x =>
                  className?.includes(x.classname)
                );
                const hasPartialOverlap = selectedObjects.some(x =>
                  className?.includes(x.classname)
                );
                if (isNoOverlap) {
                  setErrorMessage(
                    'The Classes in the selected dataset are different from the classes the model has been trained on. <strong>The Accuracy will be incorrect</strong>'
                  );

                  // setTimeout(() => {
                  //   setErrorMessage(undefined);
                  // }, 5000);
                  // snack({
                  //   message:
                  //     'The Classes in the selected dataset are  different from the classes the model has been trained on. The Accuracy will be incorrect',
                  //   severity: 'error'
                  // });
                  // return;
                }
              }
            }}
          />
        </Grid>
      </Grid> */}
      {/* {errorMessage && (
        <Typography variant="caption" sx={{ color: 'red' }}>
          {' '}
          {errorMessage}{' '}
        </Typography>
      )} */}
      {errorMessage && (
        <Typography variant="caption" sx={{ color: 'red' }} component="div">
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </Typography>
      )}
      <Grid item xs={12} mt={2} container justifyContent="center">
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
            setErrorMessage(undefined);
            resetValues();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          // disabled={proceedDisable}
          style={{ marginLeft: '8px' }}
          onClick={() => {
            // setProceedDisable(true);
            setErrorMessage(undefined);

            const classesArray = objects.map(i => i.classname);

            const data: CreateObjectSchema[] = [];
            classesArray.forEach(i => {
              data.push({
                classname: i,
                configuration_id: selectedConfig || '',
                mode: 'INFERENCE',
                groupname: objects[0]?.groupname
              });
            });
            CreateObjects({
              configId: selectedConfig || '',
              data: data
            }).then(res => {
              setNewInferenceID(res.data[0].data_id as string);
            });
          }}
        >
          Proceed
        </Button>
      </Grid>
    </Modal>
  );
};
