/* eslint-disable */
import {
  List,
  ListItem,
  Collapse,
  styled,
  ListItemText,
  Divider,
  ListItemButton
} from '@mui/material';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import routes from 'routes/privateRoutesConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Typography } from 'components/Typography/Typography';

interface IMenuModeProps {
  mode: 'light' | 'dark';
  subMenu?: boolean;
}

const StyledList = styled(List)<IMenuModeProps>(({ theme, mode }) => ({
  minWidth: '240px',
  backgroundColor:
    mode === 'light'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
  color:
    mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText
}));

const StyledListItem = styled(ListItem)<
  IMenuModeProps & { active: boolean; disabled?: boolean }
>(({ theme, mode, active, subMenu, disabled }) => ({
  padding: '5px 0',
  paddingLeft: subMenu ? '16px' : '0',

  borderBottom: `1px solid ${
    mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText
  }`,
  ':last-of-type': {
    borderBottom: 'none'
  },
  color: subMenu ? '#000 !important' : '#fff !important',
  backgroundColor: subMenu
    ? active
      ? '#FFFFFF'
      : '#c2c2c2'
    : active
    ? '#FFFFFF'
    : 'inherit',
  ...(active && {
    color: subMenu ? '#000 !important' : '#000 !important'
  }),
  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.5
  })
}));

const AppMenuContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '100%',
  marginTop: '64px'
}));

const AppMenu: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useSelector((state: RootState) => state.auth.profile);
  // Extract the feature list from the profile
  const userFeatures = profile?.features_list || [];
  const filteredRoutes = Object.keys(routes).reduce((acc: any, key: any) => {
    const item = routes[key];
    if (profile?.role === 'organization_admin') {
      acc[key] = item;
      return acc; // Skip further checks
    }
    console.log('itemitemitemitem', item?.name, userFeatures);
    // Always include the "Data" and "Reports" tabs
    if (item.name === 'Data' && userFeatures.includes('DATASHARING')) {
      // alert('HEEE');
      acc[key] = item;
    }

    if (item.name === 'Reports') {
      acc[key] = item;
    }
    // if (item.name === 'Configuration') {
    //   acc[key] = item;
    // }

    // Check for the "Training" route (for the TRAINING feature)
    if (item.name === 'Train' && userFeatures.includes('TRAINING')) {
      acc[key] = item;
    }
    // Check For the Configuration Route
    if (item.name === 'Configuration' && userFeatures.includes('DATASHARING')) {
      acc[key] = item;
    }

    // Check for the "Inference" route (for RECORDEDINFERENCE or LIVEINFERENCE features)
    if (
      (item.name === 'Inference' && userFeatures.includes('LIVEINFERENCE')) ||
      userFeatures.includes('RECORDEDINFERENCE')
    ) {
      // Only include the "Inference" route if the user has either "RECORDEDINFERENCE" or "LIVEINFERENCE"
      const filteredSubItems: any = {};

      // Check if the user has permission for each sub-route in "Inference"
      if (userFeatures.includes('LIVEINFERENCE') && item.items?.live) {
        filteredSubItems['live'] = item.items['live']; // "Live Inference"
      }
      if (userFeatures.includes('RECORDEDINFERENCE') && item.items?.media) {
        filteredSubItems['media'] = item.items['media']; // "Batch Inference"
      }

      // Only include "Inference" route if it has filtered sub-routes
      if (Object.keys(filteredSubItems).length > 0) {
        item.items = filteredSubItems; // Update the sub-items with only the allowed ones
        acc[key] = item;
      }
    }

    return acc;
  }, {});

  const [openIndex, setOpenIndex] = useState<number | null>(() => {
    let opened = null;
    Object.keys(routes).forEach((key, index) => {
      const item = routes[key];
      Object.keys(item?.items || {}).forEach((key, idx) => {
        const subItem = (item?.items || {})[key];

        if (location.pathname.includes(subItem.route || '')) {
          opened = index;
        }
      });
    });
    return opened;
  });

  return (
    <AppMenuContainer>
      <StyledList disablePadding mode="dark">
        {Object.keys(filteredRoutes).map((key, index) => {
          const item = filteredRoutes[key];
          const isOpened = openIndex === index;
          if (item.name != 'labelAssist1' && item.name != 'Novelty Detection') {
            return (
              <React.Fragment key={`app-menu-${index}`}>
                <StyledListItem
                  mode="dark"
                  onClick={() => {
                    if (!item.items) {
                      navigate(`${item.route}`);
                    } else {
                      setOpenIndex(isOpened ? null : index);
                    }
                  }}
                  active={location.pathname == item.route}
                >
                  <ListItemButton>
                    {' '}
                    <Typography>{item.name} </Typography>{' '}
                  </ListItemButton>
                  {item.items && (isOpened ? <ExpandLess /> : <ExpandMore />)}
                </StyledListItem>

                {item.items && (
                  <Collapse in={isOpened} timeout="auto" unmountOnExit>
                    <StyledList disablePadding mode="light" subMenu>
                      {Object.keys(item.items).map((subKey, idx) => {
                        const subItem = item.items![subKey];

                        return (
                          <StyledListItem
                            key={`app-menu-${index}-${idx}`}
                            mode="light"
                            onClick={() => {
                              navigate(`${item.route}${subItem.route}`);
                            }}
                            active={
                              location.pathname ===
                              `${item.route}${subItem.route}`
                            }
                            subMenu
                          >
                            <ListItemButton>
                              {' '}
                              <Typography>{subItem.name} </Typography>{' '}
                            </ListItemButton>
                          </StyledListItem>
                        );
                      })}
                    </StyledList>
                  </Collapse>
                )}
              </React.Fragment>
            );
          }
        })}
      </StyledList>
    </AppMenuContainer>
  );
};
export default AppMenu;
