import { Button, Card, Typography, Modal } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react';

import {
  Stack,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  IconButton,
  CircularProgress,
  Skeleton
} from '@mui/material';
import Thumbnail from './Thumbnail';
import {
  ConfigSchema,
  ObjResponseSchemaCount,
  ObjSchema,
  QualitygeneratemaskRequest,
  SyncObjectsRequest,
  TrainingResultSchema
} from 'schemas';

import { SelectInput, TextField } from 'components/Form';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Default from '../../assets/images/default.svg';
import forwardArrow from '../../assets/images/forwardArrow.svg';

import { useNavigate } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { useSyncObjectByDataid } from 'api/sdk';
import { v4 } from 'uuid';
import SkeletonLoader from 'components/SkeletonLoader';
import TrainingModal from './trainingModal';
import TablePagination from '@mui/material/TablePagination';
import ChartComponent from './graphScroll';

interface MoveImageProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  reportsLoaded?: boolean;

  objects: ObjSchema[] | undefined;
  objectAll: ObjResponseSchemaCount | undefined;

  mode: string;
  setStep: (data: number) => void;
  viewMode?: boolean;
  currentInferenceID: string | undefined;

  selectedModel?: string;
  models?: TrainingResultSchema[];
}
interface FormValues {
  modelName: string;
  trainTestSplit: number;
  batchSize: number;
  learningRate: number;
  epochs: number;
  classesType: string;
}

type WarningModalProps = {
  onClose: () => void;
  message: string;
};

export const ViewDetails: React.FC<MoveImageProps> = ({
  selectedConfig,

  objects,
  objectAll,
  currentInferenceID,
  reportsLoaded,
  mode,
  configs,
  selectedModel,
  models,
  setStep,
  viewMode
}) => {
  const [selectedObject, setSelectedObject] = useState<string>('');
  console.log('selectedModel', selectedModel, models);
  const [modelArchitecture, setModelArchitecture] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [previewImageID, setPreviewImageID] = useState<number>(1);
  const [trainingModal, setTrainingModal] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [localImages, setLocalImages] = useState<ObjSchema[]>();

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const ImagesRowRef = useRef(null);
  const [imagesPerRow, setImagesPerRow] = useState(0);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorWarning, setShowErrorWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const navigate = useNavigate();
  const handelChangeImageIndex = (id: number) => {
    setPreviewImageID(id);
    setShowPreview(true);
  };

  useEffect(() => {
    if (objects && objects?.length > 0) {
      setSelectedObject(objects[0]?.classname);
    }
  }, [objects]);

  useEffect(() => {
    setShowOverlay(false);
  }, [previewImageID, selectedObject]);

  useEffect(() => {
    setLocalImages(objects);
  }, [objects]);

  const onCheckItem = (checked: boolean, item: string) => {
    if (checked) {
      setCheckedItems(prev => [...prev, item]);
    } else {
      setCheckedItems(prev => prev.filter(i => i !== item));
    }
  };

  const WarningModal: React.FC<WarningModalProps> = ({ onClose, message }) => (
    <Modal open={true} onClose={onClose}>
      <div>
        <Typography
          style={{ fontSize: '16px', fontFamily: 'proxima-nova-bold' }}
        >
          Warning: Class Count Below 200
        </Typography>
        <Typography>{message}</Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Button onClick={onClose} version="light">
            Close
          </Button>

          <Button
            onClick={() => {
              onClose();
              setModelArchitecture('');
              objects && handleSync(objects);
              setTrainingModal(true);
            }}
          >
            Proceed
          </Button>
        </Stack>
      </div>
    </Modal>
  );

  useEffect(() => {
    setCheckedItems([]);
    setPreviewImageID(0);
    setPage(1);
  }, [selectedObject]);

  const { mutateAsync: syncObject } = useSyncObjectByDataid();

  const handleSync = async (
    data: ObjSchema[],
    inferenceid?: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      groupname: obj.groupname as string,
      processed_id: null,
      configuration_id: selectedConfig || '',
      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          image_path: imagePath.thumb_url || null,
          nas_path: imagePath.nas_path,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: obj.mode || 'INFERENCE'
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid ? inferenceid : currentInferenceID || '',
      configId: selectedConfig || ''
    });
  };

  const mainObject = useMemo(() => {
    const o = localImages?.find(i => i.classname == selectedObject);
    return o ?? null;
  }, [localImages, objects, selectedObject]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const previewImageSrc = useMemo(() => {
    if (previewImageID === undefined) return Default;
    const image = localImages?.find(i => i.classname === selectedObject)
      ?.images?.[previewImageID];
    if (showOverlay) {
      return image?.overlayed_url || Default;
    } else return image?.thumb_url || Default;
  }, [localImages, selectedObject, previewImageID, showOverlay]);

  const handlePrevImage = () => {
    setPreviewImageID(prev => Math.max(prev - 1, 0));
  };

  const handleNextImage = () => {
    setPreviewImageID(prev =>
      Math.min(prev + 1, (mainObject?.images?.length || 1) - 1)
    );
  };

  const handleUprow = () => {
    if (previewImageID > imagesPerRow) {
      setPreviewImageID(previewImageID - imagesPerRow);
    }
  };
  const handleDownrow = () => {
    if (previewImageID + imagesPerRow <= 99) {
      setPreviewImageID(previewImageID + imagesPerRow);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          handleUprow();
          break;
        case 'ArrowDown':
          handleDownrow();
          break;
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [previewImageID]);

  useEffect(() => {
    setPreviewImageID((page - 1) * 100);
  }, [page]);

  const layoutRef = useRef(null);

  useEffect(() => {
    const updateImagesPerRow = () => {
      if (ImagesRowRef.current) {
        const boxWidth = (ImagesRowRef.current as HTMLElement)?.offsetWidth;
        const thumbnailWidth = 110;
        const imagesInRow = Math.floor(boxWidth / thumbnailWidth);
        setImagesPerRow(imagesInRow);
      }
    };
    updateImagesPerRow();
    window.addEventListener('resize', updateImagesPerRow);

    return () => {
      window.removeEventListener('resize', updateImagesPerRow);
    };
  }, []);
  const imagesPerPage = 100;

  const currentImages = mainObject?.images?.slice(
    (page - 1) * imagesPerPage,
    page * imagesPerPage
  );

  return reportsLoaded || !objectAll?.config_name ? (
    <SkeletonLoader />
  ) : (
    <>
      <Button
        version="transparent"
        onClick={() => {
          if (viewMode) {
            setStep(1);
          } else {
            setStep(2);
          }
        }}
      >
        {' '}
        <ArrowBackIosIcon /> Back
      </Button>

      <Card
        style={{
          height: 'calc(100vh - 160px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '16px'
        }}
      >
        <Typography
          style={{
            fontFamily: 'proxima-nova-Bold',
            marginBottom: '16px',
            fontSize: '20px'
          }}
        >
          Train Model
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={5.5} container>
            <Grid item xs={6} md={6} lg={5}>
              <Stack direction="row">
                <Stack>
                  <Typography>
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: 'proxima-nova-Bold'
                      }}
                    >
                      Configuration :
                    </span>{' '}
                    <span
                      style={{ fontSize: '14px', fontFamily: 'proxima-nova' }}
                    >
                      {objectAll?.config_name}
                    </span>
                  </Typography>

                  <Typography>
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: 'proxima-nova-Bold'
                      }}
                    >
                      Data-Set Name :
                    </span>{' '}
                    <span
                      style={{ fontSize: '14px', fontFamily: 'proxima-nova' }}
                    >
                      {' '}
                      {localImages?.[0]?.groupname}
                    </span>
                  </Typography>
                </Stack>
              </Stack>
              <Stack mt={4}>
                {objects && (
                  <Stack direction={'row'}>
                    <SelectInput
                      fullwidth
                      label="Select Classes"
                      placeholder="Select Classes"
                      variant="outlined"
                      name="classes"
                      value={selectedObject}
                      options={objects
                        .filter(obj => obj.classname !== 'unlabeled')
                        .map(obj => ({
                          label: obj.classname,
                          value: obj.classname
                        }))}
                      onChange={value => setSelectedObject(value)} // Update the state on change
                    />
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6} md={6} lg={5}>
              <Stack direction="row" spacing={6}>
                <Stack>
                  <Typography
                    variant="h6"
                    fontSize="14px"
                    sx={{
                      opacity: 1,
                      textTransform: 'capitalize'
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: 'proxima-nova-Bold'
                      }}
                    >
                      Total Labeled Images :
                    </span>{' '}
                    <span
                      style={{ fontSize: '14px', fontFamily: 'proxima-nova' }}
                    >
                      {objectAll?.labeled_count}
                    </span>
                  </Typography>
                  <Typography>
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: 'proxima-nova-Bold'
                      }}
                    >
                      {' '}
                      Total Classes :{' '}
                    </span>
                    <span
                      style={{ fontSize: '14px', fontFamily: 'proxima-nova' }}
                    >
                      {Object.keys(objectAll?.classname_counts || {}).length}
                    </span>
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            {/* <Grid xs={12} md={8} lg={8}>
           
            </Grid> */}
          </Grid>

          <Grid item xs={6} md={6} lg={6.5}>
            <Card
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: '20px'
              }}
            >
              <ChartComponent
                labels={
                  objects
                    ?.filter(obj => obj.classname !== 'unlabeled')
                    ?.map(i => i.classname) || []
                }
                values={
                  objects
                    ?.filter(obj => obj.classname !== 'unlabeled')
                    ?.map(i => i?.images?.length || 0) || []
                }
              />
            </Card>
          </Grid>
        </Grid>

        <Grid
          mt={1}
          mb={1}
          style={{
            overflow: 'scroll'
          }}
        >
          <Box ref={ImagesRowRef}>
            <Grid container spacing={3}>
              {currentImages?.map((item, index) => (
                <Grid item xs={6} sm={4} md={3} lg={1} key={item.id}>
                  <Thumbnail
                    id={item.id}
                    title=""
                    showImageGallery={true}
                    thumbnailUrl={item.thumb_url || ''}
                    index={(page - 1) * imagesPerPage + index}
                    checkable={false}
                    checked={checkedItems.includes(item.id)}
                    onCheck={checked => onCheckItem(checked, item.id)}
                    handelChangeImageIndex={handelChangeImageIndex}
                    type={
                      (page - 1) * imagesPerPage + index === previewImageID
                        ? 'OK'
                        : ''
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {(mainObject?.images?.length ?? 0) > imagesPerPage && (
          <Stack
            spacing={2}
            justifyContent={'center'}
            width={'100%'}
            height={'30px'}
            direction={'row'}
          >
            <Pagination
              count={Math.ceil(
                (mainObject?.images?.length ?? 0) / imagesPerPage
              )}
              page={page}
              onChange={handleChange}
              variant="outlined"
            />
          </Stack>
        )}

        {!viewMode && (
          <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
            <Button
              onClick={() => {
                setStep(1);
                setTrainingModal(false);
              }}
              style={{ width: '150px', height: '45px' }}
              version="light"
            >
              Cancel
            </Button>
            <Button
              disabled={
                !objectAll?.classname_counts || // Checks if classname_counts is null or undefined
                Object.keys(objectAll.classname_counts || {}).length === 0 // Checks if it's an empty object
              }
              onClick={() => {
                if (
                  objectAll &&
                  objectAll?.classname_counts &&
                  Object.keys(objectAll.classname_counts || {}).length < 2
                ) {
                  setShowErrorModal(true); // Show error modal if number of classes < 2
                } else {
                  const classesWithLowCount = Object.keys(
                    objectAll?.classname_counts || {}
                  ).filter(
                    className =>
                      ((objectAll?.classname_counts &&
                        objectAll.classname_counts[className]) ||
                        0) < 200
                  );

                  if (classesWithLowCount.length > 0) {
                    // If there are any classes with less than 200 images, show the warning modal
                    setShowErrorWarning(true);
                    setWarningMessage(
                      `Classes with less than 200 images: ${classesWithLowCount.join(
                        ', '
                      )}`
                    );
                  } else {
                    setModelArchitecture('');

                    objects && objects?.length > 0 && handleSync(objects);
                    setTrainingModal(true);
                  }
                }
              }}
              style={{ width: '150px', height: '45px' }}
            >
              Proceed
            </Button>
          </Stack>
        )}
      </Card>

      {showErrorWarning && (
        <WarningModal
          onClose={() => setShowErrorWarning(false)}
          message={warningMessage} // Display the message with class names
        />
      )}

      {/* Error Modal */}
      <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)}>
        <Typography variant="h6" mb={2}>
          Error
        </Typography>
        <Typography variant="body1" mb={3}>
          Minimum 2 Classes Needed For Supervised Training{' '}
        </Typography>
        <Button
          onClick={() => setShowErrorModal(false)}
          variant="contained"
          color="primary"
        >
          OK
        </Button>
      </Modal>
      <TrainingModal
        currentInferenceID={currentInferenceID || ''}
        mode={mode}
        objectAll={objectAll}
        selectedConfig={selectedConfig || ''}
        setTrainingModal={setTrainingModal}
        trainingModal={trainingModal}
        setStep={setStep}
        selectedModel={selectedModel}
        models={models || []}
      />
      <Modal
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <IconButton
              onClick={() => {
                setPreviewImageID(prev => prev - 1);
              }}
              disabled={previewImageID <= 1}
            >
              <ArrowCircleLeftIcon />
            </IconButton>
            <img
              src={previewImageSrc || ''}
              style={{
                height: '50vh',
                objectFit: 'contain',
                width: '80%'
              }}
              alt=""
            />
            <IconButton
              onClick={() => {
                setPreviewImageID(prev => prev + 1);
              }}
              disabled={previewImageID + 1 >= (mainObject?.images?.length || 0)}
            >
              <ArrowCircleRightIcon />
            </IconButton>
          </Stack>
          <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={2}>
            <Box>
              Image Number :{previewImageID + 1}/{mainObject?.images?.length}
            </Box>
            <Box>Image Class Type : {selectedObject}</Box>
          </Stack>
        </>
      </Modal>
    </>
  );
};
