import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './Graph.css';
import { Padding } from '@mui/icons-material';
import { Stack } from '@mui/material';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface GraphProps {
  labels: string[];
  values: number[];
}
const ChartComponent: React.FC<GraphProps> = ({ labels, values }) => {
  const colorPalette = ['#92BAE8', '#C2FBFF', '#C2FFDE', '#FFC2C2', '#FFD6A5'];

  const backgroundColors = labels.map(
    (_, index) => colorPalette[index % colorPalette.length]
  );
  const barThickness = 40; // Fixed bar width
  const minChartWidth = labels.length * 80; // Ensure enough space for scrolling
  useEffect(() => {
    // Set the Chart.js version in the DOM
    const chartVersion = document.getElementById('chartVersion');
    if (chartVersion) chartVersion.textContent = ChartJS.version;
  }, []);
  const trimmedLabels = labels.map(label =>
    label.length > 12 ? `${label.substring(0, 12)}...` : label
  );

  const data2 = {
    labels: trimmedLabels,
    datasets: [
      {
        label: '',
        data: values,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        barThickness: barThickness
      }
    ]
  };

  const config2 = {
    type: 'bar',
    data: data2,
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 10,
          top: 10
        }
      },
      scales: {
        x: {
          title: {
            display: false,
            text: 'Class Types'
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            display: false
          },
          grid: {
            drawTicks: false,
            drawBorder: false
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x' as 'x',
            threshold: 10
          },
          zoom: {
            wheel: {
              enabled: true
            },
            pinch: {
              enabled: true
            },
            mode: 'x' as 'x'
          }
        }
      }
    }
  };

  const data1 = {
    labels: [],
    datasets: [
      {
        label: '',
        data: data2.datasets[0].data // Use data from the second week's data
      }
    ]
  };

  const config1 = {
    type: 'bar',
    data: data1,
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 60
        }
      },
      scales: {
        x: {
          ticks: {
            display: false
          },

          grid: {
            drawTicks: false
          }
        },
        y: {
          beginAtZero: true,
          afterFit: (ctx: any) => {
            console.log('ctx', ctx);
            ctx.width = 35;
          },
          ticks: {
            callback: function (value: any) {
              if (value >= 1000) {
                return value / 1000 + 'k'; // Convert to 'k' format
              }
              return value; // Display the original value if less than 1000
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    }
  };

  useEffect(() => {
    const box = document.querySelector('.box') as HTMLElement;
    const barLength = data2.labels.length;
    const baseWidth = 36.46; // 36.46vw equivalent to 700px
    const extraBarWidth = (30 / 1920) * 100; // Convert 30px to vw (~1.56vw)
    const chartWidth = baseWidth + (barLength - 5) * extraBarWidth; // Dynamic width in vw

    if (barLength > 5) {
      box.style.width = `${chartWidth}vw`; // Use vw for width
    }
  }, [data2]);

  return (
    <div>
      <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
        Number of Images
      </span>
      <div className="chartBox">
        <div className="colSmall">
          <Bar data={data1} options={config1.options} />
        </div>
        <div className="colLarge">
          <div className="box">
            <Bar data={data2} options={config2.options} />
          </div>
          <Stack
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Class Type
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
