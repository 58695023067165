import { Button, Card, Typography } from 'components';
import React, { useRef, useState, useEffect } from 'react';
import { Main } from './main';

import { ViewDetails } from './viewDetails';
import PreprocessedData from 'components/filemanager/preProcessedData';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  useGetModelList,
  useGetObjectsByDataId,
  useListCameras,
  useListConfigurations,
  useStartTraining,
  useSyncObjectByDataid
} from 'api/sdk';
import {
  LabelAsisitRequestQualityControl,
  ObjResponseSchemaCount,
  ObjSchema,
  SyncObjectsRequest,
  TrainingResultSchema
} from 'schemas';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setAllConfigs } from 'store/configSlice';
import { useSnack } from 'plugins/snack';
import DropClass from './DropClass';
import { useConfirm } from 'plugins/confirm';
import TrainingModal from './trainingModal';
import ViewDataModal from './viewDataModal';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
export const TrainPage: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const profile = useSelector((state: RootState) => state?.auth.profile);

  const [viewMode, setViewMode] = useState<boolean>(false);
  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const [currentObjects, setCurrentObjects] = useState<ObjSchema[]>([]);
  const [objectAll, setObjectAll] = useState<ObjResponseSchemaCount>();
  const { isLoading, isError, refetch } = useListConfigurations({
    user_id: profile?.id || ''
  });
  const [models, setModels] = useState<TrainingResultSchema[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>();
  const [isIncremental, setIsIncrementl] = useState<boolean>(false);
  const [selectedObjects, setSelectedObjects] = useState<ObjSchema[]>([]);
  const [objectsinModels, setObjectsinModels] = useState<string[]>([]);
  const [trainingModal, setTrainingModal] = useState<boolean>(false);
  const [trainingConfirmationModal, setTrainingConfirmationModal] =
    useState<boolean>(false);

  const [dataIDModel, setDataIDModel] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const confirm = useConfirm();
  const snack = useSnack();
  const dispatch = useDispatch();
  const { mutateAsync: syncObject } = useSyncObjectByDataid();
  const handleSync = async (
    data: ObjSchema[],
    inferenceid?: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      groupname: obj.groupname as string,
      processed_id: null,
      configuration_id: selectedConfig || '',

      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          image_path: imagePath.thumb_url || null,
          nas_path: imagePath.nas_path,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: obj.mode || 'INFERENCE'
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid ? inferenceid : currentInferenceID || '',
      configId: selectedConfig || ''
    });
  };
  console.log('dataIDModel', dataIDModel,selectedModel);
  const onChange = (num: number) => {
    setStep(num);
  };
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const configs = useSelector((state: RootState) => state.config.allConfigs);
  const { refetch: refetchModels } = useGetModelList(selectedConfig || '');
  const [reportsLoaded, setReportsLoaded] = useState(true);

  const { refetch: currentObjectsRefetch, isLoading: objectsloading } =
    useGetObjectsByDataId(selectedConfig || '', currentInferenceID || '');

  const { data: dataFromModal, refetch: refetchFromModel } =
    useGetObjectsByDataId(selectedConfig || '', dataIDModel || '');

  useEffect(() => {
    setReportsLoaded(true);
    if (currentInferenceID) {
      currentObjectsRefetch().then(res => {
        setCurrentObjects(res?.data?.data.object_details || []);
        setObjectAll(res?.data?.data);
        setReportsLoaded(false);
      });
    }
  }, [currentInferenceID]);

  useEffect(() => {
    refetchModels().then(res => {
      setModels(res.data?.data || []);
    });
  }, [selectedConfig, selectedModel, isIncremental]);
  console.log('ssnsjss', models);
  function compareClassArrays(
    classInModal: string[],
    selectedClasses: string[]
  ): string {
    const classSet = new Set(classInModal);
    const selectedSet = new Set(selectedClasses);

    const allPresent = classInModal.every(cls => selectedSet.has(cls));
    const noExtras = selectedClasses.every(cls => classSet.has(cls));
    const hasAdditional = selectedClasses.some(cls => !classSet.has(cls));

    if (selectedClasses.every(cls => !classSet.has(cls))) {
      return 'noOverlap';
    } else if (allPresent && noExtras) {
      return 'excatMatch';
    } else if (allPresent && hasAdditional) {
      return 'excatMatchWithAdditional';
    } else if (!allPresent && noExtras) {
      return 'partialMatch';
    } else {
      return 'partialMatchWithAddedClasses';
    }
  }
  type ClassData = { className: string; imagesLength: number };

  const hasExcessImages = (
    selected: ClassData[],
    control: ClassData[]
  ): boolean => {
    const controlMap = new Map(
      control.map(({ className, imagesLength }) => [className, imagesLength])
    );

    return selected.some(({ className, imagesLength }) => {
      return (
        controlMap.has(className) &&
        imagesLength > (controlMap.get(className) || 0)
      );
    });
  };
  const getExcessImageClasses = (
    selected: ClassData[],
    control: ClassData[]
  ): string[] => {
    const controlMap = new Map(
      control.map(({ className, imagesLength }) => [className, imagesLength])
    );

    return selected
      .filter(
        ({ className, imagesLength }) =>
          controlMap.has(className) &&
          imagesLength > (controlMap.get(className) || 0)
      )
      .map(({ className }) => className);
  };

  return (
    <>
      {step == 1 && (
        <Main
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          setCurrentInferenceID={setCurrentInferenceID}
          setViewMode={setViewMode}
          setStep={setStep}
          step={step}
          models={models || []}
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          setIsIncrementl={setIsIncrementl}
          setObjectsinModels={setObjectsinModels}
          setDataIDModel={setDataIDModel}
        />
      )}

      {step == 2 && (
        <PreprocessedData
          type="TRAINING"
          selectedConfig={selectedConfig || ''}
          configs={configs || []}
          onSelect={(e, o) => {
            if (isIncremental) {
              setCurrentInferenceID(e);
              setSelectedObjects(o || []);

              if (o) {
                const selectedClasses = o.map(x => x.classname);
                const res = compareClassArrays(
                  objectsinModels,
                  selectedClasses
                );
                const dataFromMOdel2 = dataFromModal?.data?.object_details.map(
                  i => {
                    return {
                      className: i.classname || '',
                      imagesLength: i?.images?.length || 0
                    };
                  }
                );
                const ClasseswithImageCount = o.map(x => {
                  return {
                    className: x.classname || '',
                    imagesLength: x?.images?.length || 0
                  };
                });

                if (res == 'noOverlap') {
                  confirm({
                    title: 'No Overlap',
                    content:
                      'No overlap between selected objects and selected models. Do you want to proceed?',
                    icon: <WarningAmberIcon />
                  })
                    .then(() => {
                      setTrainingConfirmationModal(true);
                    })
                    .catch(() => {});
                  return;
                }

                if (res == 'excatMatch') {
                  if (
                    hasExcessImages(ClasseswithImageCount, dataFromMOdel2 || [])
                  ) {
                    confirm({
                      title: 'Excess images',
                      content: ` Classes      ${getExcessImageClasses(
                        ClasseswithImageCount,
                        dataFromMOdel2 || []
                      ).join(
                        ', '
                      )}, has more number of images than the available images in the selected models. this may affect the accuracy of the model. Do you want to proceed?`,
                      icon: <WarningAmberIcon />
                    }).then(() => {
                      setTrainingConfirmationModal(true);

                      return;
                    });
                  } else {
                    setTrainingConfirmationModal(true);

                    return;
                  }
                }

                if (res == 'excatMatchWithAdditional') {
                  setOpen(true);
                  return;
                }

                if (res == 'partialMatch') {
                  console.log('hello');
                  confirm({
                    title: 'Partial Match',
                    content:
                      'Some of the selected objects are not in the selected models. Do you want to proceed?',
                    icon: <WarningAmberIcon />
                  })
                    .then(() => {
                      console.log(
                        hasExcessImages(
                          ClasseswithImageCount,
                          dataFromMOdel2 || []
                        )
                      );
                      if (
                        hasExcessImages(
                          ClasseswithImageCount,
                          dataFromMOdel2 || []
                        )
                      ) {
                        confirm({
                          title: 'Excess images',
                          content: `Classes      ${getExcessImageClasses(
                            ClasseswithImageCount,
                            dataFromMOdel2 || []
                          ).join(
                            ', '
                          )}, has more number of images than the available images in the selected models. this may affect the accuracy of the model. Do you want to proceed?`,
                          icon: <WarningAmberIcon />
                        }).then(() => {
                          setTrainingConfirmationModal(true);

                          return;
                        });
                      } else {
                        setTrainingConfirmationModal(true);
                      }
                    })
                    .catch(() => {});

                  return;
                }

                if (res == 'partialMatchWithAddedClasses') {
                  confirm({
                    title: 'Partial Match',
                    content:
                      'Some of the selected objects are not in the selected models. Do you want to proceed?',
                    icon: <WarningAmberIcon />
                  })
                    .then(() => {
                      setOpen(true);
                    })
                    .catch(() => {});
                }
              }
            } else {
              setCurrentInferenceID(e);
              setTrainingConfirmationModal(true);
            }
          }}
          onCancel={() => {
            setStep(1);
          }}
          isIncremental={isIncremental}
        />
      )}

      {step == 3 && (
        <ViewDetails
          onChange={onChange}
          selectedConfig={selectedConfig}
          objects={currentObjects}
          objectAll={objectAll}
          currentInferenceID={currentInferenceID}
          mode={isIncremental ? 'INCREMENTAL_TRAINING' : 'TRAINING'}
          setStep={setStep}
          viewMode={viewMode}
          configs={configs}
          reportsLoaded={reportsLoaded}
          selectedModel={selectedModel}
          models={models || []}
        />
      )}

      <DropClass
        setTrainingConfirmationModal={setTrainingConfirmationModal}
        handleSync={handleSync}
        modelsClass={objectsinModels}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        selectedObjects={selectedObjects}
        dataFromModal={dataFromModal?.data.object_details || []}
        selectedConfig={selectedConfig || ''}
        setCurrentInferenceID={setCurrentInferenceID}
        setStep={setStep}
      />

      <TrainingModal
        currentInferenceID={currentInferenceID || ''}
        mode={isIncremental ? 'INCREMENTAL_TRAINING' : 'TRAINING'}
        objectAll={objectAll}
        selectedConfig={selectedConfig || ''}
        setTrainingModal={setTrainingModal}
        trainingModal={trainingModal}
        setStep={setStep}
        selectedModel={selectedModel}
        models={models}
      />

      <ViewDataModal
        open={trainingConfirmationModal}
        onClose={() => {
          setTrainingConfirmationModal(false);
        }}
        onStartTraining={() => {
          setTrainingConfirmationModal(false);
          setTrainingModal(true);
        }}
        onViewData={() => {
          setTrainingConfirmationModal(false);
          setStep(3);
        }}
      />
    </>
  );
};
