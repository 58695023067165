import {
  Checkbox,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  Contents,
  DirectoriesContents,
  DirectoriesContentsResponse,
  FileType
} from '../../schemasMedia';
import Folder from '../../assets/images/Folder.png';
import DraggableItem from './Draggable';
import { MyContext } from 'layouts/PrivateLayout';
import ImageModal from './imageViewer';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import FolderType from './FolderType';
import GridUnzipping from './GridUnzipping';

interface GridViewProps {
  files: Contents[];
  setSearchMode: (data: boolean) => void;
  selectCurrentDirectory: (path: string) => void;
  setFiles: (data: Contents[]) => void;
  setSearchQueryText: (data: string) => void;
  setSearchQuery: (data: string) => void;
  // setCurrentDirectory: (data: string) => void;
  currentDirectory: string;
  searchMode: boolean;
  setSelectedFiles: (data: Contents[]) => void;
  selectedFiles: Contents[];
  handleSelectFile: (file: Contents) => void;
  copyFiles: Contents[];
  editName: boolean;
  setEditName: (data: boolean) => void;
  type: string;
  handleRefetchDirectory: () => void;
  handleContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const GridView: React.FC<GridViewProps> = ({
  files,
  setSearchMode,
  setSearchQueryText,
  setSearchQuery,
  selectCurrentDirectory,
  currentDirectory,
  setSelectedFiles,
  searchMode,
  selectedFiles,
  handleSelectFile,
  setFiles,
  copyFiles,
  editName,
  setEditName,
  type,
  handleRefetchDirectory,
  handleContextMenu
}) => {
  const [imageModal, setImageModal] = useState<boolean>(false);
  const [imgSrc, setImgsrc] = useState<string>('');

  return (
    <>
      {files
        ?.filter(file => file.type !== 'application/zip')
        .map((file, index) => {
          const isDirectory = file.type === 'directory';

          const folderContent = (
            <div>
              {
                <FolderType
                  key={index}
                  selected={selectedFiles.map(i => i.name).includes(file.name)}
                  fileType={file.type || ''}
                  name={file.name || ''}
                  handleRefetchDirectory={handleRefetchDirectory}
                  selectCurrentDirectory={selectCurrentDirectory}
                  currentDirectory={currentDirectory}
                  handleSelectFile={handleSelectFile}
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                  file={file}
                  setFiles={setFiles}
                  editName={editName}
                  setEditName={setEditName}
                  handleContextMenu={handleContextMenu}
                  setSearchMode={setSearchMode}
                  setSearchQueryText={setSearchQueryText}
                  setSearchQuery={setSearchQuery}
                  imageModal={imageModal}
                  imageSrc={imgSrc}
                  setImageModal={setImageModal}
                  setImgsrc={setImgsrc}
                  disabled={
                    copyFiles.map(i => i.path).includes(file.path) &&
                    type === 'move'
                  }
                />
              }
            </div>
          );

          return isDirectory ? (
            <DraggableItem
              id={file.name || ''}
              key={file.name}
              path={file.path || ''}
            >
              {folderContent}
            </DraggableItem>
          ) : (
            <>
              <div key={file.name}>{folderContent}</div>
            </>
          );
        })}
      <GridUnzipping
        handleRefetchDirectory={handleRefetchDirectory}
        currentDirectory={currentDirectory}
      />

      <ImageModal
        imageModal={imageModal}
        imageSrc={imgSrc}
        setimgSrc={setImgsrc}
        setImageModal={setImageModal}
      />
    </>
  );
};

export default GridView;
