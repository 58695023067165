import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './GraphScrollReport.css';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Padding } from '@mui/icons-material';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

interface GraphProps {
  width: string;
  height: string;
  data: {
    class_name: string;
    // user_class_count: number;
    // ai_class_count: number;
    correct_prediction_count: number;
    wrong_prediction_count: number;
    total_count: number;
  }[];
}

const ChartComponentReportPage: React.FC<GraphProps> = ({
  height,
  width,
  data
}) => {
  // Extract labels and values for user and AI counts
  const labels = data?.map(item =>
    item?.class_name?.length > 12
      ? item?.class_name.slice(0, 12)
      : item.class_name
  );
  const userCounts = data?.map(item => item?.correct_prediction_count);
  const aiCounts = data?.map(item => item?.wrong_prediction_count);

  // Color palette
  const colorPalette = ['#92BAE8', '#C2FBFF', '#C2FFDE', '#FFC2C2', '#FFD6A5'];

  // Function to generate random color from the palette
  const getRandomColor = () =>
    colorPalette[Math.floor(Math.random() * colorPalette.length)];
  const barThickness = 40; // Fixed bar width
  const minChartWidth = labels.length * 80; // Ensure enough space for scrolling
  useEffect(() => {
    // Set the Chart.js version in the DOM
    const chartVersion = document.getElementById('chartVersion');
    if (chartVersion) chartVersion.textContent = ChartJS.version;
  }, []);

  const data2 = {
    labels: labels,
    datasets: [
      // {
      //   label: 'User Class Count',
      //   data: userCounts,
      //   backgroundColor: '#92BAE8', // Assign random color for each bar
      //   barThickness: 20,
      //   maxBarThickness: 40
      // },
      // Add User Class Count dataset only if userCounts has non-zero values
      ...(userCounts?.some(count => count > 0)
        ? [
            {
              label: 'Correctly Predicted',
              data: userCounts,
              backgroundColor: 'green',
              barThickness: 20,
              maxBarThickness: 40
            }
          ]
        : []),
      {
        label: 'Wrongly Predicted',
        data: aiCounts,
        backgroundColor: 'red', // Assign random color for each bar
        barThickness: 20,
        maxBarThickness: 40
      }
    ]
  };

  const config2 = {
    type: 'bar',
    data: data2,
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 10,
          top: 10
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Class Types'
          },
          stacked: true
        },
        y: {
          beginAtZero: true,
          stacked: true,
          ticks: {
            display: false
          },
          grid: {
            drawTicks: false,
            drawBorder: false
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom' as const,
          labels: {
            color: 'rgb(0, 0, 0)'
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x' as 'x',
            threshold: 10
          },
          zoom: {
            wheel: {
              enabled: true
            },
            pinch: {
              enabled: true
            },
            mode: 'x' as 'x'
          }
        }
      }
    }
  };

  const data1 = {
    labels: [],
    datasets: [
      ...(userCounts?.some(count => count > 0)
        ? [
            {
              label: '',
              data: userCounts
            }
          ]
        : []),
      {
        label: '',
        data: aiCounts
      }
    ]
  };

  const config1 = {
    type: 'bar',
    data: data1,
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 90
        }
      },
      scales: {
        x: {
          ticks: {
            display: false
          },

          grid: {
            drawTicks: false
          }
        },
        y: {
          beginAtZero: true,
          afterFit: (ctx: any) => {
            console.log('ctx', ctx);
            ctx.width = 35;
          },
          stacked: true, // Enable stacking for the y-axis
          ticks: {
            callback: function (value: any) {
              if (value >= 1000) {
                return value / 1000 + 'k'; // Convert to 'k' format
              }
              return value; // Display the original value if less than 1000
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    }
  };

  useEffect(() => {
    const box = document.querySelector('.boxReport') as HTMLElement;
    const barLength = data2.labels.length;
    const baseWidth = 600; // Base width in pixels (equivalent to 36.46vw)
    const extraBarWidth = 35; // Additional width for each extra bar in pixels
    const chartWidth = baseWidth + (barLength - 5) * extraBarWidth; // Dynamic width in pixels

    if (barLength > 5) {
      box.style.width = `${chartWidth}px`; // Use px for width
    }
  }, [data2]);

  return (
    <div>
      <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
        Number of Images
      </span>
      <div className="chartBoxReport">
        <div className="colSmallReport">
          <Bar data={data1} options={config1.options} />
        </div>
        <div className="colLargeReport">
          <div className="boxReport">
            <Bar data={data2} options={config2.options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartComponentReportPage;
