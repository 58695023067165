/* eslint-disable */

import { Stack } from '@mui/material';
import { useCreateObjects, useGetObjectsByDataId } from 'api/sdk';
import { Button, Modal, Typography } from 'components';
import { useConfirm } from 'plugins/confirm';
import React, { useEffect, useState } from 'react';
import { CreateObjectSchema, ObjSchema } from 'schemas';

interface DropClassProps {
  open: boolean;
  onClose: () => void;
  modelsClass: string[];
  selectedObjects: ObjSchema[];
  selectedConfig: string;
  handleSync: (data: ObjSchema[], inferenceid?: string) => Promise<void>;
  setCurrentInferenceID: (data: string) => void;
  setStep: (data: number) => void;
  dataFromModal: ObjSchema[];
  setTrainingConfirmationModal: (data: boolean) => void;
}

const DropClass: React.FC<DropClassProps> = ({
  open,
  onClose,
  modelsClass,
  selectedObjects,
  selectedConfig,
  handleSync,
  setStep,
  setCurrentInferenceID,
  dataFromModal,
  setTrainingConfirmationModal
}) => {
  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  const [newObjects, setNewObjects] = useState<ObjSchema[]>();
  const confirm = useConfirm();
  const [newInferenceID, setNewInferenceID] = useState<string>('');

  const {
    data: objectData,
    refetch: currentObjectsRefetch,
    isLoading: currentObjectsLoading
  } = useGetObjectsByDataId(selectedConfig, newInferenceID || '');

  useEffect(() => {
    if (newInferenceID) {
      currentObjectsRefetch().then(res => {
        setNewObjects(res.data?.data.object_details || []);
      });
    }
  }, [newInferenceID]);

  useEffect(() => {
    if (newObjects?.length && newObjects.length > 0) {
      const transformedObjects: ObjSchema[] = [];

      newObjects?.forEach(i => {
        let temp2: ObjSchema;
        const currentObj = selectedObjects.find(
          j => j.classname === i.classname
        );

        temp2 = {
          ...i,
          images: currentObj?.images || [],
          groupname: currentObj?.groupname || ''
        };

        transformedObjects.push(temp2);
      });

      handleSync(transformedObjects, transformedObjects[0].data_id || '').then(
        res => {
          setCurrentInferenceID(transformedObjects[0].data_id as string);
          setTrainingConfirmationModal(true);
          onClose();
        }
      );
    }
  }, [newObjects]);

  type ClassData = { className: string; imagesLength: number };

  const hasExcessImages = (
    selected: ClassData[],
    control: ClassData[]
  ): boolean => {
    const controlMap = new Map(
      control.map(({ className, imagesLength }) => [className, imagesLength])
    );

    return selected.some(({ className, imagesLength }) => {
      return (
        controlMap.has(className) &&
        imagesLength > (controlMap.get(className) || 0)
      );
    });
  };
  const getExcessImageClasses = (
    selected: ClassData[],
    control: ClassData[]
  ): string[] => {
    const controlMap = new Map(
      control.map(({ className, imagesLength }) => [className, imagesLength])
    );

    return selected
      .filter(
        ({ className, imagesLength }) =>
          controlMap.has(className) &&
          imagesLength > (controlMap.get(className) || 0)
      )
      .map(({ className }) => className);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          fontFamily: 'Proxima-Nova-Bold !important',
          fontWeight: 'bold'
        }}
      >
        Drop Classes
      </div>

      <Typography mt={1}>
        {' '}
        New Classes{' '}
        {selectedObjects
          .filter(i => !modelsClass.includes(i.classname))
          .map(i => i.classname)
          .join(', ')}{' '}
        are found, do you want to Drop them ?{' '}
      </Typography>
      <Stack direction={'row'} spacing={2} justifyContent={'center'} mt={2}>
        <Button
          version="light"
          loading={createObjectLoading || currentObjectsLoading}
          onClick={() => {
            const dataFromMOdel2 = dataFromModal?.map(i => {
              return {
                className: i.classname || '',
                imagesLength: i?.images?.length || 0
              };
            });
            const ClasseswithImageCount = selectedObjects.map(x => {
              return {
                className: x.classname || '',
                imagesLength: x?.images?.length || 0
              };
            });
            if (hasExcessImages(ClasseswithImageCount, dataFromMOdel2 || [])) {
              confirm({
                title: 'Excess images',
                content:
                  'no of images selected are more than the available images in the selected models. this may affect the accuracy of the model. Do you want to proceed?'
              }).then(() => {
                const classesArray = selectedObjects
                  .filter(i => modelsClass.includes(i.classname))
                  .map(i => i.classname);

                const data: CreateObjectSchema[] = [];
                classesArray.forEach(i => {
                  data.push({
                    classname: i,
                    configuration_id: selectedConfig || '',
                    mode: 'INFERENCE',
                    groupname: null
                  });
                });
                CreateObjects({
                  configId: selectedConfig || '',
                  data: data
                }).then(res => {
                  setNewInferenceID(res.data[0].data_id as string);
                });
              });
            } else {
              const classesArray = selectedObjects
                .filter(i => modelsClass.includes(i.classname))
                .map(i => i.classname);

              const data: CreateObjectSchema[] = [];
              classesArray.forEach(i => {
                data.push({
                  classname: i,
                  configuration_id: selectedConfig || '',
                  mode: 'INFERENCE',
                  groupname: null
                });
              });
              CreateObjects({
                configId: selectedConfig || '',
                data: data
              }).then(res => {
                setNewInferenceID(res.data[0].data_id as string);
              });
            }
          }}
        >
          Drop and proceed
        </Button>
        <Button
          onClick={() => {
            onClose();
            const dataFromMOdel2 = dataFromModal?.map(i => {
              return {
                className: i.classname || '',
                imagesLength: i?.images?.length || 0
              };
            });
            const ClasseswithImageCount = selectedObjects.map(x => {
              return {
                className: x.classname || '',
                imagesLength: x?.images?.length || 0
              };
            });

            if (hasExcessImages(ClasseswithImageCount, dataFromMOdel2 || [])) {
              confirm({
                title: 'Excess images',
                content: `Classes      ${getExcessImageClasses(
                  ClasseswithImageCount,
                  dataFromMOdel2 || []
                ).join(
                  ', '
                )}, has more number of images than the available images in the selected models. this may affect the accuracy of the model. Do you want to proceed?`
              }).then(() => {
                setTrainingConfirmationModal(true);
              });
            } else {
              setTrainingConfirmationModal(true);
            }
          }}
        >
          {' '}
          Continue without Dropping
        </Button>
      </Stack>
    </Modal>
  );
};

export default DropClass;
