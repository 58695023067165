import React, { useState, useEffect, useRef } from 'react';
import { Box, Tooltip } from '@mui/material';
import Folder from '../../assets/images/Folder.png';
import styled from '@emotion/styled';
import { Typography } from '../Typography/Typography';

import { Contents, DirectoriesContents } from '../../schemasMedia';
import { useRenameDirectoryFileManagerRenameDirectoryPatch } from '../../api/media';

import ImageModal from './imageViewer';
import LaodingIMage from '../../assets/images/loadingImage.jpg';
import { useSnack } from 'plugins/snack';
import { useSelector } from 'react-redux';
import { Root } from 'react-dom/client';
import { RootState } from 'store';
interface FolderTypeProps {
  selected: boolean;
  fileType: string;
  name: string;
  selectCurrentDirectory: (path: string) => void;
  currentDirectory: string;
  handleSelectFile: (file: Contents) => void;
  file: Contents;
  setSelectedFiles: (file: Contents[]) => void;
  selectedFiles: Contents[];
  editName: boolean;
  handleRefetchDirectory: (select?: Contents[]) => void;
  setEditName: (data: boolean) => void;
  disabled: boolean;
  setSearchMode: (data: boolean) => void;
  handleContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  setFiles: (data: Contents[]) => void;
  setSearchQueryText: (data: string) => void;
  setSearchQuery: (data: string) => void;
  imageModal: boolean;
  setImageModal: (data: boolean) => void;
  setImgsrc: (data: string) => void;
  imageSrc: string;
}

const getBG = (selected: boolean, hovered: boolean, disabled: boolean) => {
  if (disabled) return '#ededed';
  if (selected) return '#CFE2ED';
  if (hovered) return '#EBF3F8';
  return 'fff';
};

const StyledDiv = styled('div')<{
  hovered: boolean;
  selected: boolean;
  disabled: boolean;
}>(({ hovered, selected, disabled }) => ({
  width: '130px',
  padding: '10px',
  position: 'relative',
  backgroundColor: getBG(selected, hovered, disabled),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  float: 'left'
}));

const FolderType: React.FC<FolderTypeProps> = ({
  selected,
  fileType,
  name,
  selectCurrentDirectory,
  currentDirectory,
  handleSelectFile,
  file,
  setSelectedFiles,
  editName,
  handleRefetchDirectory,
  setEditName,
  disabled,
  setSearchMode,
  handleContextMenu,
  selectedFiles,
  setFiles,
  setSearchQuery,
  setSearchQueryText,
  setImgsrc,
  imageModal,
  setImageModal
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(name);
  const [playModal, setPlayModal] = useState<boolean>(false);

  const [videoName, setVideoName] = useState<string>('');
  const snack = useSnack();
  const user_id = useSelector((state: RootState) => state.auth.profile?.id);

  const { mutateAsync: rename } =
    useRenameDirectoryFileManagerRenameDirectoryPatch();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editName && selected && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editName, selected]);

  const getThumbnail = (type: string) => {
    if (type === 'directory') {
      return Folder;
    }
    if (
      type === 'image/png' ||
      type === 'image/jpeg' ||
      type === 'image/jpg' ||
      type === 'image/webp'
    ) {
      return `${process.env.REACT_APP_API_BASE_URL}${file.path}`;
    }

    return Folder;
  };

  const CreateCurrrentDir = (): string => {
    if (currentDirectory == '/') {
      return name;
    } else {
      return `${currentDirectory}/${name}`;
    }
  };

  const handleRename = () => {
    const len = file?.path?.split('/').length;
    const oldName = len && file?.path?.[len - 1];

    if (!oldName || oldName == newName) {
      setEditName(false);
      return;
    }
    if (newName.trim().length === 0) {
      snack({
        message: 'Folder name cannot be empty',
        severity: 'error'
      });
      return;
    }
    rename({
      params: {
        directory_path: file.path || '',
        new_name: newName.trim(),
        current_directory: currentDirectory
      }
    })
      .then(() => {
        handleRefetchDirectory();
        setEditName(false);
        setNewName('');
      })
      .catch(error => {
        snack({
          message: error.response.data.detail,
          severity: 'error'
        });
      });
  };

  return (
    <Tooltip title={name.length > 15 && !imageModal ? name : ''}>
      <StyledDiv
        selected={selected}
        hovered={hovered}
        disabled={disabled}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onContextMenu={e => {
          e.preventDefault();
          if (e.ctrlKey) {
            setSelectedFiles([...selectedFiles, file]);
          } else {
            setSelectedFiles([file]);
          }
          handleContextMenu(e);
        }}
        onClick={e => {
          e.stopPropagation();
          if (e.ctrlKey) {
            handleSelectFile(file);
          } else {
            setSelectedFiles([file]);
          }
        }}
        onDoubleClick={() => {
          setSearchMode(false);
          setSelectedFiles([]);
          setSearchQueryText('');
          setSearchQuery('');
          if (fileType === 'directory') {
            selectCurrentDirectory(CreateCurrrentDir());
            setFiles([]);
          }

          if (
            fileType === 'image/png' ||
            fileType === 'image/jpeg' ||
            fileType === 'image/jpg' ||
            fileType === 'image/webp'
          ) {
            console.log('image', 'clicked');
            setImageModal(true);
            setImgsrc(`${process.env.REACT_APP_API_BASE_URL}${file.path}`);
          }
        }}
      >
        <img
          loading="lazy"
          src={getThumbnail(fileType) || LaodingIMage}
          alt=""
          style={{
            width: '120px'
          }}
        />
        <Box textAlign={'center'}>
          {editName && selected ? (
            <input
              draggable={false}
              ref={inputRef}
              style={{ width: '120px', fontSize: '12px' }}
              value={newName}
              onBlur={() => {
                setEditName(false);
                setNewName('');
                setSelectedFiles([]);
              }}
              onChange={e => {
                setNewName(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleRename();
                }
              }}
            />
          ) : (
            <Tooltip title={name.length > 15 && !imageModal ? name : ''}>
              <Typography variant="caption">
                {name.length > 15 ? name.substring(0, 15) + '...' : name}{' '}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </StyledDiv>
    </Tooltip>
  );
};

export default FolderType;
