import React from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Typography,
  Stack
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Modal, Button } from 'components';
import { SelectInput } from 'components/Form';
import { useCreateConfigurationCamera } from 'api/sdk';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const validationSchema = Yup.object({
  cameraName: Yup.string().required('Camera Name is required'),
  cameraURL: Yup.string().required('Camera URL is required')
  // .test('Check prefix', 'Camera URL must start with rtsp://', value =>
  //   value?.startsWith('rtsp://')
  // )
  // samplingRate: Yup.number()
  //   .typeError('Sampling Rate must be a number')
  //   .required('Sampling Rate is required')
  //   .min(1, 'Sampling Rate must be at least 1')
  //   .max(60, 'Sampling Rate must be less than or equal to 60')
});

interface AddCameraModalProps {
  open: boolean;
  onClose: (data: boolean) => void;
  onSubmit: () => void;
}

const AddCameraModal: React.FC<AddCameraModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const initialValues = {
    cameraName: '',
    cameraURL: ''
    // samplingRate: ''
  };

  const { mutateAsync: createcamera, isLoading: createCamerLoading } =
    useCreateConfigurationCamera();

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box p={2}>
        <Typography variant="h6" mb={2}>
          Add Camera
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            createcamera({
              data: {
                configuration_id: selectedConfig || '',
                name: values.cameraName,
                url: values.cameraURL
                // sampling_rate: Number(values.samplingRate)
              }
            }).then(res => {
              resetForm();
              onSubmit();
            });
          }}
        >
          {({ errors, touched, handleChange, handleBlur, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    fullWidth
                    size="small"
                    label="Enter Camera Name"
                    name="cameraName"
                    value={values.cameraName} // Bind value
                    onChange={handleChange} // Bind change handler
                    onBlur={handleBlur} // Bind blur handler
                    error={touched.cameraName && Boolean(errors.cameraName)}
                    helperText={touched.cameraName && errors.cameraName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    size="small"
                    label="Camera URL"
                    name="cameraURL"
                    value={values.cameraURL} // Bind value
                    onChange={handleChange} // Bind change handler
                    onBlur={handleBlur} // Bind blur handler
                    error={touched.cameraURL && Boolean(errors.cameraURL)}
                    helperText={touched.cameraURL && errors.cameraURL}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    fullWidth
                    size="small"
                    label="Enter Sampling Rate"
                    type="number"
                    name="samplingRate"
                    value={values.samplingRate} // Bind value
                    onChange={handleChange} // Bind change handler
                    onBlur={handleBlur} // Bind blur handler
                    error={touched.samplingRate && Boolean(errors.samplingRate)}
                    helperText={touched.samplingRate && errors.samplingRate}
                    InputProps={{ inputProps: { min: 1, max: 60 } }}
                  />
                </Grid> */}
              </Grid>
              <Stack
                mt={2}
                direction={'row'}
                justifyContent={'center'}
                spacing={2}
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  version="light"
                  onClick={() => onClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  loading={createCamerLoading}
                >
                  Create
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddCameraModal;
