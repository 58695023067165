import { Button, Card, Typography, Modal } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react';

import {
  Stack,
  Box,
  Grid,
  Divider,
  Select,
  IconButton,
  TextField,
  Pagination,
  Checkbox
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Thumbnail from 'components/Thumbnail';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SelectInput from 'components/Form/Inputs/SelectInput';
import {
  ObjResponseSchemaCount,
  ObjSchema,
  SyncObjectsRequest,
  ObjSchemaImages,
  ImgSchema,
  LabelAsisitRequestQualityControlIncremental,
  TrainingResultSchema
} from 'schemas';
import DropClassIncr from './DropIncremental';
import {
  useCreateObjects,
  useGetObjectsByDataId,
  useSyncObjectByDataid,
  useGetModelList,
  useSaveLabaelAssist
} from 'api/sdk';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Default from '../../assets/images/default.svg';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useSnack } from 'plugins/snack';
import DeleteModal from './deleteInferenceID';
import { useConfirm } from 'plugins/confirm';
import DropClass from './DropClass';
import EditClassModal from './EditClass';
import DataDistribution from './Chart';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Delete, DeleteOutline } from '@mui/icons-material';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import TrainingModal from 'pages/train/trainingModal';
import { v4 } from 'uuid';

interface LabelAssist1Props {}

export const LabelAssist1: React.FC<LabelAssist1Props> = ({}) => {
  const location = useLocation();
  const { id, model_id } = useParams();

  const [data, setData] = useState<ObjSchema[]>([]);
  const [objectAll, setObjectAll] = useState<ObjResponseSchemaCount>();

  const [selectedClass, setSelectedClass] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [currentInferenceID, setCurrentInferenceID] = useState<string>('');
  const [previewImageID, setPreviewImageID] = useState<number>(0);

  const [isIncrementalLoading, setIsIncrementalLoading] =
    useState<boolean>(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [EditClass, setEditClass] = useState<boolean>(false);
  const [DataDistributionOpen, setDataDistributionOpen] =
    useState<boolean>(false);
  const [incrementedState, setIncrementedState] = useState<ObjSchema[]>([]);
  const [type, setType] = useState<string>('');

  const [isTraining, setIsTraining] = useState<boolean>(false);

  const [trainingModal, setTrainingModal] = useState<boolean>(false);
  const [currentModelID, setCurrentModelID] = useState<string>('');
  const [dropClassModal, setDropClassModal] = useState<boolean>(false);
  const [dropClassINCRModal, setDropClassINCRModal] = useState<boolean>(false);

  const [moveSelectedImage, setMoveSelectedImage] = useState<boolean>(false);
  const [objectsInModal, setObjectsInModal] = useState<string[]>([]);

  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const allConfigs = useSelector((state: RootState) => state.config.allConfigs);

  const snack = useSnack();

  const { mutateAsync: saveData } = useSaveLabaelAssist();

  useEffect(() => {
    if (data.length > 0) {
      const allImages = data
        .flatMap(item => item.images ?? [])
        .filter((image): image is ImgSchema => image !== null);

      const allImagesObject: ObjSchema = {
        category: 'All Categories',
        classname: 'All',
        configuration_id: 'ALL_CONFIG',
        groupname: 'All Groups',
        id: 'ALL_ID',
        images: allImages || [],
        data_id: 'ALL_INFERENCE',
        mode: 'ALL_MODE',
        is_defect_free: false,
        processed_id: null
      };

      setIncrementedState([allImagesObject, ...data]);
      setSelectedClass('All');
    }
  }, [data, currentInferenceID]);

  const [moveClass, setMoveClass] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [models, setModels] = useState<TrainingResultSchema[]>([]);
  const navigate = useNavigate();
  const {
    data: objectListArr,
    mutateAsync: syncObject,
    isLoading: syncLoading
  } = useSyncObjectByDataid();

  useEffect(() => {
    setCurrentInferenceID(id || '');
    if (model_id !== 'null') {
      setCurrentModelID(model_id || '');

      setType('Incremental');
    } else {
      setCurrentModelID('');
      setType('Data');
    }
  }, [id, model_id]);

  useEffect(() => {
    if (!selectedConfig) {
      navigate('/Media/Media');
    }
  }, [selectedConfig]);

  const { refetch: refetchModels, isLoading: saveLoading } = useGetModelList(
    selectedConfig || ''
  );

  useEffect(() => {
    refetchModels().then(res => {
      setModels(res.data?.data || []);
    });
  }, [selectedConfig, model_id]);

  const {
    data: totalData,
    refetch,
    isFetching: dataFetching,
    isLoading: dataLoading
  } = useGetObjectsByDataId(selectedConfig || '', currentInferenceID);
  const confirm = useConfirm();

  useEffect(() => {
    if (selectedClass) {
      const r = data.filter(item => item.classname != selectedClass);

      setMoveClass(r[0]?.classname || '');
    }
  }, [selectedClass]);

  useEffect(() => {
    if (currentInferenceID != '') {
      refetch().then(res => {
        setData(res.data?.data.object_details || []);

        if (res.data?.data.object_details[0].mode != 'INFERENCE') {
          setIsTraining(true);
        } else {
          setIsTraining(false);
        }

        if (res.data?.data.object_details[1].classname) {
          setMoveClass(res.data?.data.object_details[1].classname || '');
        }
      });
    }
  }, [currentInferenceID]);

  const currentObject = useMemo(() => {
    return incrementedState?.find(i => i.classname == selectedClass);
  }, [data, selectedClass, currentInferenceID, incrementedState]);

  const onCheckItem = (checked: boolean, item: string) => {
    if (checked) {
      setCheckedItems(prev => [...prev, item]);
    } else {
      setCheckedItems(prev => prev.filter(i => i !== item));
    }
  };

  const handelChangeImageIndex = (id: number) => {
    setPreviewImageID(id);
  };

  const previewImageSrc = useMemo(() => {
    if (previewImageID === undefined) return Default;
    const image = incrementedState?.find(i => i.classname === selectedClass)
      ?.images?.[previewImageID + (page - 1) * 100];
    return image?.thumb_url || Default;
  }, [data, selectedClass, previewImageID, incrementedState]);

  const previewImageSrcID = useMemo(() => {
    if (previewImageID === undefined) return Default;
    const image = incrementedState?.find(i => i.classname === selectedClass)
      ?.images?.[previewImageID + (page - 1) * 100];

    return image?.id || '';
  }, [data, selectedClass, previewImageID, incrementedState]);

  const handlePrevImage = () => {
    setPreviewImageID(prev => Math.max(prev - 1, 0));
  };

  const handleNextImage = () => {
    setPreviewImageID(prev => prev + 1);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [previewImageID]);

  const totalImageCount = useMemo(() => {
    let count = 0;
    for (let x of data) {
      if (x?.images?.length) {
        count = count + x?.images?.length || 0;
      }
    }
    return count;
  }, [data]);

  const handelMoveImage = (
    fromObjectIndex: number,
    toObjectIndex: number,
    checkedItems: string[]
  ) => {
    if (data) {
      setCheckedItems([]);

      const fromObject = data[fromObjectIndex];
      const toObject = data[toObjectIndex];
      fromObject.images = fromObject.images || [];
      toObject.images = toObject.images || [];

      const filteredImages = fromObject.images.filter(image =>
        checkedItems.includes(image.id)
      );
      toObject.images.push(...filteredImages);

      fromObject.images = fromObject.images.filter(
        image => !checkedItems.includes(image.id)
      );

      const updatedObjects = [...data];
      updatedObjects[fromObjectIndex] = fromObject;
      updatedObjects[toObjectIndex] = toObject;
      setData(updatedObjects);

      snack({
        message: `${checkedItems.length} image moved to ${toObject.classname}`,
        severity: 'success'
      });
    }
  };

  const handleSync = async (
    data: ObjSchema[],
    configID: string,
    inferenceid: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      processed_id: obj.processed_id,
      configuration_id: configID || '',
      groupname: obj.groupname as string,
      is_defect_free: obj.is_defect_free ?? false,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          nas_path: imagePath.nas_path,
          image_path: imagePath.thumb_url,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: obj.mode || ''
    }));

    await syncObject({
      data: updatedObjects,
      dataId: inferenceid,
      configId: configID || ''
    });
  };

  const checkForlastIndex = (previewImageid: number, page: number): boolean => {
    const lastPage = Math.ceil(
      (currentObject?.images?.length as number) / 100 || 1
    );

    if (page == lastPage) {
      const total = currentObject?.images?.length as number;

      if (previewImageid + 1 + (page - 1) * 100 >= total) {
        return true;
      } else return false;
    } else {
      if (previewImageid >= 99) {
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    setCheckedItems([]);
  }, [selectedClass]);

  const checkForTraining = (data: ObjSchema[]): boolean => {
    if (data.length < 2) {
      snack({
        message: 'You cannot save training dataset with lessthan  2 classes',
        severity: 'error'
      });
      return false;
    }

    for (const item of data) {
      if (!item.images?.length || !item.images || item.images?.length == 0) {
        setDropClassModal(true);

        return false;
      }

      if (item.images && item.images?.length < 50) {
        snack({
          message: `Class "${item.classname}" has less than 50 images`,
          severity: 'error'
        });
        return false;
      }
    }

    return true;
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  function checkForall(): boolean {
    const id = currentObject?.images
      ?.slice((page - 1) * 100, page * 100)
      .map(i => i.id);
    if (checkedItems.length == id?.length) {
      return true;
    } else {
      return false;
    }
  }

  function compareClassArrays(
    classInModal: string[],
    selectedClasses: string[]
  ): string {
    const classSet = new Set(classInModal);
    const selectedSet = new Set(selectedClasses);

    const allPresent = classInModal.every(cls => selectedSet.has(cls));
    const noExtras = selectedClasses.every(cls => classSet.has(cls));
    const hasAdditional = selectedClasses.some(cls => !classSet.has(cls));

    if (selectedClasses.every(cls => !classSet.has(cls))) {
      return 'noOverlap';
    } else if (allPresent && noExtras) {
      return 'excatMatch';
    } else if (allPresent && hasAdditional) {
      return 'excatMatchWithAdditional';
    } else if (!allPresent && noExtras) {
      return 'partialMatch';
    } else {
      return 'partialMatchWithAddedClasses';
    }
  }

  const hasMoreThan50Images = (data: ObjSchema[]): boolean => {
    return data.every(item => (item.images?.length ?? 0) > 50);
  };

  return (
    <>
      <Button
        version="transparent"
        onClick={() => {
          setDeleteModalOpen(true);
        }}
      >
        {' '}
        <ArrowBackIosIcon /> BACK{' '}
      </Button>

      <Grid container spacing={2} style={{ height: 'calc(100vh - 190px)' }}>
        <Grid item xs={6} style={{ height: '100%' }}>
          <Card style={{ height: '100%' }}>
            <>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography
                  style={{
                    fontFamily: 'Proxima-Nova-Bold',
                    fontSize: '20px'
                  }}
                >
                  {' '}
                  Data set creation{' '}
                </Typography>
                <Stack direction={'row'} spacing={2}>
                  {allConfigs && selectedConfig && (
                    <div>
                      <Typography>
                        {' '}
                        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Configuration:
                        </span>
                        <span style={{ fontSize: '14px' }}>
                          {allConfigs?.find(i => i.id == selectedConfig)
                            ?.name || ''}{' '}
                        </span>
                      </Typography>
                    </div>
                  )}

                  <div>
                    {data && data[0] && (
                      <Typography>
                        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Dataset Name :{' '}
                        </span>
                        <span style={{ fontSize: '14px' }}>
                          {data[0].groupname}
                        </span>
                      </Typography>
                    )}
                  </div>
                </Stack>
              </Stack>

              <Stack direction={'row'} spacing={5} mt={1.5} mb={1.5}>
                <Typography>
                  {' '}
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {' '}
                    Total images :{' '}
                  </span>{' '}
                  <span style={{ fontSize: '14px' }}>
                    {totalImageCount}{' '}
                  </span>{' '}
                </Typography>
                {incrementedState && selectedClass && (
                  <Typography>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      {' '}
                      {selectedClass}:
                    </span>
                    <span style={{ fontSize: '14px' }}>
                      {' '}
                      {
                        incrementedState.find(i => i.classname == selectedClass)
                          ?.images?.length
                      }{' '}
                    </span>
                  </Typography>
                )}
              </Stack>

              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {data && (
                  <SelectInput
                    width={'60%'}
                    fullwidth
                    name="select"
                    value={selectedClass || ''}
                    variant="outlined"
                    options={
                      incrementedState?.map(i => {
                        return { value: i.classname, label: i.classname };
                      }) || []
                    }
                    onChange={e => {
                      setSelectedClass(e);
                      setPage(1);
                      setPreviewImageID(0);
                    }}
                  />
                )}

                <Stack direction={'row'} spacing={1}>
                  <Button
                    version="light"
                    onClick={() => {
                      setEditClass(true);
                    }}
                  >
                    Edit Class
                  </Button>
                  <Button
                    version="light"
                    onClick={() => {
                      setDataDistributionOpen(true);
                    }}
                  >
                    View Data Distribution
                  </Button>
                </Stack>
              </Stack>

              <Stack
                direction={'row'}
                spacing={1}
                justifyContent={'space-between'}
                pt={1.5}
                mt={1.5}
                borderTop={'3px solid #D9D9D9'}
                alignItems={'center'}
              >
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Checkbox
                    disabled={selectedClass == 'All'}
                    checked={checkForall()}
                    {...label}
                    onChange={e => {
                      const id = currentObject?.images
                        ?.slice((page - 1) * 100, page * 100)
                        .map(i => i.id);
                      if (checkedItems.length == id?.length) {
                        setCheckedItems([]);
                      } else {
                        setCheckedItems(id || []);
                      }
                    }}
                  />{' '}
                  <Typography>
                    {checkForall() ? 'Deselect All' : 'Select All'}
                  </Typography>
                </Stack>
                <Typography>Selected Images : {checkedItems.length}</Typography>
                <Stack direction={'row'} spacing={1}>
                  <Button
                    disabled={
                      selectedClass == 'All' || checkedItems.length == 0
                    }
                    version="dark"
                    onClick={() => {
                      setMoveSelectedImage(true);
                    }}
                  >
                    Move Selected
                  </Button>
                  <IconButton
                    disabled={
                      selectedClass == 'All' || checkedItems.length == 0
                    }
                    onClick={() => {
                      confirm({
                        title: 'Delete Images',
                        content: `Are you sure you want to delete ${checkedItems.length} images?`
                      })
                        .then(() => {
                          setData(prev =>
                            prev.map(item => {
                              if (item.classname === selectedClass) {
                                return {
                                  ...item,
                                  images:
                                    item.images?.filter(
                                      image => !checkedItems.includes(image.id)
                                    ) || []
                                };
                              }
                              return item;
                            })
                          );
                        })
                        .catch(() => {});
                    }}
                  >
                    <RiDeleteBin6Fill />
                  </IconButton>
                </Stack>
              </Stack>

              <Grid sx={{ height: '64%', overflowY: 'scroll' }} mt={1}>
                {dataLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      height: '75%',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{ textAlign: 'center' }}>
                      <CircularProgress />
                      <Typography>
                        please wait till the data is loading
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  currentObject?.images
                    ?.slice((page - 1) * 100, page * 100)
                    .map((item, index) => (
                      <Thumbnail
                        id={item.id}
                        title=""
                        showImageGallery={true}
                        key={index}
                        thumbnailUrl={item.thumb_url || ''}
                        index={index}
                        checkable={selectedClass != 'All'}
                        checked={checkedItems.includes(item.id)}
                        onCheck={checked =>
                          selectedClass != 'All' &&
                          onCheckItem(checked, item.id)
                        }
                        handelChangeImageIndex={handelChangeImageIndex}
                        type={previewImageID == index ? true : false}
                        onClick={() => console.log('hello')}
                      />
                    ))
                )}
              </Grid>

              <Stack
                spacing={2}
                justifyContent={'center'}
                direction={'row'}
                mt={1.5}
              >
                <Pagination
                  page={page}
                  onChange={(e, v) => {
                    setPage(v);
                    setPreviewImageID(0);
                    setCheckedItems([]);
                  }}
                  count={Math.ceil(
                    (currentObject?.images?.length as number) / 100 || 1
                  )}
                />
              </Stack>
            </>
          </Card>
        </Grid>

        <Grid item xs={6} style={{ height: '100%' }}>
          <Card style={{ height: '100%' }}>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '10px'
              }}
            >
              <Typography>
                Showing Image : {1 + previewImageID + (page - 1) * 100} /{' '}
                {currentObject?.images?.length}
              </Typography>

              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ height: 'calc(100vh - 325px)' }}
              >
                <IconButton
                  disabled={previewImageID <= 0}
                  onClick={() => {
                    setPreviewImageID(prev => prev - 1);
                  }}
                >
                  <ArrowCircleLeftIcon />
                </IconButton>
                <img
                  src={previewImageSrc || ''}
                  alt=""
                  style={{
                    objectFit: 'contain',
                    maxWidth: 'calc(100% - 100px)',
                    maxHeight: '100%',
                    width: 'auto',
                    height: 'auto'
                  }}
                />
                <IconButton
                  disabled={checkForlastIndex(previewImageID, page)}
                  onClick={() => {
                    setPreviewImageID(prev => prev + 1);
                  }}
                >
                  <ArrowCircleRightIcon />
                </IconButton>
              </Stack>
              {data.length > 1 && (
                <Stack
                  direction={'row'}
                  spacing={4}
                  alignItems={'center'}
                  padding={1}
                  justifyContent={'center'}
                >
                  {data && (
                    <SelectInput
                      disabled={selectedClass == 'All'}
                      width={'250px'}
                      maxWidth={'250px'}
                      name="select"
                      variant="outlined"
                      value={moveClass || ''}
                      options={
                        data
                          ?.filter(i => i.classname != selectedClass)
                          .map(i => {
                            return { value: i.classname, label: i.classname };
                          }) || []
                      }
                      onChange={e => {
                        setMoveClass(e);
                      }}
                    />
                  )}
                  <Button
                    version="dark"
                    disabled={
                      selectedClass == 'All' ||
                      moveClass == '' ||
                      previewImageSrcID == ''
                    }
                    onClick={() => {
                      const fromObjIndex = data?.findIndex(
                        i => i.classname == selectedClass
                      );
                      const toObjIndex = data?.findIndex(
                        i => i.classname == moveClass
                      );

                      if (fromObjIndex != -1 && toObjIndex != -1) {
                        handelMoveImage(fromObjIndex, toObjIndex, [
                          previewImageSrcID
                        ]);
                      }
                    }}
                  >
                    Move
                  </Button>
                  <IconButton
                    disabled={selectedClass == 'All' || previewImageSrcID == ''}
                    onClick={() => {
                      setData(prev =>
                        prev.map(item => {
                          if (item.classname === selectedClass) {
                            return {
                              ...item,
                              images:
                                item.images?.filter(
                                  image => image.id != previewImageSrcID
                                ) || []
                            };
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Stack>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {type == 'Data' && (
        <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={1}>
          <Button
            version="light"
            onClick={() => {
              setDeleteModalOpen(true);
            }}
          >
            {' '}
            Cancel
          </Button>
          <Button
            loading={syncLoading || saveLoading || dataLoading}
            onClick={() => {
              handleSync(
                data,
                data[0].configuration_id,
                currentInferenceID
              ).then(res => {
                refetch().then(response => {
                  setData(response.data?.data.object_details || []);
                  if (
                    isTraining &&
                    checkForTraining(response.data?.data.object_details || [])
                  ) {
                    saveData({
                      dataId: currentInferenceID,
                      configId: selectedConfig || ''
                    }).then(res => {
                      navigate('/Media/Media');
                    });
                  }

                  if (!isTraining) {
                    saveData({
                      dataId: currentInferenceID,
                      configId: selectedConfig || ''
                    }).then(res => {
                      navigate('/Media/Media');
                    });
                  }
                });
              });
            }}
          >
            {' '}
            Save
          </Button>
        </Stack>
      )}

      {type == 'Incremental' && (
        <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={2}>
          <Button
            version="light"
            onClick={() => {
              setDeleteModalOpen(true);
            }}
          >
            {' '}
            Cancel{' '}
          </Button>
          <Button
            disabled={isIncrementalLoading}
            loading={isIncrementalLoading}
            onClick={() => {
              setIsIncrementalLoading(true);
              handleSync(data, selectedConfig || '', currentInferenceID).then(
                response => {
                  refetch().then(res => {
                    setData(res.data?.data.object_details || []);
                    res.data?.data && setObjectAll(res.data?.data);
                    setIsIncrementalLoading(false);

                    const selectedObjects = res.data?.data.object_details || [];

                    if (
                      res.data?.data.object_details.length &&
                      res.data?.data.object_details.length >= 2
                    ) {
                      if (hasMoreThan50Images(selectedObjects)) {
                        const objectsinModels =
                          models.find(i => i.model_id == currentModelID)
                            ?.classnames || [];
                        setObjectsInModal(objectsinModels);
                        const selectedClasses =
                          res.data?.data.object_details.map(i => i.classname) ||
                          [];
                        const result = compareClassArrays(
                          objectsinModels,
                          selectedClasses
                        );

                        if (result == 'noOverlap') {
                          confirm({
                            title: 'No Overlap',
                            content:
                              'No overlap between selected objects and selected models. Do you want to proceed?'
                          })
                            .then(() => {
                              setTrainingModal(true);
                            })
                            .catch(() => {});
                          return;
                        }

                        if (result == 'excatMatch') {
                          setTrainingModal(true);

                          return;
                        }

                        if (result == 'excatMatchWithAdditional') {
                          setDropClassINCRModal(true);
                          return;
                        }

                        if (result == 'partialMatch') {
                          confirm({
                            title: 'Partial Match',
                            content:
                              'Some of the selected objects are not in the selected models. Do you want to proceed?'
                          })
                            .then(() => {
                              setTrainingModal(true);
                            })
                            .catch(() => {});

                          return;
                        }

                        if (result == 'partialMatchWithAddedClasses') {
                          confirm({
                            title: 'Partial Match',
                            content:
                              'Some of the selected objects are not in the selected models. Do you want to proceed?'
                          })
                            .then(() => {
                              setDropClassINCRModal(true);
                            })
                            .catch(() => {});
                        }
                      } else {
                        snack({
                          severity: 'error',
                          message: 'one or more classes has less than 50 images'
                        });
                      }
                    } else {
                      snack({
                        message:
                          'incremental training requires at least 2 classes',
                        severity: 'error'
                      });
                    }
                  });
                }
              );
            }}
          >
            {' '}
            Start Incremental Training{' '}
          </Button>
        </Stack>
      )}

      <Modal
        open={moveSelectedImage}
        onClose={() => setMoveSelectedImage(false)}
        title="Select Class to Move Images"
        size="sm"
      >
        <SelectInput
          fullwidth
          disabled={selectedClass == 'All'}
          name="select"
          variant="outlined"
          value={moveClass || ''}
          options={
            data
              ?.filter(i => i.classname != selectedClass)
              .map(i => {
                return { value: i.classname, label: i.classname };
              }) || []
          }
          onChange={e => {
            setMoveClass(e);
          }}
        />

        <Stack
          direction={'row'}
          spacing={4}
          alignItems={'center'}
          padding={1}
          justifyContent={'center'}
        >
          <Button
            version="light"
            onClick={() => {
              setMoveSelectedImage(false);
            }}
          >
            cancel
          </Button>
          <Button
            disabled={selectedClass == 'All' || moveClass == ''}
            onClick={() => {
              const fromObjIndex = data?.findIndex(
                i => i.classname == selectedClass
              );
              const toObjIndex = data?.findIndex(i => i.classname == moveClass);
              if (fromObjIndex != -1 && toObjIndex != -1) {
                handelMoveImage(fromObjIndex, toObjIndex, checkedItems);
              }
              setMoveSelectedImage(false);
            }}
          >
            Move
          </Button>
        </Stack>
      </Modal>

      <TrainingModal
        currentInferenceID={currentInferenceID}
        mode="INCREMENTAL"
        objectAll={objectAll}
        selectedConfig={selectedConfig || ''}
        setTrainingModal={setTrainingModal}
        trainingModal={trainingModal}
        selectedModel={currentModelID}
      />

      <DeleteModal
        deleteModal={DeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        currentInferenceId={currentInferenceID}
      />
      <DropClassIncr
        modelsClass={objectsInModal}
        onClose={() => {
          setDropClassINCRModal(false);
        }}
        setTrainingModal={setTrainingModal}
        open={dropClassINCRModal}
        selectedObjects={data}
        selectedConfig={selectedConfig || ''}
        handleSync={handleSync}
        setCurrentInferenceID={setCurrentInferenceID}
      />

      <DropClass
        currentInferenceID={currentInferenceID}
        DropClassModal={dropClassModal}
        data={data}
        handleSync={handleSync}
        onClose={() => {
          setDropClassModal(false);
        }}
        selectedConfig={selectedConfig || ''}
      />

      <EditClassModal
        EditClassModal={EditClass}
        type={data[0]?.mode == 'INFERENCE' ? false : true}
        onClose={() => {
          refetch().then(res => {
            setData(res.data?.data.object_details || []);
            setEditClass(false);
          });
        }}
        refetchClass={() => {
          refetch().then(res => {
            setData(res.data?.data.object_details || []);
          });
        }}
        currentInferenceID={currentInferenceID}
        data={data}
        handleSync={handleSync}
        setData={setData}
        syncLoading={syncLoading}
        dataLoading={dataLoading}
        dataFetching={dataFetching}
      />

      <DataDistribution
        data={data}
        onClose={() => {
          setDataDistributionOpen(false);
        }}
        open={DataDistributionOpen}
      />
    </>
  );
};
