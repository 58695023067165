/* eslint-disable */
import {
  FormControlLabel,
  Grid,
  MobileStepper,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import {
  useCreateConfiguration,
  useCreateGroup,
  useFeaturesList,
  useRegisterMember
} from 'api/sdk';
import { Button, Modal } from 'components';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField } from 'components/Form';
import { useSnack } from 'plugins/snack';
import { convertServerErrors } from 'utils/form';
import { AccessLevel, Analytics } from 'schemas';

interface IProps {
  // initialValues: IformValues;
  // onSubmit: (values: IformValues) => void;
  // onCancel: () => void;
  open: boolean;
  onClose: () => void;
  onSucess?: () => void;
  record: any;
  viewerValue: any;
}

interface IformValues {
  email: string;
  group_id: string;
  features_list: string[];
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required')
});

const ViewerCreation: React.FC<IProps> = ({
  open,
  onClose,
  onSucess,
  record,
  viewerValue
}) => {
  const { mutateAsync, isLoading } = useRegisterMember();
  const { mutateAsync: EditFeaturesList, isLoading: isEditLoading } =
    useFeaturesList();
  const [globalDetails, setGlobalDetails] = useState(false);
  const [values, setValues] = useState<IformValues>({
    email: '',
    group_id: '',
    features_list: []
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const resetValues = () => {
    setValues({
      email: '',
      group_id: '',
      features_list: []
    });
  };
  const snack = useSnack();

  const defaultInitialValues: IformValues = {
    email: '',
    group_id: '',
    features_list: []
  };
  // Initial state with all switches set to false by default
  const [switchesState, setSwitchesState] = useState({
    dataSharing: false,
    training: false,
    incrementalTraining: false,
    advancedSettings: false,
    recordedInference: false,
    liveInference: false,
    noveltyDetection: false
  });
  // Helper function to update the switches
  const updateSwitches = (features: string[], switchesState: any) => {
    const updatedSwitches = { ...switchesState };

    // Reset all switches first
    Object.keys(updatedSwitches).forEach(key => {
      updatedSwitches[key as keyof typeof updatedSwitches] = false;
    });

    // Enable switches based on features
    features.forEach((feature: string) => {
      switch (feature) {
        case 'DATASHARING':
          updatedSwitches.dataSharing = true;
          break;
        case 'TRAINING':
          updatedSwitches.training = true;
          break;
        case 'INCTRAINING':
          updatedSwitches.incrementalTraining = true;
          break;
        case 'ADVANCEDSETTINGS':
          updatedSwitches.advancedSettings = true;
          break;
        case 'RECORDEDINFERENCE':
          updatedSwitches.recordedInference = true;
          break;
        case 'LIVEINFERENCE':
          updatedSwitches.liveInference = true;
          break;
        case 'NOVELTY':
          updatedSwitches.noveltyDetection = true;
          break;
        default:
          break;
      }
    });

    return updatedSwitches;
  };

  useEffect(() => {
    if (viewerValue) {
      if (viewerValue?.features_list) {
        const updatedSwitches = updateSwitches(
          viewerValue.features_list,
          switchesState
        );
        setSwitchesState(updatedSwitches);
      } else if (viewerValue?.GroupFeatureList) {
        const updatedSwitches = updateSwitches(
          viewerValue.GroupFeatureList,
          switchesState
        );
        setSwitchesState(updatedSwitches);
      } else {
        setSwitchesState({
          dataSharing: false,
          training: false,
          incrementalTraining: false,
          advancedSettings: false,
          recordedInference: false,
          liveInference: false,
          noveltyDetection: false
        });
      }
    } else {
      setSwitchesState({
        dataSharing: false,
        training: false,
        incrementalTraining: false,
        advancedSettings: false,
        recordedInference: false,
        liveInference: false,
        noveltyDetection: false
      });
    }
  }, [viewerValue]);
  const handleFormSave = async (
    values: IformValues,
    formikHelpers: FormikHelpers<IformValues>
  ) => {
    // Create an array for the features list dynamically based on switchesState
    const featuresList = Object.keys(switchesState)
      .filter(key => switchesState[key as keyof typeof switchesState]) // Narrow the key type
      .map(key => {
        // Map the switch state keys to their corresponding feature names
        switch (key) {
          case 'dataSharing':
            return 'DATASHARING';
          case 'training':
            return 'TRAINING';
          case 'liveInference':
            return 'LIVEINFERENCE';
          case 'incrementalTraining':
            return 'INCTRAINING';
          case 'noveltyDetection':
            return 'NOVELTY';
          case 'recordedInference':
            return 'RECORDEDINFERENCE';
          case 'advancedSettings':
            return 'ADVANCEDSETTINGS';
          default:
            return '';
        }
      })
      .filter(feature => feature !== null); // Remove null values if any
    await mutateAsync({
      data: {
        email: values.email,
        group_id: record?.id,
        features_list: featuresList
      }
    })
      .then(response => {
        snack({
          message: 'Verfication email has been sent. Please check your email.',
          severity: 'success'
          // snackbarProps: {
          //   autoHideDuration: 2000
          // }
        });
        resetValues(); // Reset values
        onSucess && onSucess(); // Close modal
      })
      .catch(err => {
        if (
          err.response.status != ''
          // ||
          // err.response.status === 400 ||
          // err.response.status === 429
        ) {
          // formikHelpers.setErrors(
          //   convertServerErrors(err.response.data.detail)
          // );
          setError(err.response.data.detail);
        }
      });
  };
  console.log('errorerror', error);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalDetails(event.target.checked);
    // Dispatch any action or handle toggle state change if necessary
  };
  const handleEditForm = async (
    values: IformValues,
    formikHelpers: FormikHelpers<IformValues>
  ) => {
    // Create an array for the features list dynamically based on switchesState
    const featuresList = Object.keys(switchesState)
      .filter(key => switchesState[key as keyof typeof switchesState]) // Narrow the key type
      .map(key => {
        // Map the switch state keys to their corresponding feature names
        switch (key) {
          case 'dataSharing':
            return 'DATASHARING';
          case 'training':
            return 'TRAINING';
          case 'liveInference':
            return 'LIVEINFERENCE';
          case 'incrementalTraining':
            return 'INCTRAINING';
          case 'noveltyDetection':
            return 'NOVELTY';
          case 'recordedInference':
            return 'RECORDEDINFERENCE';
          case 'advancedSettings':
            return 'ADVANCEDSETTINGS';
          default:
            return '';
        }
      })
      .filter(feature => feature !== null); // Remove null values if any
    await EditFeaturesList({
      data: {
        // email: viewerValue.email,
        name: viewerValue.email,
        id: viewerValue?.id,
        type: AccessLevel.viewer,
        global_share: null,
        features_list: featuresList
      }
    })
      .then(response => {
        snack({
          message: 'User Edited successfully',
          severity: 'success'
          // snackbarProps: {
          //   autoHideDuration: 200
          // }
        });
        resetValues(); // Reset values
        onSucess && onSucess(); // Close modal
      })
      .catch(err => {
        if (err.response.status === 422) {
          formikHelpers.setErrors(
            convertServerErrors(err.response.data.detail)
          );
        }
      });
  };

  const handleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setSwitchesState(prevState => {
      const newState = { ...prevState, [name]: checked };
      // If "dataSharing" is being deactivated, deactivate all other switches
      if (name === 'dataSharing' && !checked) {
        newState.dataSharing = false;
        newState.recordedInference = false;
        newState.liveInference = false;
        newState.training = false;
        newState.incrementalTraining = false;
        newState.noveltyDetection = false;
        newState.advancedSettings = false;
      }
      // Automatically enable "training" if "incrementalTraining" is checked
      if (name === 'incrementalTraining' && checked) {
        newState.training = true;
      }
      if (!newState.training) {
        newState.incrementalTraining = false;
      }
      if (name === 'noveltyDetection' && checked) {
        if (!newState.recordedInference && !newState.liveInference) {
          newState.recordedInference = true;
          newState.liveInference = true;
        }
      }
      if (!newState.recordedInference && !newState.liveInference) {
        newState.noveltyDetection = false;
      }
      if (!newState.recordedInference && !newState.liveInference) {
        newState.noveltyDetection = false;
      }

      // Automatically enable "dataSharing" if other switches are enabled, but allow manual toggling
      if (name !== 'dataSharing') {
        const anySwitchEnabled =
          newState.recordedInference ||
          newState.liveInference ||
          newState.training ||
          newState.incrementalTraining ||
          newState.noveltyDetection;

        // Only auto-enable dataSharing if it's currently false
        if (!newState.dataSharing && anySwitchEnabled) {
          newState.dataSharing = true;
        }
      }
      return newState;
    });
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setError(undefined);

        resetValues(); // Reset values when modal is closed
      }}
      title="Manage User"
      size="md"
    >
      <Formik
        // initialValues={defaultInitialValues}
        initialValues={
          viewerValue
            ? {
                email: viewerValue.email || '', // Pre-fill email if viewerValue exists
                group_id: record?.id || '', // Pre-fill group_id if viewerValue exists
                features_list: []
              }
            : defaultInitialValues
        }
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={viewerValue.email ? handleEditForm : handleFormSave}
      >
        {({ handleSubmit, errors, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={3}
              style={{
                paddingLeft: '25px',
                paddingRight: '25px'
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <Typography
                  component="label" // Ensures it's treated as a label
                  htmlFor="group-name" // Links the label to the input field
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#102633',
                    marginBottom: '4px'
                  }}
                >
                  User Email
                </Typography>
                <TextField
                  id="viewer-name" // Matches the `htmlFor` of the label
                  name="email"
                  placeholder="Enter User Email"
                  type="email"
                  label="Email"
                  required
                  variant="outlined" // Optional, defines the input style
                  fullWidth
                  disabled={!!viewerValue.email} // Disable if email exists
                />
              </div>
            </Stack>
            <Stack
              direction={'column'} // Change direction to row to align items horizontally
              spacing={2} // Add spacing between the switches
              style={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {/* <Grid
                container
                direction={'row'} // Change direction to row to align items horizontally
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid
                  item
                  xs={6}
                  mt={3}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="h3"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '16px',
                      opacity: 0.5
                    }}
                  >
                    Global Share :
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={3}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {' '}
                  <Stack mr={2}>
                    <Switch
                      disabled
                      checked={record?.globalShare}
                      onChange={handleSwitches}
                      name="Globalshare"
                      color="primary"
                    />
                  </Stack>
                </Grid>
              </Grid> */}
              <Grid
                container
                direction={'row'} // Change direction to row to align items horizontally
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid
                  item
                  xs={12}
                  mt={3}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="h3"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '16px'
                    }}
                  >
                    Assign Features
                  </Typography>
                </Grid>
                {!record?.globalShare && (
                  <>
                    <Grid
                      item
                      xs={6}
                      mt={2}
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      {' '}
                      <Typography
                        variant="subtitle2"
                        ml={3}
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: '14px'
                        }}
                      >
                        Configuration & Data Manager
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      mt={2}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {' '}
                      <Stack mr={2}>
                        <Switch
                          checked={switchesState.dataSharing}
                          onChange={handleSwitches}
                          name="dataSharing"
                          color="primary"
                        />
                      </Stack>
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="subtitle2"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '14px'
                    }}
                  >
                    Training
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {' '}
                  <Stack mr={2}>
                    <Switch
                      checked={switchesState.training}
                      onChange={handleSwitches}
                      name="training"
                      color="primary"
                      // disabled={switchesState.incrementalTraining}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="subtitle2"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '14px'
                    }}
                  >
                    Incremental Training
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {' '}
                  <Stack mr={2}>
                    <Switch
                      checked={switchesState.incrementalTraining}
                      onChange={handleSwitches}
                      name="incrementalTraining"
                      color="primary"
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="subtitle2"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '14px'
                    }}
                  >
                    Advanced Settings
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {' '}
                  <Stack mr={2}>
                    <Switch
                      checked={switchesState.advancedSettings}
                      onChange={handleSwitches}
                      name="advancedSettings"
                      color="primary"
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="subtitle2"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '14px'
                    }}
                  >
                    Recorded Inference
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {' '}
                  <Stack mr={2}>
                    <Switch
                      checked={switchesState.recordedInference}
                      onChange={handleSwitches}
                      name="recordedInference"
                      color="primary"
                      // disabled={switchesState.noveltyDetection}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="subtitle2"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '14px'
                    }}
                  >
                    Live Inference
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {' '}
                  <Stack mr={2}>
                    <Switch
                      checked={switchesState.liveInference}
                      onChange={handleSwitches}
                      name="liveInference"
                      color="primary"
                      // disabled={switchesState.noveltyDetection}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Typography
                    variant="subtitle2"
                    ml={3}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '14px'
                    }}
                  >
                    Novelty Detection
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {' '}
                  <Stack mr={2}>
                    <Switch
                      checked={switchesState.noveltyDetection}
                      onChange={handleSwitches}
                      name="noveltyDetection"
                      color="primary"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            {error && (
              <Typography variant="caption" sx={{ color: 'red' }}>
                {' '}
                {error}{' '}
              </Typography>
            )}
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px'
              }}
              padding={2}
            >
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  setError(undefined);
                  onClose();
                }}
                version="light"
              >
                Cancel
              </Button>

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                loading={isLoading}
              >
                {isLoading ? 'Creating...' : 'Save'}
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ViewerCreation;
