import React, { useState } from 'react';
import { Box, Grid, IconButton, Stack, TextField } from '@mui/material';
import Folder from '../../assets/images/Folder.png';
import { Typography } from '../Typography/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { createDatesetUser } from './rawData';
import { useSnack } from 'plugins/snack';

interface DatalistProps {
  classname: string;
  noOfImages: number;
  index: number;
  editable: boolean;
  onRemove?: (data: string) => void;
  onEdit?: (oldName: string, newName: string) => void;
  type?: string;
  path?: string;
  handleSaveEdit?: (index: number, newName: string) => void;
}

const Datalist: React.FC<DatalistProps> = ({
  classname,
  noOfImages,
  onRemove,
  onEdit,
  type,
  path,
  handleSaveEdit,
  index,
  editable
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState(classname);
  const snack = useSnack();
  const handleSave = () => {
    if (onEdit && editedName.trim() !== '') {
      onEdit(classname, editedName);
    }

    setEditMode(false);
  };

  return (
    <Grid style={{ backgroundColor: '#E3EFF6' }} mt={1} mb={1} p={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        pl={1}
        pr={1}
        spacing={2}
      >
        <img
          src={Folder}
          alt="Folder"
          style={{ width: '50px', height: '40px' }}
        />

        {!editMode ? (
          <Box>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography>
                {type === 'UNLABELLED' ? 'Folder Name' : 'Classname'}:{' '}
                {classname}
              </Typography>{' '}
            </Stack>

            <Typography>No. of Images: {noOfImages}</Typography>
            {/* {path && (
                <Typography color="textSecondary">
                  Path: {path.slice(0, 50)}...
                </Typography>
              )} */}
          </Box>
        ) : (
          <Stack>
            <Stack direction={'row'}>
              <TextField
                value={editedName}
                onChange={e => setEditedName(e.target.value)}
                size="small"
                variant="outlined"
              />
              <>
                <IconButton
                  onClick={() => {
                    if (editedName.trim() === '') {
                      snack({
                        message: 'Class name cannot be empty.',
                        severity: 'error'
                      });
                      return;
                    }
                    handleSaveEdit && handleSaveEdit(index, editedName);
                    setEditMode(false);
                  }}
                >
                  <CheckIcon color="success" />
                </IconButton>
                <IconButton onClick={() => setEditMode(false)}>
                  <CloseIcon color="error" />
                </IconButton>
              </>
            </Stack>
            <Typography>No. of Images: {noOfImages}</Typography>

            {/* {path && (
              <Typography color="textSecondary">
                Path: {path.slice(0, 50)}...
              </Typography>
            )} */}
          </Stack>
        )}

        <Stack direction="row" spacing={1} alignItems={'flex-start'}>
          {editable && (
            <IconButton onClick={() => setEditMode(true)}>
              <EditIcon />
            </IconButton>
          )}
          {onRemove && (
            <IconButton onClick={() => onRemove(classname)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default Datalist;
